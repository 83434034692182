<script>
  import { onMount } from 'svelte';
  import { getData } from '../../services/fetchData';

  export let title = 'Create Goal';

  export let designCount;
  export let clientCount;
  export let suggestedDesignCount;
  export let suggestedClientCount;
  export let callGoal;
  export let reward = '';
  export let error = '';
  export let isFormInvalid = true;

  let originalReward;
  let currentMonthWorkingDays;

  const thresholdCallMinutesPerDay = 34;
  $: callGoal = thresholdCallMinutesPerDay * currentMonthWorkingDays;

  let isDesignCountDirty,
    isClientCountDirty = false;

  $: isDesignCountEmpty = !designCount;
  $: isClientCountEmpty = !clientCount;

  $: if (title.includes('Edit') && !suggestedClientCount && !suggestedDesignCount) {
    suggestedClientCount = clientCount;
    suggestedDesignCount = designCount;
  }

  $: if (designCount && clientCount) {
    if (reward && !originalReward) originalReward = reward;
    if (
      designCount == suggestedDesignCount &&
      clientCount == suggestedClientCount
    ) {
      reward = originalReward;
    } else {
      reward =
        originalReward *
        (((designCount * 100) / suggestedDesignCount +
          0.25 * ((clientCount * 100) / suggestedClientCount)) /
          1.25);
      reward = Math.round(reward / 100);
    }
  }

  $: isFormInvalid = isDesignCountEmpty || isClientCountEmpty;

  const fetchData = async () => {
    let response = await getData('https://test.dev/statistics/workingDays');
    currentMonthWorkingDays = await response.json();
  };

  onMount(() => fetchData());

  export let submit;
  export let cancel = () => (showForm = false);

  export let showForm = false;
</script>

{#if error}
  <p class="text-red-500 mt-16 text-center text-xs italic">{error}</p>
{:else}
  <form class="container mx-auto w-full max-w-lg">
    <div>
      <h2 class="text-2xl dark:text-white mb-6 font-semibold leading-tight">
        {title}
        <span class="text-base text-truegray-500"
          >{title.includes('Edit') ? ' (Unapproved)' : ''}</span>
      </h2>
    </div>
    <div class="flex flex-wrap -mx-3 mb-6">
      <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
        <input
          bind:value="{designCount}"
          class="appearance-none block w-full text-gray-700 dark:text-truegray-300 border border-black-900 shadow dark:shadow-blue rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-truegray-800 dark:bg-truegray-900 focus:shadow-lg border-none dark:focus:text-white focus:text-black"
          type="number"
          placeholder="Design Count: {suggestedDesignCount}"
          on:focus="{() => (isDesignCountDirty = true)}" />
        {#if isDesignCountDirty && isDesignCountEmpty}
          <p class="text-red-500 text-xs italic">Please fill out this field.</p>
        {/if}
      </div>
      <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
        <input
          bind:value="{clientCount}"
          class="appearance-none block w-full text-gray-700 dark:text-truegray-300 border border-black-900 shadow dark:shadow-blue rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-truegray-800 dark:bg-truegray-900 focus:shadow-lg border-none dark:focus:text-white focus:text-black"
          type="number"
          placeholder="Client Count: {suggestedClientCount}"
          on:focus="{() => (isClientCountDirty = true)}" />
        {#if isClientCountDirty && isClientCountEmpty}
          <p class="text-red-500 text-xs italic">Please fill out this field.</p>
        {/if}
      </div>
    </div>
    <div class="flex flex-wrap -mx-3 mb-6">
      <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
        <input
          class="appearance-none block cursor-not-allowed italic w-full text-gray-700 dark:text-truegray-300 border border-black-900 shadow dark:shadow-blue rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-truegray-800 dark:bg-truegray-900 focus:shadow-lg border-none dark:focus:text-white focus:text-black"
          type="number"
          placeholder="Reward: Rs. {reward}"
          disabled="true" />
      </div>
      <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
        <p class="dark:text-truegray-50 text-truegray-900 mt-2">
          <span class="text-blue-500 text-lg">{callGoal} mins</span> of calls in
          <span class="text-blue-500 text-lg">{currentMonthWorkingDays}</span> days
        </p>
      </div>
    </div>
    <div
      class="px-5 py-5 bg-white dark:bg-truegray-900 border-t dark:border-truegray-700 flex flex-col xs:flex-row items-center
    xs:justify-between ">
      <div class="mt-2 px-2 xs:mt-0 items-center">
        <button
          class="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold
        py-2 px-4 rounded-l shadow dark:shadow-blue"
          on:click="{submit}"
          type="button">
          {title.split(' ')[0]}
        </button>
        <button
          class="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold
        py-2 px-4 rounded-r shadow dark:shadow-blue"
          on:click="{cancel}"
          type="button">
          Cancel
        </button>
      </div>
    </div>
  </form>
{/if}
