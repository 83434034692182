<script>
  import { onMount, onDestroy } from "svelte";
  import Datatable from "../components/datatable/DataTable.svelte";
  import { submitData } from "../services/postData";
  import Spinner from "../components/Spinner.svelte";
  import columns from "../components/datatable/column-data/all-orders.js";

  let fetchOrdersResponse;
  let ordersData;
  let isMounted;
  
  export let showSpinner;
  $: showSpinner =fetchOrdersResponse;

  const perPage = [50, 1000, 3000, 5000, 7000];

  const updateLastFetchedOrdersDate = () => {
    const now = new Date();
    const lastFetchedOrdersDate = `${now.getFullYear()}-${
      now.getMonth() + 1
    }-${now.getDate()} ${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`;
    return lastFetchedOrdersDate;
  };

  const fetchData = async () => {
    if (isMounted) {
      fetchOrdersResponse = await submitData(
        "https://test.dev/order/read/all",
        {
          date: updateLastFetchedOrdersDate(),
          doUpdate: true,
        }
      );
      [fetchOrdersResponse] = await Promise.all([fetchOrdersResponse]);
      ordersData = await fetchOrdersResponse.json();
    }
  };

  onMount(() => {
    isMounted = true;
    fetchData();
  });

  onDestroy(() => {
    isMounted = false;
  });
</script>

<svelte:head>
  <title>All Orders</title>
</svelte:head>


<div>
  <Datatable
    rows="{ordersData}"
    title="All Orders"
    columns="{columns}"
    process="local"
    perPage="{perPage}" />
</div>
