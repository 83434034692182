<script>
  import { getTextboxSupportedTypes } from '../../helpers/functions';

  import Textbox from './components/Textbox.svelte';

  export let login;
  export let forgotPassword;

  export let username = '';
  export let password = '';

  export let isUsernameEmpty;
  export let isFormInvalid;

  export let title = 'Login';
  export let signInAttempts;
  export let clearUserStore;

  let isUsernameDirty,
    isPasswordDirty = false;

  $: isUsernameEmpty = username.length < 1 ? true : false;
  $: isPasswordEmpty = password.length < 1 ? true : false;

  $: isFormInvalid = isUsernameEmpty || isPasswordEmpty;
  
  function handleKeydown(e) {
    if (e.key == 'Enter') login();
  }
</script>

<svelte:window on:keydown="{handleKeydown}" />

<form class="container mx-auto py-48 w-full max-w-lg">
  <div>
    <h2
      class="text-2xl dark:text-white my-6 font-semibold leading-tight"
      class:loading="{'Authenticating' === title}">
      {title}
    </h2>
  </div>
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <Textbox
        bind:text="{username}"
        bind:isTextboxDirty="{isUsernameDirty}"
        showError="{isUsernameEmpty}"
        isAutoFocus="{true}"
        placeholder="Username" />
    </div>
    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <Textbox
        bind:text="{password}"
        type="{getTextboxSupportedTypes().password}"
        bind:isTextboxDirty="{isPasswordDirty}"
        showError="{isPasswordEmpty}"
        placeholder="Password" />
    </div>
  </div>
  <div
    class="px-5 py-5 bg-white dark:bg-truegray-900 border-t dark:border-truegray-700 flex flex-col xs:flex-row items-center
    xs:justify-between ">
    <div class="mt-2 px-2 xs:mt-0 items-center">
      <button
        class="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold
        py-2 px-4 rounded-l shadow dark:shadow-blue"
        on:click="{login}"
        type="button">
        Login
      </button>
      <button
        class="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold
        py-2 px-4 rounded-r shadow dark:shadow-blue"
        on:click="{forgotPassword}"
        type="button">
        Forgot Password
      </button>
    </div>
    {#if signInAttempts > 1}
      <button
        on:click="{clearUserStore}"
        class="text-blue-500 no-underline hover:underline hover:text-blue-700 pt-4"
        >Please click here if unable to login</button>
    {/if}
  </div>
</form>
