<script>
  import {onDestroy, onMount} from 'svelte';
  import {scale} from 'svelte/transition';
  import NavLink from './NavLink.svelte';
  import {getData} from '../../services/fetchData';
  import {
    accentColorStore,
    avatarStore,
    isUserAdminStore,
    lastLoginStatusCheckStore,
    secondaryColorStore,
    usernameStore,
  } from '../forms/stores/index';
  import {appTheme} from '../../stores/index';
  import {clickOutside} from '../select/clickOutside';
  import { employeeDataStore } from '../../pages/stores';

  // 0 means false
  let isProfileDropdown = 0;
  let isMobileMenuOpen = false;
  let isCollectiondropdownMenuOpen = false;
  let isVerificationdropdownMenuOpen = false;

  let dropdown = {button1: 0, button2: 0, button3: 0};

  let toggleDisplay = {0: 'hidden', 1: 'block'};

  let isMounted = false;
  let currentUser = {};
  let username;
  let isUserAdmin = false;
  let avatar, accentColor, secondaryColor;
  let fetchCurrentUser;

  export let approvalPendingCount = 0;

  if ($appTheme == 'light') {
    document.documentElement.classList.remove('dark');
  } else {
    document.documentElement.classList.add('dark');
  }

  const fetchData = async () => {
    if (!isMounted) {
      return false;
    }
    fetchCurrentUser = getData('https://test.dev/user/read');
    [fetchCurrentUser] = await Promise.all([fetchCurrentUser]);
    currentUser = await fetchCurrentUser.json();
    username = currentUser.username;
    avatar = currentUser.avatar;
    accentColor = currentUser.accent_color;
    isUserAdmin = currentUser.isUserAdmin;
    secondaryColor = currentUser.second_color;
    usernameStore.set(username);
    avatarStore.set(avatar);
    accentColorStore.set(accentColor);
    secondaryColorStore.set(secondaryColor);
    isUserAdminStore.set(isUserAdmin);
  };

  onMount(() => {
    isMounted = true;
    if (
      !$usernameStore ||
      !$avatarStore ||
      !$accentColorStore ||
      !$secondaryColorStore ||
      !isUserAdminStore
    ) {
      fetchData();
    } else {
      username = $usernameStore;
      avatar = $avatarStore;
      accentColor = $accentColorStore;
      secondaryColor = $secondaryColorStore;
      isUserAdmin = $isUserAdminStore;
    }
  });

  onDestroy(() => {
    isMounted = false;
  });
</script>

<!-- <svelte:head>
  <link
    href="https://fonts.googleapis.com/css?family=Gelasio"
    rel="stylesheet" />
</svelte:head> -->

<div
  class="w-full h-14 text-truegray-700 bg-white dark:text-truegray-200 dark:bg-truegray-900 shadow dark:shadow-2xl">
  <div
    class="flex flex-col max-w-screen-xl h-14 px-4 mx-auto md:items-center md:justify-between md:flex-row md:px-6 lg:px-8">
    <div class="p-2 flex flex-row items-center justify-between">
      <NavLink to="/" externalClasses="-ml-4">
        <img
          class="block lg:hidden h-12 w-auto"
          src="/img/doc-logo-{$appTheme}.png"
          alt="DOC logo" />
        <img
          class="hidden lg:block h-12 w-auto"
          src="/img/doc-logo-{$appTheme}.png"
          alt="DOC logo" />
      </NavLink>
      <button
        class="md:hidden rounded-lg focus:outline-none focus:shadow-outline"
        on:click="{() => (isMobileMenuOpen = !isMobileMenuOpen)}">
        <svg fill="currentColor" viewBox="0 0 20 20" class="w-6 h-6">
          {#if !isMobileMenuOpen}
            <path
              fill-rule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
              clip-rule="evenodd"></path>
          {:else}
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"></path>
          {/if}
        </svg>
      </button>
    </div>
    <nav
      class:flex="{isMobileMenuOpen}"
      class:hidden="{!isMobileMenuOpen}"
      class="dark:bg-truegray-900 text-truegray-700 dark:text-truegray-200 bg-white z-20 flex-col flex-grow pb-4 md:pb-0 md:flex md:justify-end md:flex-row flex">
      <NavLink to="orders">Orders</NavLink>

      <NavLink to="clients">Clients</NavLink>

      <!-- <NavLink to="inbox">Inbox</NavLink> -->
      {#if isUserAdmin}
        <NavLink to="stats">Revenue + Stats</NavLink>
        <NavLink to="scripts/write">Templates</NavLink>
      {:else}
        <NavLink to="leads">Leads</NavLink>
        <NavLink to="scripts/read">Templates</NavLink>
      {/if}
      <NavLink to="crm">CRM</NavLink>
      <div
        class="relative"
        use:clickOutside
        on:click_outside="{() => isCollectiondropdownMenuOpen = false}">
        <button
          class="flex flex-row items-center w-full px-4 py-2 mt-2 text-base font-semibold text-left bg-transparent rounded-lg dark:bg-transparent dark:focus:text-white dark:hover:text-white dark:focus:bg-truegray-600 dark:hover:bg-truegray-600 md:w-auto md:inline md:mt-0 md:ml-4 hover:text-truegray-900 focus:text-truegray-900 hover:bg-truegray-200 focus:bg-truegray-200 focus:outline-none focus:shadow-outline"
          on:click="{() => {
            isCollectiondropdownMenuOpen = !isCollectiondropdownMenuOpen;
          }}">
          <span>Collections</span>
          <svg
            fill="currentColor"
            viewBox="0 0 20 20"
            class="inline w-4 h-4 mt-1 ml-1 transition-transform duration-200 transform md:-mt-1"
            class:rotate-180="{isCollectiondropdownMenuOpen}"
            class:rotate-0="{!isCollectiondropdownMenuOpen}">
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"></path>
          </svg>
        </button>
        <div
          x-transition:enter="transition ease-out duration-100"
          x-transition:enter-start="transform opacity-0 scale-95"
          x-transition:enter-end="transform opacity-100 scale-100"
          x-transition:leave="transition ease-in duration-75"
          x-transition:leave-start="transform opacity-100 scale-100"
          x-transition:leave-end="transform opacity-0 scale-95"
          class:flex="{isCollectiondropdownMenuOpen}"
          class:hidden="{!isCollectiondropdownMenuOpen}"
          class="absolute right-0 w-full mt-2 origin-top-right rounded-md shadow-lg md:w-48 z-10">
          <div
            in:scale="{{ duration: 150, start: 0.6 }}"
            out:scale="{{ duration: 150, start: 0.6 }}"
            class="absolute right-0 w-full mt-2 origin-top-right rounded-md shadow-md dark:shadow-blue-md md:w-48 z-20">
            <div
              class="px-2 py-2 bg-white rounded-md shadow-md dark:shadow-blue-md dark:bg-truegray-800"
              on:click="{() =>
                Object.keys(dropdown).forEach((i) => (dropdown[i] = 0))}">
              <NavLink
                to="collections/gen"
                externalClasses="text-base font-medium leading-5 block px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark:bg-transparent dark:hover:bg-truegray-600 dark:focus:bg-truegray-600 dark:focus:text-white dark:hover:text-white dark:text-truegray-200 md:mt-0 hover:text-truegray-900 focus:text-truegray-900 hover:bg-truegray-400 focus:bg-truegray-400 focus:outline-none focus:shadow-outline"
                >Generate</NavLink>
              <NavLink
                to="collections/bww"
                externalClasses="text-base font-medium leading-5 block px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark:bg-transparent dark:hover:bg-truegray-600 dark:focus:bg-truegray-600 dark:focus:text-white dark:hover:text-white dark:text-truegray-200 md:mt-0 hover:text-truegray-900 focus:text-truegray-900 hover:bg-truegray-400 focus:bg-truegray-400 focus:outline-none focus:shadow-outline"
                >Buffalo</NavLink>
              <NavLink
                to="collections/itec"
                externalClasses="text-base font-medium leading-5 block px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark:bg-transparent dark:hover:bg-truegray-600 dark:focus:bg-truegray-600 dark:focus:text-white dark:hover:text-white dark:text-truegray-200 md:mt-0 hover:text-truegray-900 focus:text-truegray-900 hover:bg-truegray-400 focus:bg-truegray-400 focus:outline-none focus:shadow-outline"
                >iTec</NavLink>
              <NavLink
                to="collections/thread"
                externalClasses="text-base font-medium leading-5 block px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark:bg-transparent dark:hover:bg-truegray-600 dark:focus:bg-truegray-600 dark:focus:text-white dark:hover:text-white dark:text-truegray-200 md:mt-0 hover:text-truegray-900 focus:text-truegray-900 hover:bg-truegray-400 focus:bg-truegray-400 focus:outline-none focus:shadow-outline"
                >Thread</NavLink>
              <NavLink
                to="collections/pending"
                externalClasses="text-base font-medium leading-5 block px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark:bg-transparent dark:hover:bg-truegray-600 dark:focus:bg-truegray-600 dark:focus:text-white dark:hover:text-white dark:text-truegray-200 md:mt-0 hover:text-truegray-900 focus:text-truegray-900 hover:bg-truegray-400 focus:bg-truegray-400 focus:outline-none focus:shadow-outline"
                >Pending</NavLink>
              <NavLink
                to="collections/all"
                externalClasses="text-base font-medium leading-5 block px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark:bg-transparent dark:hover:bg-truegray-600 dark:focus:bg-truegray-600 dark:focus:text-white dark:hover:text-white dark:text-truegray-200 md:mt-0 hover:text-truegray-900 focus:text-truegray-900 hover:bg-truegray-400 focus:bg-truegray-400 focus:outline-none focus:shadow-outline"
                >Total</NavLink>
            </div>
          </div>
        </div>
      </div>
      <div
        class="relative"
        use:clickOutside
        on:click_outside="{() => isVerificationdropdownMenuOpen = false}">
        <button
          class="flex flex-row items-center w-full px-4 py-2 mt-2 text-base font-semibold text-left bg-transparent rounded-lg dark:bg-transparent dark:focus:text-white dark:hover:text-white dark:focus:bg-truegray-600 dark:hover:bg-truegray-600 md:w-auto md:inline md:mt-0 md:ml-4 hover:text-truegray-900 focus:text-truegray-900 hover:bg-truegray-200 focus:bg-truegray-200 focus:outline-none focus:shadow-outline"
          on:click="{() =>
            (isVerificationdropdownMenuOpen =
              !isVerificationdropdownMenuOpen)}">
          <span>Verification</span>
          <svg
            fill="currentColor"
            viewBox="0 0 20 20"
            class="inline w-4 h-4 mt-1 ml-1 transition-transform duration-200 transform md:-mt-1"
            class:rotate-180="{isVerificationdropdownMenuOpen}"
            class:rotate-0="{!isVerificationdropdownMenuOpen}"
            ><path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"></path>
          </svg>
        </button>

        <div
          x-transition:enter="transition ease-out duration-100"
          x-transition:enter-start="transform opacity-0 scale-95"
          x-transition:enter-end="transform opacity-100 scale-100"
          x-transition:leave="transition ease-in duration-75"
          x-transition:leave-start="transform opacity-100 scale-100"
          x-transition:leave-end="transform opacity-0 scale-95"
          class:flex="{isVerificationdropdownMenuOpen}"
          class:hidden="{!isVerificationdropdownMenuOpen}"
          class="absolute right-0 w-full mt-2 origin-top-right rounded-md shadow-lg md:w-48 z-10">
          <div
            in:scale="{{ duration: 150, start: 0.6 }}"
            out:scale="{{ duration: 150, start: 0.6 }}"
            class="absolute right-0 w-full mt-2 origin-top-right rounded-md shadow-md dark:shadow-blue-md md:w-48 z-20"
            on:click="{() =>
              Object.keys(dropdown).forEach((i) => (dropdown[i] = 0))}">
            <div
              class="px-2 py-2 bg-white rounded-md shadow-md dark:shadow-blue-md dark:bg-truegray-800">
              <NavLink
                to="vv"
                externalClasses="text-base font-medium leading-5 block px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark:bg-transparent dark:hover:bg-truegray-600 dark:focus:bg-truegray-600 dark:focus:text-white dark:hover:text-white dark:text-truegray-200 md:mt-0 hover:text-truegray-900 focus:text-truegray-900 hover:bg-truegray-400 focus:bg-truegray-400 focus:outline-none focus:shadow-outline"
                >Vendor</NavLink>
              <NavLink
                to="dv/bww"
                externalClasses="text-base font-medium leading-5 block px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark:bg-transparent dark:hover:bg-truegray-600 dark:focus:bg-truegray-600 dark:focus:text-white dark:hover:text-white dark:text-truegray-200 md:mt-0 hover:text-truegray-900 focus:text-truegray-900 hover:bg-truegray-400 focus:bg-truegray-400 focus:outline-none focus:shadow-outline"
                >Buffalo</NavLink>
              <NavLink
                to="dv/itec"
                externalClasses="text-base font-medium leading-5 block px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark:bg-transparent dark:hover:bg-truegray-600 dark:focus:bg-truegray-600 dark:focus:text-white dark:hover:text-white dark:text-truegray-200 md:mt-0 hover:text-truegray-900 focus:text-truegray-900 hover:bg-truegray-400 focus:bg-truegray-400 focus:outline-none focus:shadow-outline"
                >iTec</NavLink>
              <NavLink
                to="dv/thread"
                externalClasses="text-base font-medium leading-5 block px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark:bg-transparent dark:hover:bg-truegray-600 dark:focus:bg-truegray-600 dark:focus:text-white dark:hover:text-white dark:text-truegray-200 md:mt-0 hover:text-truegray-900 focus:text-truegray-900 hover:bg-truegray-400 focus:bg-truegray-400 focus:outline-none focus:shadow-outline"
                >Thread</NavLink>
            </div>
          </div>
        </div>
      </div>
      <div
        class="absolute right-0 flex items-center pr-2 sm:static
        sm:inset-auto sm:ml-6 sm:pr-0">
        <!-- Profile dropdown -->
        <div
          class="ml-3 relative cursor-pointer"
          use:clickOutside
          on:click_outside="{() => isProfileDropdown = 0}">
          <div class="flex -space-x-4">
            <button
              class="flex text-sm border-2 border-black rounded-full
                focus:outline-none focus:border-red-500 transition duration-150
                ease-in-out"
              id="user-menu"
              aria-label="User menu"
              aria-haspopup="true"
              on:click="{() =>
                (isProfileDropdown = isProfileDropdown ? 0 : 1)}">
              <span class="relative inline-block">
                <img
                  class="h-8 w-8 rounded-full"
                  src="/img/avatars/{avatar}.png"
                  alt="" />
                {#if approvalPendingCount > 0}
                  <NavLink
                    to="/"
                    externalClasses="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
                    {approvalPendingCount}
                  </NavLink>
                {/if}
              </span>
            </button>
          </div>
          <!--
            Profile dropdown panel, show/hide based on dropdown state.

            Entering: "transition ease-out duration-100"
              From: "transform opacity-0 scale-95"
              To: "transform opacity-100 scale-100"
            Leaving: "transition ease-in duration-75"
              From: "transform opacity-100 scale-100"
              To: "transform opacity-0 scale-95"
          -->
          <div
            class="origin-top-right absolute right-0 mt-2 w-48 rounded-md
              shadow-lg dark:shadow-blue-lg {toggleDisplay[isProfileDropdown]}">
            <div
              class="py-1 rounded-md bg-white dark:bg-truegray-900 ring-1 ring-black ring-opacity-5"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="user-menu">
              <div
                class="block px-4 py-2 text-sm leading-5 text-truegray-700 dark:text-truegray-200
                  hover:bg-truegray-100 dark:hover:bg-truegray-600  focus:outline-none focus:bg-truegray-100
                  transition duration-150 ease-in-out">
                Your Profile
              </div>
              <div
                on:click="{() => {
                  appTheme.set($appTheme == 'light' ? 'dark' : 'light');
                  location.reload();
                  return false;
                }}"
                class="block px-4 py-2 text-sm leading-5 text-truegray-700 dark:text-truegray-200
                  hover:bg-truegray-100 dark:hover:bg-truegray-600  focus:outline-none focus:bg-truegray-100
                  transition duration-150 ease-in-out">
                Toggle Theme
            </div>
              <div
                on:click="{() => {
                  getData('https://test.dev/user/logout');
                  lastLoginStatusCheckStore.set(
                    new Date('2022-01-02').toISOString()
                  );
                  location.reload();
                  $avatarStore = null;
                  $employeeDataStore = 0;
                  return false;
                }}"
                class="block px-4 py-2 text-sm leading-5 text-truegray-700 dark:text-truegray-200
                  hover:bg-truegray-100 dark:hover:bg-truegray-600 focus:outline-none focus:bg-truegray-100
                  transition duration-150 ease-in-out">
                Sign out
            </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</div>

<style>
  .dropdown:hover .dropdown-menu {
    display: block;
    z-index: 20;
  }
</style>
