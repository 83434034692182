<script>
  export let id = "";
  export let text = "";
  export let offText = "";
  export let value = "";
  export let checked = false;
  export let disabled = false;
  export let useAlternateColors = false;
</script>

<label for="{id}">
  <div class="switch">
    <input
      id="{id}"
      name="{id}"
      type="checkbox"
      class="sr-only"
      disabled="{disabled}"
      {value}
      bind:checked />
    <div class="{useAlternateColors ? 'trackAlt' : 'track'}"></div>
    <div class="{useAlternateColors ? 'thumbAlt' : 'thumb'}"></div>
  </div>
  <span class="ml-2 cursor-pointer dark:text-truegray-200"
    >{!checked && offText ? offText : text}</span>
</label>

<style>
  .switch {
    @apply relative inline-block align-middle cursor-pointer select-none bg-transparent;
  }
  .track {
    @apply w-12 h-6 bg-gray-600 rounded-full shadow-inner;
  }
  .thumb {
    @apply transition-all duration-300 ease-in-out absolute top-0 left-0 w-6 h-6 bg-white dark:bg-truegray-900 border-2 border-gray-600 rounded-full;
  }
  input[type="checkbox"]:checked ~ .thumb {
    @apply transform translate-x-full border-green-500;
  }
  input[type="checkbox"]:checked ~ .track {
    @apply transform transition-colors bg-green-500;
  }
  input[type="checkbox"]:disabled ~ .track {
    @apply bg-gray-500;
  }
  input[type="checkbox"]:disabled ~ .thumb {
    @apply bg-gray-100 dark:bg-truegray-800 border-gray-500;
  }
  input[type="checkbox"]:focus + .track,
  input[type="checkbox"]:active + .track {
    @apply ring;
  }

  .trackAlt {
    @apply w-12 h-6 bg-purple-600 rounded-full shadow-inner;
  }
  .thumbAlt {
    @apply transition-all duration-300 ease-in-out absolute top-0 left-0 w-6 h-6 bg-white dark:bg-truegray-900 border-2 border-purple-600 rounded-full;
  }
  input[type="checkbox"]:checked ~ .thumbAlt {
    @apply transform translate-x-full border-orange-500;
  }
  input[type="checkbox"]:checked ~ .trackAlt {
    @apply transform transition-colors bg-orange-500;
  }
  input[type="checkbox"]:disabled ~ .trackAlt {
    @apply bg-purple-500;
  }
  input[type="checkbox"]:disabled ~ .thumbAlt {
    @apply bg-purple-100 dark:bg-truegray-800 border-purple-500;
  }
  input[type="checkbox"]:focus + .trackAlt,
  input[type="checkbox"]:active + .trackAlt {
    @apply ring;
  }
</style>
