import App from "./App.svelte";
import { appTheme } from "./stores/index";
import { get } from "svelte/store";

if ('serviceWorker' in navigator && 'process.env' != 'dev') {
  navigator.serviceWorker.register('./service-worker.js')
    .then((reg) => {
      // registration worked
      console.log('Registration succeeded.');
    }).catch((error) => {
      // registration failed
      console.log('Registration failed with ' + error);
    });
}

const app = new App({
  target: document.body,
});

// set CSS variable
document.documentElement.style.setProperty(`--appTheme`, `'${get(appTheme) == 'light' ? '0c0c0c' : '#c1c1c1'}'`);

export default app;
