export default [
    {
        label: "Name", // Column name
        field: "name", // Field name from row
        numeric: false, // Affects sorting
        html: false // Escapes output if false.
    },
    {
        label: "Revision", // Column name
        field: function(data) {
            if (data?.revisionCount > 0) return "revised";
        }, // Field name from row, // Field name from row
        numeric: true, // Affects sorting
        html: false // Escapes output if false.
    },
    {
        label: "Price",
        field: function (data) {
            return "$" + data.price;
        },
        numeric: false,
        html: true
    },
    {
        label: "Client",
        field: "clientname",
        numeric: false,
        html: false
    },
    {
        label: "Vendor",
        field: "vendorname",
        numeric: false,
        html: false
    },
    {
        label: "Time",
        field: "date",
        numeric: false,
        html: false
    }
]