<script>
    import {onDestroy, onMount} from 'svelte';
    import Datatable from '../components/datatable/DataTable.svelte';
    import Spinner from '../components/Spinner.svelte';
    import Form from '../components/forms/ClientForm.svelte';
    import Chip from '../components/Chip.svelte';
    import {appTheme} from '../stores/index';
    import {getData} from '../services/fetchData.js';
    import {submitData} from '../services/postData.js';
    import columns from '../components/datatable/column-data/clients.js';

    let printable = false;
    let exportable = false;

    let showForm = false;

    let clientDt = {time: null, name: null};

    let clientId;
    let clientName;
    let email;
    let phone;
    let address;
    let selectedPaymentMethod;
    let selectedCompany;
    let selectedEmployee;
    let orderNotes;

    let fetchClients;
    let clientsData;
    let isMounted;

    let formTitle = 'Add Client';
    let postURL;
    let isFormInvalid;

    let PPTPrice;
    let isTCRequired = true;
    let isFlatRate = true;
    let emergencyMode;

    let body;
  export let showSpinner;
  $: showSpinner = fetchClients;

  export let chipMessage;
  export let mildError,
        severeError,
        infoMsg,
        warnMsg,
        actionSuccess,
        internalSuccess = false;

    const clearFormInputs = () => {
        clientName = '';
        email = '';
        phone = '';
        address = '';
        orderNotes = '';
        selectedPaymentMethod = null;
        selectedCompany = null;
        selectedEmployee = null;
    };

    const prepareBody = () => {
        body = {
            id: clientId,
            name: clientName,
            email: email,
            phone: phone,
            address: address,
            orderNotes: orderNotes,
            paym: selectedPaymentMethod,
            companyid: selectedCompany,
            employeeid: selectedEmployee,
            isflatrate: isFlatRate,
            istcrequired: isTCRequired,
            emergencymode: emergencyMode,
            pptprice: PPTPrice,
        };
    };

    const fetchData = async () => {
        if (isMounted) {
            fetchClients = getData('https://test.dev/client/read');
            [fetchClients] = await Promise.all([fetchClients]);
            clientsData = await fetchClients.json();
        }
    };

    const handleKeydown = (e) => {
        if (e.key === 'a' && e.altKey) {
            handleNewClient();
        }
    };

    let submit = async () => {
        if (isFormInvalid) return;
        prepareBody();
        if (isMounted) {
            const response = await submitData(postURL, body);

            if (response.ok) {
                chipMessage = 'Successfully added/edited';
                actionSuccess = true;
                fetchData();
                showForm = false;
                clearFormInputs();
            }
        }
    };

    const updateClientLastCalled = (id) => {
        fetchClients = getData(`https://test.dev/client/updateLastCalled/${id}`);
    };

    const getClientCurrentTime = async (address) => {
        const timeZoneKey =
            'AtjOUCMZmj3S0tmmRlCpbbKc5EVbPcJ4Uy1ETO_ILJZeWmH47RuzXjsmVQ4puzK2';
        const response = await getData(
            'https://dev.virtualearth.net/REST/v1/TimeZone/?query=' +
            address +
            '&key=' +
            timeZoneKey,
            false
        );
        const result = await response.json();
        const clientLocationTime =
            result.resourceSets[0].resources[0].timeZoneAtLocation[0]?.timeZone[0]
                ?.convertedTime?.localTime;
        if (clientLocationTime) {
            const dateTime = new Date(clientLocationTime);
            return dateTime;
        }
        return null;
    };

    const copyPhoneNum = (e) => {
        navigator.clipboard.writeText(e.detail.row.phone).then(() => {
            chipMessage = 'Phone number copied to clipboard';
            internalSuccess = true;
        });
    };

    const handlePhoneNumbers = async (e) => {
        updateClientLastCalled(e.detail.row.clientid);
        if (e.detail.event.ctrlKey)
            window.location.href = `callto:+1${e.detail.row.phone}`;
        else copyPhoneNum(e);
        if (e.detail.row.address.length > 20) {
            clientDt.time = await getClientCurrentTime(e.detail.row.address);
            clientDt.name = e.detail.row.clientname.split(' ').shift();
            setTimeout(() => (clientDt = {time: null, name: null}), 5000);
        }
    };

    const handleRowClick = async (e) => {
        let elementName = e.detail.target.name;
        if (!elementName) elementName = e.detail.target.getAttribute('name');

        const selectedText = window.getSelection().toString().trim();
        if (
            selectedText.length > 5 &&
            !e.detail.target.name &&
            !/[a-zA-Z]/g.test(selectedText)
        )
            handlePhoneNumbers(e);

        switch (elementName) {
            case 'callClientNum':
                handlePhoneNumbers(e);
                break;

            case 'editClientBtn':
                clientId = e.detail.row.clientid;
                clientName = e.detail.row.clientname;
                email = e.detail.row.email;
                phone = e.detail.row.phone;
                address = e.detail.row.address;
                selectedPaymentMethod = e.detail.row.paym;
                selectedCompany = e.detail.row.companyid;
                selectedEmployee = e.detail.row.employeeid;
                orderNotes = e.detail.row.ordernotes;
                isFlatRate = e.detail.row.isflatrate == 1 ? true : false;
                isTCRequired = e.detail.row.applycharge == 1 ? true : false;
                emergencyMode = e.detail.row.emergencymode == 1 ? true : false;
                PPTPrice = e.detail.row.pptprice;

                formTitle = 'Edit Client';
                showForm = true;
                postURL = 'https://test.dev/client/update';
                break;

            case 'deleteClientBtn':
                postURL = 'https://test.dev/client/delete';
                (async () => {
                    const response = await submitData(postURL, {
                        id: e.detail.row.clientid,
                    });
                    if (response.ok) {
                        chipMessage = 'Successfully deleted';
                        warnMsg = true;
                        fetchData();
                    }
                })();
                break;
        }
    };

    const handleNewClient = () => {
        postURL = 'https://test.dev/client/create';
        clearFormInputs();
        formTitle = 'Add Client';
        showForm = true;
    };

    onMount(() => {
        isMounted = true;
        fetchData();
        clearFormInputs();
    });

    onDestroy(() => {
        isMounted = false;
    });
</script>

<svelte:head>
    <title>Clients</title>
</svelte:head>

<div>



    <div class:hide="{showForm}" class:show="{!showForm}">
        <Datatable
                columns="{columns}"
                exportable="{exportable}"
                hidden="{showForm}"
                on:row-click="{handleRowClick}"
                printable="{printable}"
                process="local"
                rows="{clientsData}"
                title="Clients">
            <div slot="info">
                {#if clientDt.time}
                    <p class="dark:text-truegray-50 -mt-4 absolute">
                        {clientDt.name}'s current time is
                        <span class="text-green-500">
              {clientDt.time.toLocaleString('en-US', {
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
              })}</span>
                    </p>
                {/if}
            </div>
            <div slot="action-button">
                <button class="border-none rounded-r" on:click="{handleNewClient}">
                    <img alt="" class="h-10 w-10" src="/img/icons/{$appTheme}/plus.svg"/>
                </button>
            </div>
        </Datatable>
    </div>

    {#if showForm}
        <div class="form">
            <Form
                    bind:clientName
                    id="{clientId}"
                    bind:email
                    bind:phone
                    bind:address
                    bind:selectedPaymentMethod
                    bind:selectedCompany
                    bind:selectedEmployee
                    bind:isFormInvalid
                    bind:orderNotes
                    bind:isFlatRate
                    bind:isTCRequired
                    bind:emergencyMode
                    bind:PPTPrice
                    submit="{submit}"
                    bind:showForm
                    title="{formTitle}"/>
        </div>
    {/if}
</div>

<svelte:window on:keydown="{handleKeydown}"/>

<style>
    .hide {
        position: absolute;
        opacity: 0;
        display: none;
    }

    .show {
        position: static;
        opacity: 1;
        display: block;
    }

    .form {
        opacity: 0;
        animation: fadeInUp 0.5s ease 0s forwards;
    }

    /* defines the animation */
    @keyframes fadeInUp {
        from {
            opacity: 0;
            -webkit-transform: translate3d(0, 50%, 0);
            transform: translate3d(0, 50%, 0);
        }

        to {
            opacity: 1;
            -webkit-transform: none;
            transform: none;
        }
    }
</style>
