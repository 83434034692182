import { appTheme } from "../../../stores/index";
import {get } from "svelte/store";

export default [
    // Array of objects
    {
        label: "Clients", // Column name
        field: "clientname", // Field name from row
        numeric: false, // Affects sorting
        html: false, // Escapes output if false.
    },
    {
        label: "Total Due", // Column name
        field: "awss", // Field name from row
        numeric: true, // Affects sorting
        html: false, // Escapes output if false.
    },
    {
        label: "Total Due",
        field: function(data) {
            return "<p class='py-2 h-10'> $" + data.awss + "</p>";
        },
        numeric: false,
        html: true,
    },
    {
        label: "Status",
        field: function(data) {
            return (
                '<button name="statusChangeBtn" class="relative border-none">' +
                data.statusInfo +
                "</button>"
            );
        },
        numeric: false,
        html: true,
    },
    {
        label: "",
        field: function(data) {
            let btnName, icon;
            const slideClass = (data.last_emailed_weeks_ago > 1 && data.status == 0 && data.paym != "Credit Card") || (data.thanked == 0 && data.status == 1 && data.awss > 0) ? 'slide-in' : 'hidden';
            if (data.status == 1) {
                icon = 'send';
                btnName = 'sendThankYouEmailBtn'
            } else {
                icon = 'remind';
                btnName = 'sendInvoiceReminderEmailBtn';
            }
            return (
                '<button class="outline-none border-none ' + slideClass + '" id="slider" style="margin-left: -20px">' +
                `<img class="h-10 w-10 border-none" name=${btnName} src="/img/icons/` + get(appTheme) + `/invoice-${icon}.svg"/>` +
                "</button>"
            );
        },
        numeric: false,
        html: true,
    },
    {
        label: "Status Variable",
        field: "status",
        numeric: true,
        html: false,
    },
    {
        label: "Employee",
        field: "employee",
        numeric: true,
        html: false,
    },
    {
        label: "Inv. #",
        field: function(data) {
            return "#" + data.id;
        },
        numeric: false,
        html: false,
    },
    {
        label: "Email",
        field: "email",
        numeric: false,
        html: false,
    },
    {
        label: "Month",
        field: "date",
        numeric: false,
        html: false,
    },
    {
        label: "Company",
        field: "companyname",
        numeric: false,
        html: false,
    },
    {
        label: "Pay Method",
        field: "paym",
        numeric: false,
        html: false,
    },
    {
        label: "W/O Charges",
        field: function(data) {
            return "$" + (parseInt(data.amount)).toFixed(2);
        },
        numeric: false,
        html: true,
    },
];