<script>
    import {onMount} from 'svelte';
    import {getTextboxSupportedTypes} from '../../helpers/functions';
    import {getData} from '../../services/fetchData';
    import {appTheme} from '../../stores/index';
    import Select from '../select/Select.svelte';
    import Textbox from './components/Textbox.svelte';
    import {emailTemplatesStore} from "./stores/index2";

    export let submit = () => console.log('submit was clicked');
    export let icon = 'plus.svg';

    export let isDisabled;

    const schedules = [
        {label: 'days', value: 'd'},
        {label: 'weeks', value: 'w'},
        {label: 'months', value: 'm'},
    ];

    const weekDays = [
        {label: 'Monday', value: 1},
        {label: 'Tuesday', value: 2},
        {label: 'Wednesday', value: 3},
        {label: 'Thursday', value: 4},
        {label: 'Friday', value: 5},
    ];

    const partsOfDay = [
        {label: 'Morning', value: 'm'},
        {label: 'Afternoon', value: 'a'},
        {label: 'Evening', value: 'e'},
    ];

    let emailTemplates = [];

    export let timeInterval;
    export let repeatSchedule;
    export let partOfDay;
    export let repeatOnDay;
    export let selectedEmailTemplate;
    export let email;
    export let client;
    export let response;

    let fetchEmailTemplate;

    const fetchTemplatesData = async () => {
        fetchEmailTemplate = await getData('https://test.dev/email/read');
        const templateData = await fetchEmailTemplate.json();
        templateData.map((x) =>
            emailTemplates.push({
                label: x.title,
                value: x.id,
                variables: x?.variables
            })
        );

        emailTemplates = [...emailTemplates];
        $emailTemplatesStore = emailTemplates;
    };

    const fetchClientEmailData = async () => {
        if (!client?.id) return;
        response = await getData(`https://test.dev/client/email/${client?.id}`);
        email = await response.json();
    };

    onMount(() => {
        if ($emailTemplatesStore.length === 0)
            fetchTemplatesData();
        else
            emailTemplates = $emailTemplatesStore;
    });
    $: if (client?.id) fetchClientEmailData();
    $: emailTemplates = emailTemplates.filter(x => Object.keys(x.variables).length === 0);
</script>

{#if client?.id}
    <p class="text-2xl dark:text-white font-semibold leading-tight px-10 pt-0">
        {client?.name}
    </p>
{/if}
<div class="flex px-8">
    <div
            class="text-center p-2 pt-4  text-lg text-truegray-900 dark:text-truegray-50">
        Every
    </div>
    <div class="flex-auto w-1 p-2">
        <Textbox
                bind:text="{timeInterval}"
                isDisabled="{isDisabled}"
                placeholder="𝑥"
                type="{getTextboxSupportedTypes().number}"/>
    </div>
    <div class="flex-auto w-1/12 p-2 ">
        <Select
                bind:selectedItemId="{repeatSchedule}"
                isDisabled="{isDisabled}"
                items="{schedules}"
                placeholder="Frequency"/>
    </div>
    <div
            class="py-2 pt-4 text-center text-lg text-truegray-900 dark:text-truegray-50">
        on
    </div>
    <div class="flex-auto w-1/12 p-2 ">
        <Select
                bind:selectedItemId="{repeatOnDay}"
                isDisabled="{repeatSchedule === 'd'}"
                items="{weekDays}"
                placeholder="Day of the week"/>
    </div>
    <div class="flex-auto w-1/12 p-2 ">
        <Select
                bind:selectedItemId="{partOfDay}"
                isDisabled="{isDisabled}"
                items="{partsOfDay}"
                placeholder="Part of day"/>
    </div>
    <div
            class="py-2 pt-4 text-center text-lg text-truegray-900 dark:text-truegray-50">
        send
    </div>
    <div class="flex-auto w-2/12 p-2 ">
        <Select
                bind:selectedItemId="{selectedEmailTemplate}"
                isDisabled="{isDisabled}"
                items="{emailTemplates}"
                placeholder="Email Template"/>
    </div>
</div>

<div class="flex flex-wrap px-10">
    <div class="pr-5 mt-2 text-truegray-800 dark:text-truegray-200">Email</div>
    <div class="w-5/6">
        <Textbox bind:text="{email}" placeholder="Email Address"/>
    </div>
    <button class="border-none text-2xl ml-6" on:click="{submit}">
        <img
                alt=""
                class="h-10 w-10 cursor-pointer"
                src="/img/icons/{$appTheme}/{icon}"/>
    </button>
</div>
