import { collect } from "./data-grid";
import { fuzzy } from "../../helpers/fuzzy";

const allItemsUniqueStrings = new Set();

export function getPaged(props, pred, paginated) {
    const { selectedPage } = paginated;
    if (!pred || pred({ page: selectedPage })) {
        if (props.page) {
            props.currentPage = props.page;
        }
        paginated = Object.assign(paginated, props);
        processRows(props.rows || paginated.rows, props.searchText, paginated);
        return paginated;
    }
}

function paginateRows(rows, paginated) {
    let paginatedRows = rows;
    const { currentPerPage, currentPage, paginate } = paginated;
    if (paginate) {
        paginatedRows = paginatedRows.slice(
            (currentPage - 1) * currentPerPage,
            currentPerPage === -1 ?
            paginatedRows.length + 1 :
            currentPage * currentPerPage
        );
    }
    paginated.paginatedRows = paginatedRows;
}

function combineAllKeyValues(obj, separator) {
    if (obj.hasOwnProperty('all')) {
        return obj;
    }

    function getTextToAdd(element) {
        if (!obj[element]) {
            return "";
        }
        const textToAdd = obj[element].toString();
        const listOfText = textToAdd.trim().split(/\s+/);

        if (listOfText[0] !== "") {
            listOfText.forEach(element => {
                allItemsUniqueStrings.add(element);
            });
        }

        return textToAdd;
    }

    separator = separator || " ";
    obj = {...obj, all: Object.keys(obj).map(getTextToAdd).filter(String).join(separator) };
    return obj;
}

function processRows(rows, searchText, paginated) {
    if (!Array.isArray(rows)) return 0;

    let computedRows = rows.slice(0);
    const {
        currentPerPage,
        columns,
        sortable,
        sortColumn,
        sortType,
        searchInput
    } = paginated;

    if (!searchText) {
        searchText = searchInput;
    }

    if (sortable !== false && sortColumn > -1 && columns) {
        computedRows = computedRows.sort((x, y) => {
            if (!columns[sortColumn]) return 0;

            const cook = (x) => {
                x = collect(x, columns[sortColumn].field);
                if (typeof x === "string") {
                    x = x.toLowerCase();
                    if (columns[sortColumn].numeric)
                        x = x.indexOf(".") >= 0 ? parseFloat(x) : parseInt(x);
                }
                return x;
            };

            x = cook(x);
            y = cook(y);

            return (x < y ? -1 : x > y ? 1 : 0) * (sortType === "desc" ? -1 : 1);
        });
    }

    if (searchText) {
        computedRows = computedRows.map(v => combineAllKeyValues(v));
        const searchAll = fuzzy(computedRows, 'all', [...allItemsUniqueStrings]);
        computedRows = searchAll(searchText);
    }

    paginated.pageCount = Math.ceil(computedRows.length / currentPerPage);
    paginated.rowCount = computedRows.length;
    paginateRows(computedRows, paginated);
}