<script>
  import { beforeUpdate, createEventDispatcher, onMount } from 'svelte';
  import Paginate from './Paginate.svelte';
  import { debounce } from './debounce';
  import * as local from './local';
  import { getRelativeTime } from '../../helpers/relativeTime';
  import { collect, exportExcel, print, extractContent } from './data-grid';
  import { immerStore } from './stores';
  import { appTheme } from '../../stores/index';
  import { writable } from '../../helpers/localStorageStore.js';
  import { capitalizeFirstLetter, range } from '../../helpers/functions.js';
  import RowSorter from './rowsorter.min.js';
  import { submitData } from '../../services/postData';
  import Chip from '../Chip.svelte';

  let chipMessage;
  let mildError,
    actionSuccess,
    internalSuccess = false;

  export let title = '';
  export let clickable = true;
  export let printable = true;
  export let exportable = true;
  export let showPageSizeOptions = true;
  export let searchable = true;
  export let searchInputRef = null;
  export let searchInput = '';

  export let hidden = false;

  export let columnToCustomize;
  export let customizedTargetColor;

  export let allowDrag = false;
  export let draggable = false;

  export let sortable = true;
  export let sortColumn = -1;
  export let sortType = 'desc';

  export let defaultPerPageStore = writable(`defaultPerPageStore-${title}`, 0);
  export let currentPerPage = 20;
  export let perPageOptions = [];
  export let currentPage = 1;
  export let rowCount = 0;
  export let pageCount = 0;
  export let selected = 0;
  export let perPage = [20, 30, 40, 50, 75];
  export let paginate = { size: perPage[0], page: 1 };
  export let defaultPerPage = $defaultPerPageStore;

  export let columns = [];
  export let process = 'local';
  // local
  export let rows = [];
  export let paginatedRows = [];
  // server
  export let paged = { paginate: paginate };
  export let getList = null;

  const isKeyPrintable = (keycode) => {
    return (
      (keycode > 47 && keycode < 58) || // number keys
      keycode == 32 || // space
      keycode == 13 || // return key(s) (if you want to allow carriage returns)
      (keycode > 64 && keycode < 91) || // letter keys
      (keycode > 95 && keycode < 112) || // numpad keys
      (keycode > 185 && keycode < 193) || // ;=,-./` (in order)
      (keycode > 218 && keycode < 223)
    ); // [\]' (in order)
  };

  const dispatch = createEventDispatcher();
  const isServerProcess = process === 'server';

  let table;

  const paginated = immerStore({
    paginatedRows,
    rows,
    paginate,
    size: currentPerPage,
    page: currentPage,
  });

  export const handleKeydown = (e) => {
    if (!hidden && searchable) {
      if ((!e.ctrlKey && isKeyPrintable(e.keyCode)) || e.keyCode == 8)
        searchInputRef.focus();

      if (e.ctrlKey && e.key.toLowerCase() == 'a') searchInputRef.select();
    }
    if (e.key === 'Enter' && e.altKey) {
      allowDrag = !allowDrag;
    }
  };

  export function setSortIcon(index) {
    if (!sortable) return;
    if (sortColumn === index) {
      sortType = sortType === 'asc' ? 'desc' : 'asc';
    } else {
      sortType = 'asc';
      sortColumn = index;
    }
    (sortType = 'desc'), (sortColumn = sortColumn);
  }

  export function setPerPageOptions() {
    let options = perPage;
    // Force numbers
    options = options.map((v) => parseInt(v));
    // Set current page to first value
    currentPerPage = options[0];
    // Sort options
    options.sort((a, b) => a - b);
    // If defaultPerPage is provided and it's a valid option, set as current per page
    if (options.indexOf(defaultPerPage) > -1) {
      currentPerPage = parseInt(defaultPerPage);
    }
    (currentPerPage = currentPerPage), (perPageOptions = options);
  }

  $: if (draggable && allowDrag) {
    new RowSorter('#datatable', {
      onDrop: function (tbody, row, new_index, old_index) {
        table = tbody.nodeName === 'TBODY' ? tbody.parentNode : tbody;
      },
    });
  } else {
    RowSorter.destroy('#datatable');
  }

  $: if (!!(draggable && !allowDrag && table)) {
    calculateRowReorder();
  }

  const calculateRowReorder = async (currentTableStateSnapshot) => {
    currentTableStateSnapshot = currentTableStateSnapshot || [];

    if (currentTableStateSnapshot.length == 0) {
      const upperBound = Math.min(rowCount, currentPerPage);
      const rowIndicesInConsideration = range(1, upperBound);
      rowIndicesInConsideration.map((x) =>
        currentTableStateSnapshot.push({
          id: parseInt(table.rows[x].id),
          serial: upperBound - x + 1,
        })
      );
    }

    const shouldEmail = confirm(`Email reorder info to vendors?`);

    const response = await submitData(
      `https://test.dev/order/reorder/${shouldEmail}`,
      currentTableStateSnapshot
    );
    if (response.status != 201) {
      calculateRowReorder(currentTableStateSnapshot);
      chipMessage = 'Something went wrong. Please refresh this page.';
      mildError = true;
    } else {
      table = undefined;
      rows = [];
      chipMessage = 'Successfully reordered';
      actionSuccess = true;
    }
  };

  function copyInvoiceNumber(event) {
    let selectedText = window.getSelection().toString();

    if (selectedText.includes('#') && !isNaN(selectedText.replace('#', ''))) {
      event.preventDefault();

      let clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent.clipboardData;

      const today = new Date();
      today.setDate(0);
      const currentMonth = today.toLocaleString('default', { month: 'long' });
      clipboardData.setData(
        'Text',
        'Invoice ' +
          selectedText +
          ' for the month of ' +
          currentMonth +
          ' ' +
          today.getFullYear()
      );
    }
  }

  export function click(row) {
    if (!clickable) {
      return;
    }
    dispatch('row-click', row);
  }

  export function mousedown(row) {
    if (!clickable) {
      return;
    }

    dispatch('row-mousedown', row);
  }

  export function sort(index) {
    if (index > -1) {
      setSortIcon(index);
      getPaged({ colName: columns[index].field, direction: sortType });
    }
  }

  onMount(() => {
    setPerPageOptions();
  });

  function getPagedLocal(props, pred) {
    const values = local.getPaged(props, pred, {
      paginate,
      selected,
      currentPage,
      currentPerPage,
      sortable,
      sortColumn,
      sortType,
      searchInput,
      rowCount,
      pageCount,
      rows,
      columns,
    });
    ({
      paginate,
      selected,
      currentPage,
      currentPerPage,
      sortable,
      sortColumn,
      sortType,
      searchInput,
      rowCount,
      pageCount,
      rows,
      paginatedRows,
    } = values);
    update(paginatedRows, currentPerPage, selectedPage);
  }

  function getPagedServer(props, pred, paged, cb) {
    const { paginate, getList } = paged;
    if (getList && (!pred || pred(paginate))) {
      const p = Object.assign({}, paginate, props);
      getList(p).then((data) => {
        cb(data);
      });
    }
  }

  function updateServer(paged) {
    const { paginate } = paged;
    rowCount = paginate.total;
    pageCount = paginate.pages;
    currentPage = paginate.page;
    if (paged.getList) {
      getList = paged.getList;
    }
  }

  function updatePaged(data) {
    updateServer(data);
    update(data.rows, data.paginate.size, data.paginate.page);
  }

  function getPaged(props, pred) {
    if (isServerProcess) {
      getPagedServer(props, pred, { paginate, getList }, updatePaged);
    } else {
      getPagedLocal(props, pred);
    }
  }

  $: selectedPage = selected + 1;
  let previous;
  function update(paginatedRows, size, page) {
    paginated.update(($paginated) => {
      $paginated.paginatedRows = paginatedRows;
      $paginated.rows = rows;
      $paginated.size = size;
      $paginated.page = page;
    });
    previous = $paginated;
  }

  $: {
    if (previous) {
      let changed = false;
      if (!isServerProcess && rows !== previous.rows) {
        changed = true;
      }
      if (currentPerPage !== previous.size) {
        changed = true;
      }
      if (selectedPage !== previous.page) {
        changed = true;
      }
      if (changed) {
        getPaged({ rows, size: currentPerPage, page: selectedPage });
        defaultPerPageStore.set(currentPerPage);
      }
    }
    previous = $paginated;
  }

  let prevPaged;
  $: if (paged.rows && paged !== prevPaged) {
    updatePaged(paged);
    prevPaged = paged;
  }

  $: if (paged.rows) {
    updatePaged(paged);
  }

  $: if (rows) {
    const currentShowing =
      (currentPage - 1) * currentPerPage
        ? (currentPage - 1) * currentPerPage
        : 1;
    if (currentShowing > Math.min(rowCount, currentPerPage * currentPage)) {
      selected = 0;
    }
  }

  let prevSearchInput = '';
  beforeUpdate(() => {
    if (searchInput != prevSearchInput) {
      debounce(() => {
        if (searchInput != prevSearchInput) {
          getPaged({ searchText: searchInput });
          prevSearchInput = searchInput;
        }
      }, 400)();
    }
  });
</script>

<!-- component starts here -->
<Chip bind:mildError bind:internalSuccess bind:actionSuccess
  >{chipMessage}</Chip>
<div class="container mx-auto px-4 sm:px-8">
  <div class="py-8">
    <div>
      <h2 class="text-2xl dark:text-white font-semibold leading-tight">
        {title}
      </h2>
    </div>
    <div class="my-2 flex sm:flex-row flex-col">
      {#if showPageSizeOptions}
        <div class="flex flex-row mb-1 sm:mb-0">
          <div class="relative">
            <select
              class="h-10 rounded-l border block w-full bg-white dark:bg-truegray-900 border-gray-400
            dark:border-truegray-700 border-dotted text-gray-700 dark:text-truegray-200 py-2 px-2 leading-tight
            focus:outline-none focus:bg-white dark:focus:bg-truegray-900 dark:bg-truegray-900 focus:border-gray-500"
              bind:value="{currentPerPage}">
              {#each perPageOptions as option, x}
                <option value="{option}">{option}</option>
              {/each}
            </select>
          </div>
        </div>
      {/if}
      {#if searchable}
        <div class="block relative">
          <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2">
            <svg viewBox="0 0 24 24" class="h-4 w-4 fill-current text-gray-500">
              <path
                d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387
                5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z"></path>
            </svg>
          </span>
          <input
            placeholder="Search"
            type="search"
            id="search-input"
            bind:this="{searchInputRef}"
            bind:value="{searchInput}"
            autofocus
            class="appearance-none rounded-r rounded-l sm:rounded-l-none border
            border-gray-400 dark:border-truegray-700 border-dashed block pl-8 pr-8 py-2 h-10 w-full
            bg-white dark:bg-truegray-900 text-sm placeholder-gray-400 text-gray-700 dark:text-truegray-200 focus:bg-white dark:focus:bg-truegray-900 dark:bg-truegray-900
            focus:placeholder-gray-600 focus:text-gray-700 dark:text-truegray-200 focus:outline-none" />
        </div>
      {/if}
      {#if printable}
        <div class="mx-2 h-10 w-10">
          <a
            href="#!"
            on:click="{() => print(columns, $paginated.paginatedRows)}">
            <div class="mx-auto">
              <img
                class="h-10 w-10"
                src="/img/icons/{$appTheme}/pdf.svg"
                alt="" />
            </div>
          </a>
        </div>
      {/if}
      {#if exportable}
        <div class="h-10 w-10">
          <a
            href="#!"
            on:click="{() =>
              exportExcel(columns, $paginated.paginatedRows, title)}">
            <div class="mx-auto">
              <img
                class="h-10 w-10"
                src="/img/icons/{$appTheme}/excel.svg"
                alt="" />
            </div>
          </a>
        </div>
      {/if}
      <div class="m-auto">
        <slot name="info" />
      </div>
      <div class="flex ml-auto">
        <slot name="action-button" />
      </div>
    </div>

    <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
      <div
        class="inline-block min-w-full shadow-md dark:shadow-blue-md rounded-lg overflow-hidden">
        <table class="min-w-full leading-normal" id="datatable">
          <thead>
            <tr>
              {#each columns as column, x}
                <th
                  on:click="{() => sort(x)}"
                  class="{sortable ? 'sorting ' : ''}
                  {sortColumn === x
                    ? sortType === 'desc'
                      ? 'sorting-desc'
                      : 'sorting-asc'
                    : ''}
                  {column.numeric ? 'hidden numeric' : ''} px-5 py-3 border-b-2
                  border-gray-200 dark:border-truegray-700 text-left text-xs font-semibold
                  text-gray-600 uppercase tracking-wider {draggable && allowDrag
                    ? 'bg-gray-900'
                    : 'bg-gray-100 dark:bg-truegray-800'}"
                  style="width: {column.width ? column.width : 'auto'}">
                  <div class="{draggable && allowDrag ? 'anim' : ''}">
                    {column.label}
                  </div>
                </th>
              {/each}
            </tr>
          </thead>
          {#if Array.isArray($paginated.paginatedRows) && $paginated.paginatedRows.length > 0}
            <tbody>
              {#each $paginated.paginatedRows as row, y}
                <tr
                  id="{collect(row, 'designid')}"
                  class="{clickable
                    ? 'clickable'
                    : ''} hover:bg-gray-200 dark:hover:bg-truegray-800 dark:border-truegray-700"
                  on:click="{(e) => click({ target: e.target, row, event: e })}"
                  on:mousedown="{(e) => mousedown({ target: e.target, row })}">
                  {#each columns as column, x}
                    <td
                      class="pl-5 border-b {column.numeric
                        ? 'hidden numeric'
                        : ''}
                  {draggable && allowDrag ? 'sorter unselectable' : ''}
                  border-gray-200 dark:border-truegray-700 text-sm">
                      {#if columnToCustomize && column.label.toLowerCase() == columnToCustomize.toLowerCase()}
                        {#if customizedTargetColor}
                          {#if collect(customizedTargetColor, extractContent(collect(row, column.field)))?.customColorCombo}
                            <div
                              name="{collect(
                                customizedTargetColor,
                                extractContent(collect(row, column.field))
                              )?.name}"
                              class="relative text-sm inline-flex items-center font-bold leading-sm uppercase px-3 py-1 cursor-pointer {collect(
                                customizedTargetColor,
                                extractContent(collect(row, column.field))
                              ).customColorCombo} rounded-full">
                              {#if collect(customizedTargetColor, extractContent(collect(row, column.field)))?.customSvgFormat}
                                {@html collect(
                                  customizedTargetColor,
                                  extractContent(collect(row, column.field))
                                )?.icon}
                              {:else}
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="3%"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-bell-off mr-2">
                                  {@html collect(
                                    customizedTargetColor,
                                    extractContent(collect(row, column.field))
                                  )?.icon}
                                </svg>
                              {/if}
                              <p class="truncateText">
                                {#if column.html}
                                  {@html collect(row, column.field)}
                                {:else}{collect(row, column.field)}{/if}
                              </p>
                            </div>
                          {:else}
                            <div
                              name="{collect(
                                customizedTargetColor,
                                extractContent(collect(row, column.field))
                              )?.name}"
                              class="relative text-sm inline-flex items-center font-bold leading-sm uppercase px-3 py-1 cursor-pointer bg-{collect(
                                collect(
                                  customizedTargetColor,
                                  extractContent(collect(row, column.field))
                                ),
                                'light'
                              )}-200 hover:bg-{collect(
                                collect(
                                  customizedTargetColor,
                                  extractContent(collect(row, column.field))
                                ),
                                'light'
                              )}-300 text-{collect(
                                collect(
                                  customizedTargetColor,
                                  extractContent(collect(row, column.field))
                                ),
                                'light'
                              )}-900 rounded-full dark:bg-{collect(
                                collect(
                                  customizedTargetColor,
                                  extractContent(collect(row, column.field))
                                ),
                                'dark'
                              )}-700 dark:hover:bg-{collect(
                                collect(
                                  customizedTargetColor,
                                  extractContent(collect(row, column.field))
                                ),
                                'dark'
                              )}-600 dark:text-{collect(
                                collect(
                                  customizedTargetColor,
                                  extractContent(collect(row, column.field))
                                ),
                                'dark'
                              )}-300 rounded-full">
                              {#if collect(customizedTargetColor, extractContent(collect(row, column.field)))?.customSvgFormat}
                                {@html collect(
                                  customizedTargetColor,
                                  extractContent(collect(row, column.field))
                                )?.icon}
                              {:else}
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="3%"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-bell-off mr-2">
                                  {@html collect(
                                    customizedTargetColor,
                                    extractContent(collect(row, column.field))
                                  )?.icon}
                                </svg>
                              {/if}
                              <p class="truncateText">
                                {#if column.html}
                                  {@html collect(row, column.field)}
                                {:else}{collect(row, column.field)}{/if}
                              </p>
                            </div>
                          {/if}
                        {/if}
                      {:else}
                        <p
                          class="{column.label &&
                          !column.html &&
                          collect(row, column.field) &&
                          !collect(row, column.field).toString().startsWith('<')
                            ? 'truncateText'
                            : ''} text-gray-900 dark:text-truegray-50"
                          on:copy="{(e) => copyInvoiceNumber(e)}"
                          title="{column.field === 'date'
                            ? new Date(
                                collect(row, column.field)
                              ).toLocaleString('en-US', {
                                timeZone: 'Asia/Karachi',
                                weekday: 'short',
                                hour: 'numeric',
                                minute: 'numeric',
                              })
                            : ''}">
                          {#if column.field === 'date'}
                            {capitalizeFirstLetter(
                              getRelativeTime(
                                new Date(collect(row, column.field))
                              )
                            )}
                          {:else if column.html}
                            {@html collect(row, column.field)}
                          {:else}{collect(row, column.field)}{/if}
                        </p>
                      {/if}
                    </td>
                  {/each}
                </tr>
              {/each}
            </tbody>
          {/if}
        </table>
        <div
          class="px-5 py-5 bg-white dark:bg-truegray-900 border-t dark:border-truegray-700 flex flex-col xs:flex-row
          items-center xs:justify-between ">
          <span class="text-xs xs:text-sm text-gray-900 dark:text-truegray-50">
            Showing
            {(currentPage - 1) * currentPerPage
              ? (currentPage - 1) * currentPerPage
              : 1}
            to
            {Math.min(rowCount, currentPerPage * currentPage)}
            of
            {rowCount}
            entries
          </span>
          <div class="inline-flex mt-2 xs:mt-0">
            <Paginate
              bind:pageCount
              marginPages="2"
              pageRange="4"
              bind:selected
              containerClass="inline-flex mt-2 xs:mt-0"
              prevClass="text-sm mr-2 shadow dark:shadow-blue bg-gray-300 border-none
              hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l"
              nextClass="text-sm bg-gray-300 border-none hover:bg-gray-400
              text-gray-800 font-semibold py-2 px-4 shadow dark:shadow-blue rounded-r">
            </Paginate>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- component ends here -->

<svelte:window on:keydown="{handleKeydown}" />

<style>
  .unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .icon {
    color: black;
    width: 16px;
    height: 16px;
  }

  table.sorting-table {
    cursor: ns-resize;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
  }
  table tr.sorting-row td {
    background-color: #8b8;
  }

  .anim {
    animation-name: buzz;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes buzz {
    1% {
      transform: translate(1px, -1px) rotate(1deg);
    }
    2% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }
    3% {
      transform: translate(-3px, 1px) rotate(0deg);
    }
    4% {
      transform: translate(2px, 1px) rotate(-1deg);
    }
    5% {
      transform: translate(-1px, -1px) rotate(1deg);
    }
    6% {
      transform: translate(2px, 2px) rotate(0deg);
    }
    7% {
      transform: translate(1px, -1px) rotate(1deg);
    }
    9% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }
    10% {
      transform: translate(-3px, 1px) rotate(0deg);
    }
    11% {
      transform: translate(2px, 1px) rotate(-1deg);
    }
    12% {
      transform: translate(-1px, -1px) rotate(1deg);
    }
    13% {
      transform: translate(2px, 2px) rotate(0deg);
    }
    14% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
    15% {
      transform: translate(0px, 0px) rotate(0deg);
    }
    16% {
      transform: translate(1px, -1px) rotate(1deg);
    }
    17% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }
    18% {
      transform: translate(-3px, 1px) rotate(0deg);
    }
    19% {
      transform: translate(2px, 1px) rotate(-1deg);
    }
    20% {
      transform: translate(-1px, -1px) rotate(1deg);
    }
    21% {
      transform: translate(2px, 2px) rotate(0deg);
    }
    22% {
      transform: translate(1px, -1px) rotate(1deg);
    }
    23% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }
    24% {
      transform: translate(-3px, 1px) rotate(0deg);
    }
    25% {
      transform: translate(2px, 1px) rotate(-1deg);
    }
    26% {
      transform: translate(1px, -1px) rotate(1deg);
    }
    27% {
      transform: translate(0px, 0px) rotate(0deg);
    }
  }

  table td.sorter {
    cursor: ns-resize;
  }

  table.sorting-table tbody tr:not(.sorting-row) td {
    opacity: 0.2;
  }
</style>
