<script>
    export let color = 'green';
    export let percentage = 0;
    export let secondaryColor = 'truegray';
</script>

<div class="h-3 w-full relative rounded-full overflow-hidden">
  <div class="w-full h-full bg-{secondaryColor}-200 dark:bg-{secondaryColor}-800 absolute"></div>
  <div
    id="bar"
    class="h-full bg-{color}-500 relative rounded-full"
    class:shim="{percentage < 100}"
    style="width: {percentage}%; transition: width 1s;">
  </div>
</div>
