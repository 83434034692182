<script>
  import {BarLoader,} from 'svelte-loading-spinners';
  import {getRandomInt} from "../helpers/functions";

  let colors = ['#912cf0', '#e67d2d', '#e11d48', '#2fdf84', '#fd6000', '#52CEF2'];
  let i = getRandomInt(6);
  let color = colors[i];

  window.setInterval(function () {
    i = i % colors.length;
    color = colors[i];
    i++;
  }, 800);
</script>

<div class="flex flex-wrap absolute right-0">
  <div class="px-4 m-6">
    <BarLoader color="{color}" size="{63}" />
  </div>
</div>
