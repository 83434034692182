<script>
  import { onMount, onDestroy } from 'svelte';
  import { getData } from '../services/fetchData.js';
  import { submitData } from '../services/postData.js';
  import Datatable from '../components/datatable/DataTable.svelte';
  import columns from '../components/datatable/column-data/employee-pending.js';
  import Uploader from '../components/ChunkedUploader.svelte';
  import Select from '../components/select/Select.svelte';
  import Payslip from '../templates/payslip.svelte';
  import Chart from 'chart.js/auto';
  import { appTheme } from '../stores/index';
  import { getMonthName, hexToRgbaString } from '../helpers/functions.js';
  import GoalForm from '../components/forms/GoalForm.svelte';
  import {
    getOrdersTableStatusColumnIcons,
    getApprovalStatusColumnIcons
  } from '../helpers/functions.js';
  import Switch from '../components/Switch.svelte';
  import ProgressBar from '../components/ProgressBar.svelte';
  import { employeeDataStore } from './stores';

  let showPayslip = false;

  export let showSpinner;
  $: showSpinner = fetchDesignCountPerWeek && fetchOrdersData && fetchTopClientsLastWeek && goalsData && fetchEmployeeData && fetchPayslipClientRevenue;

  const monthsData = [
    { value: 0, label: 'This Month' },
    { value: 1, label: 'Last Month' },
    { value: 2, label: '2 months ago' },
    { value: 3, label: '3 months ago' },
    { value: 4, label: '4 months ago' },
    { value: 5, label: '5 months ago' }
  ];

  const weeksData = [
    { value: 1, label: 'Last Week' },
    { value: 2, label: '2 weeks ago' },
    { value: 3, label: '3 weeks ago' },
    { value: 4, label: '4 weeks ago' },
    { value: 5, label: '5 weeks ago' },
    { value: 6, label: '6 weeks ago' },
    { value: 7, label: '7 weeks ago' },
    { value: 8, label: '8 weeks ago' },
    { value: 9, label: '9 weeks ago' },
    { value: 10, label: '10 weeks ago' },
    { value: 11, label: '11 weeks ago' },
    { value: 12, label: '12 weeks ago' }
  ];

  let weekAgo, monthAgoForGoals;


  $: goalColor = employeeData?.accent_color ?? 'green';

  const now = new Date();

  if (now.getDate() == 1) monthsData.shift();

  let callMinuteGoals;

  if (now.getDate() < 5) monthAgoForGoals = 1;
  else monthAgoForGoals = 0;

  $: getGoals(monthAgoForGoals);
  $: if (monthAgoForGoals != 0) goalColor = 'truegray';
  else goalColor = 'green';

  let isMounted = false;

  $: accentColor = employeeData?.accent_color ?? 'yellow';

  let fetchTopClientsLastWeek,
    fetchDesignCountPerWeek,
    fetchOrdersData,
    fetchGoalsData,
    fetchSuggestedGoalsData,
    fetchTotalRevenue,
    fetchCallsAggregate,
    fetchPayslipClientRevenue,
    fetchEmployeeData, fetchNewClientsIdData;
  let topClients;
  let workingDays;
  let callsAggregate;
  let callsPerDay;
  let designCountPerWeek;
  let ordersData;
  let goalsData;
  let payslipClientsRevenue;
  let payslipGoalsIncentive;
  let basicPay;
  let usdRate;
  let uploadedFileNames = [];
  let designCountDiff = 0;
  let clientCountDiff = 0;
  let designCountGoal = 0;
  let isDesignCountGoalComplete = false;
  let isClientCountGoalComplete = false;
  let clientCountGoal = 0;
  let designCountPercentage = 0;
  let clientCountPercentage = 0;
  let goalDate = new Date();
  let invoiceThreshold = 0;
  let totalClientsRevenueOfEmployee = 0;
  let suggestedGoalsData;
  let showGoalsForm = false;
  let suggestedDesignCount = 0,
    suggestedClientCount = 0,
    designCount,
    clientCount,
    reward,
    goalsFormErrorMsg;
  let goalFormTitle;
  let isFormInvalid;

  let callsChart, designCountChart, topClientsChart;

  let employeeData, newClientsId = [];
  let payslipEmployeeData;
  $: callMinutes =
    payslipEmployeeData?.incentives?.callMinutes ??
    payslipEmployeeData?.callMinutes ??
    0;

  $: if (uploadedFileNames.length > 0) {
    fetchCallsHistoryData();
    uploadedFileNames = [];
  }

  $: numOfDaysCalled = callsAggregate?.chartData?.label?.length ?? 1;

  const fetchCallsHistoryData = async () => {
    fetchCallsAggregate = getData(
      'https://test.dev/statistics/callsHistoryAggregate'
    );

    [fetchCallsAggregate] = await Promise.all([fetchCallsAggregate]);

    callsChart?.destroy();

    callsAggregate = await fetchCallsAggregate.json();
    callsPerDay = callsAggregate.chartData;
  };

  const fetchPendingEmployeeOrders = async () => {
    fetchOrdersData = getData('https://test.dev/order/employeePendingRead');

    [fetchOrdersData] = await Promise.all([fetchOrdersData]);

    ordersData = await fetchOrdersData.json();
  };

  const setGoalsData = () => {
    designCountDiff = goalsData?.designcountDiff ?? designCountDiff;
    clientCountDiff = goalsData?.clientcountDiff ?? clientCountDiff;
    designCountGoal = goalsData?.designcountGoal ?? designCountGoal;
    clientCountGoal = goalsData?.clientcountGoal ?? clientCountGoal;
    isClientCountGoalComplete =
      goalsData?.isclientcountGoalComplete ?? isClientCountGoalComplete;
    isDesignCountGoalComplete =
      goalsData?.isdesigncountGoalComplete ?? isDesignCountGoalComplete;
    designCountPercentage =
      goalsData?.designcountPercentage ?? designCountPercentage;
    designCountPercentage =
      designCountPercentage > 100 ? 100 : designCountPercentage;
    clientCountPercentage =
      goalsData?.clientcountPercentage ?? clientCountPercentage;
    clientCountPercentage =
      clientCountPercentage > 100 ? 100 : clientCountPercentage;
    goalDate = new Date(goalsData.date);
    invoiceThreshold = goalsData?.invoicethreshold ?? invoiceThreshold;
  };

  const fetchTopClients = async () => {
    fetchTopClientsLastWeek = await getData(
      `https://test.dev/statistics/topClientsOfEmployee/${weekAgo}`
    );
    topClientsChart?.destroy();
    topClients = await fetchTopClientsLastWeek.json();
  };

  $: if (weekAgo) {
    fetchTopClients();
  }

  const fetchData = async () => {
    fetchDesignCountPerWeek = getData(
      'https://test.dev/statistics/designCountOfEmployee'
    );
    fetchTotalRevenue = getData(
      'https://test.dev/statistics/revenueAmountOfEmployee'
    );
    let fetchWorkingDays = getData(`https://test.dev/statistics/workingDays/1`);
    if (!$employeeDataStore)
      fetchEmployeeData = getData('https://test.dev/user/read');

    [
      fetchDesignCountPerWeek,
      fetchTotalRevenue,
      fetchPayslipClientRevenue,
      fetchWorkingDays,
      fetchEmployeeData
    ] = await Promise.all([
      fetchDesignCountPerWeek,
      fetchTotalRevenue,
      fetchPayslipClientRevenue,
      fetchWorkingDays,
      fetchEmployeeData || null
    ]);

    designCountPerWeek = await fetchDesignCountPerWeek.json();
    employeeData = fetchEmployeeData ? await fetchEmployeeData.json() : $employeeDataStore;
    totalClientsRevenueOfEmployee = await fetchTotalRevenue.json();

    workingDays = await fetchWorkingDays.json();
  };

  const fetchPayslipData = async () => {
    fetchPayslipClientRevenue = getData('https://test.dev/payroll/read');
    fetchNewClientsIdData = getData('https://test.dev/statistics/newClientByMonth');

    [fetchPayslipClientRevenue, fetchNewClientsIdData] = await Promise.all([
      fetchPayslipClientRevenue,
      fetchNewClientsIdData
    ]);

    if (fetchPayslipClientRevenue.status === 404)
      showPayslip = false;

    payslipEmployeeData = await fetchPayslipClientRevenue.json();
    newClientsId = await fetchNewClientsIdData.json();

    if (payslipEmployeeData) {
      payslipClientsRevenue = payslipEmployeeData.invoiceRevenue;
      payslipGoalsIncentive =
        payslipEmployeeData?.incentives ?? payslipEmployeeData?.totalIncentives;
      basicPay = payslipEmployeeData.basicPay;
      usdRate = payslipEmployeeData.usdRate;
    }
  };

  $: if (showPayslip) fetchPayslipData();

  const getGoals = async (monthAgo) => {
    if (typeof monthAgo == 'undefined') return;
    fetchGoalsData = await getData(`https://test.dev/goal/read/0/${monthAgo}`);

    if (!fetchGoalsData.ok && fetchGoalsData.status === 404) {
      if (monthAgoForGoals !== 0) {
        goalsFormErrorMsg = 'No goals found. Please select a different month';
        return;
      }
      showGoalsForm = true;
      getGoalSuggestions();
    } else {
      showGoalsForm = false;
      goalsData = await fetchGoalsData.json();
      if (goalsData) setGoalsData();
    }
  };

  const getGoalSuggestions = async () => {
    fetchSuggestedGoalsData = await getData(
      `https://test.dev/goal/createOrUpdate`
    );
    suggestedGoalsData = await fetchSuggestedGoalsData.json();
    if (fetchSuggestedGoalsData.status == 406) {
      goalsFormErrorMsg =
        'Goals can only be set up until the 10th of each month.';
    } else if (suggestedGoalsData) {
      if (suggestedGoalsData['action'] == 'Create') {
        suggestedDesignCount = suggestedGoalsData.designcountGoal;
        suggestedClientCount = suggestedGoalsData.clientcountGoal;
      } else {
        designCount = suggestedGoalsData.designcount;
        clientCount = suggestedGoalsData.clientcount;
      }
      goalFormTitle = `${suggestedGoalsData.action} Goals`;
      reward = Number(suggestedGoalsData?.reward);
      goalDate = new Date(suggestedGoalsData.date);
    }
  };

  const submitGoal = async () => {
    if (isFormInvalid) return;
    const postUrl =
      suggestedGoalsData['action'] == 'Create'
        ? 'https://test.dev/goal/create'
        : 'https://test.dev/goal/update';
    const response = await submitData(postUrl, {
      designcount: designCount,
      clientcount: clientCount,
      reward: reward,
      callMinutes: callMinuteGoals,
      id: suggestedGoalsData?.id
    });

    if (response.ok) getGoalSuggestion();
  };

  const handleRowClick = (e) => {
    let elementName = e.detail.target.name;
    if (!elementName) {
      elementName = e.detail.target.getAttribute('name');
    }
    const status = e.detail.target.innerHTML;
    switch (elementName) {
      case 'statusChangeBtn':
        if (
          ['Approval Pending', 'Revision Approval', 'Approved'].includes(status)
        ) {
          let postURL = 'https://test.dev/order/toggleApproval';
          (async () => {
            const response = await submitData(postURL, {
              id: e.detail.row.designid,
              currentStatus: status
            });
            if (response.ok) {
              fetchPendingEmployeeOrders();
            }
          })();
        }
        break;

      case 'orderNotesBtn':
        if (
          String(
            e.detail.target.parentNode.getAttribute('disabled')
          ).toLowerCase() == 'true'
        )
          return false;

        const notes = prompt(
          'Please add/edit notes to send with order email to client',
          e.detail.row.notes
        );
        if (notes) {
          let postURL = 'https://test.dev/order/handleOrderNotes';
          (async () => {
            const response = await submitData(postURL, {
              id: e.detail.row.designid,
              notes: notes
            });
            if (response.ok) {
              fetchPendingEmployeeOrders();
            }
          })();
        }
        break;

      default:
        break;
    }
  };

  const cacheData = async () => {
    if (employeeData)
      employeeDataStore.set(employeeData);
  };

  onMount(async () => {
    isMounted = true;
    fetchPendingEmployeeOrders();
    weekAgo = 1;
    fetchData();
    await fetchCallsHistoryData();
    cacheData();
  });

  onDestroy(() => {
    isMounted = false;
  });

  $: if (isMounted && topClients) {
    const getFirstName = (str) => str.split(" ").shift();

    topClientsChart?.destroy();

    topClientsChart = new Chart(document.getElementById('topClientsLastWeek'), {
      type: 'bar',
      data: {
        labels: topClients.label.map(getFirstName),
        datasets: [
          {
            label: 'Design Count',
            data: topClients.data,
            backgroundColor: hexToRgbaString(accentColor, 0.8),
            borderColor: `${accentColor}`,
            borderWidth: 2
          }
        ]
      },
      options: {
        scales: {
          yAxis: {
            ticks: {
              color: $appTheme === 'dark' ? 'Gainsboro' : 'grey'
            },
            gridLines: {
              color: 'rgba(136, 136, 136, 0.5)'
            }
          },
          xAxis: {
            ticks: {
              color: $appTheme == 'dark' ? 'Gainsboro' : 'grey'
            },
            gridLines: {
              color: 'rgba(136, 136, 136, 0.5)'
            }
          }
        },
        legend: {
          labels: {
            fontColor: $appTheme == 'dark' ? 'white' : 'grey'
          }
        }
      }
    });
  }

  $: if (isMounted && callsAggregate && Object.keys(callsAggregate).length) {
    const chartCtx = document
      .getElementById('callsHistoryAggregate')
      .getContext('2d');
    const skypeGradient = chartCtx.createLinearGradient(0, 0, 0, 600);
    skypeGradient.addColorStop(0, 'deepskyblue');
    skypeGradient.addColorStop(1, 'skyblue');

    const borderGradient = chartCtx.createLinearGradient(0, 0, 0, 600);
    borderGradient.addColorStop(0, 'skyblue');
    borderGradient.addColorStop(1, 'deepskyblue');

    const invalidCallsGradient = chartCtx.createLinearGradient(0, 0, 0, 600);
    invalidCallsGradient.addColorStop(0, 'azure');
    invalidCallsGradient.addColorStop(1, 'crimson');

    const invalidBorderGradient = chartCtx.createLinearGradient(0, 0, 0, 600);
    invalidBorderGradient.addColorStop(0, 'crimson');
    invalidBorderGradient.addColorStop(1, 'azure');

    callsChart?.destroy();

    callsChart = new Chart(document.getElementById('callsHistoryAggregate'), {
      type: 'bar',
      data: {
        labels: callsPerDay?.label,
        datasets: [
          {
            label: 'Number of invalid calls per day',
            data: callsPerDay?.invalidData,
            backgroundColor: invalidCallsGradient,
            borderColor: invalidBorderGradient,
            borderWidth: 1
          },
          {
            label: 'Number of valid calls per day',
            data: callsPerDay?.validData,
            backgroundColor: skypeGradient,
            borderColor: borderGradient,
            borderWidth: 1
          }
        ]
      },
      options: {
        scales: {
          yAxis: {
            ticks: {
              color: $appTheme == 'dark' ? 'Gainsboro' : 'grey'
            },
            gridLines: {
              color: 'rgba(136, 136, 136, 0.5)'
            },
            stacked: true
          },
          xAxis: {
            ticks: {
              color: $appTheme == 'dark' ? 'Gainsboro' : 'grey'
            },
            gridLines: {
              color: 'rgba(136, 136, 136, 0.5)'
            },
            stacked: true
          }
        },
        legend: {
          labels: {
            fontColor: $appTheme == 'dark' ? 'white' : 'grey'
          }
        }
      }
    });
  }

  $: if (isMounted && designCountPerWeek) {
    designCountChart?.destroy();

    designCountChart = new Chart(document.getElementById('designCount'), {
      type: 'bar',
      data: {
        labels: designCountPerWeek.label,
        datasets: [
          {
            label: 'Design Count',
            data: designCountPerWeek.data,
            backgroundColor: hexToRgbaString(accentColor, 0.8),
            borderColor: `${accentColor}`,
            borderWidth: 2
          }
        ]
      },
      options: {
        scales: {
          yAxis: {
            ticks: {
              color: $appTheme == 'dark' ? 'Gainsboro' : 'grey'
            },
            gridLines: {
              color: 'rgba(136, 136, 136, 0.5)'
            }
          },
          xAxis: {
            ticks: {
              color: $appTheme == 'dark' ? 'Gainsboro' : 'grey'
            },
            gridLines: {
              color: 'rgba(136, 136, 136, 0.5)'
            }
          }
        },
        legend: {
          labels: {
            fontColor: $appTheme == 'dark' ? 'white' : 'grey'
          }
        }
      }
    });
  }

  const markFileAsFinal = () => {
    submitData('https://test.dev/goal/markFileAsFinal', {
      filePath: callsAggregate?.filePath ?? ''
    });
  };
</script>

<svelte:head>
  <title>Dashboard</title>
</svelte:head>


<div class='w-full flex flex-col'>
  <div class='w-full dark:border-truegray-700 flex flex-col'>
    <main class='w-full flex-grow p-6 container mx-auto px-4 sm:px-8'>
      <h2 class='text-2xl dark:text-white font-semibold leading-tight'>
        Dashboard
      </h2>
      <div class='flex'>
        <p class='mx-auto text-base dark:text-truegray-50'>
          Total revenue from all clients this month: <span
          class='text-green-500 text-lg font-medium'
        >${totalClientsRevenueOfEmployee}</span>
        </p>
      </div>
      <div class='flex flex-wrap mt-6'>
        <div
          class='w-5/12 mx-auto bg-white dark:bg-truegray-900 rounded-md shadow dark:shadow-blue'>
          <div
            class='px-5 py-1 border-b-2 border-gray-200 dark:border-truegray-700 bg-gray-100 dark:bg-truegray-800 font-medium rounded-md leading-tight text-gray-500 uppercase flex justify-between'>
            <h5 class='uppercase mt-3 text-gray-600'>Top Clients of</h5>
            <div>
              <Select
                items='{weeksData}'
                id = 'weeksData'
                bind:selectedItemId='{weekAgo}' />
            </div>
          </div>
          <div class='p-6 bg-white dark:bg-truegray-900'>
            <canvas id='topClientsLastWeek'></canvas>
          </div>
        </div>
        <div
          class='w-5/12 mx-auto bg-white dark:bg-truegray-900 rounded-md shadow dark:shadow-blue'>
          <div
            class='px-5 py-3 border-b-2 border-gray-200 dark:border-truegray-700 bg-gray-100 dark:bg-truegray-800 font-medium rounded-md leading-tight text-gray-500 uppercase flex justify-between'>
            <h5 class='uppercase text-gray-600'>Design Count Each Week</h5>
          </div>
          <div class='p-6 bg-white dark:bg-truegray-900'>
            <canvas id='designCount'></canvas>
          </div>
        </div>
      </div>

      <div class='flex ml-auto flex-row w-1/4 mt-8 mr-12'>
        {#if monthAgoForGoals != 0}
          <span class='text-{goalColor}-500'
          >{getMonthName(goalDate.getMonth()) +
          ' ' +
          goalDate.getFullYear()}</span>
        {/if}
        <Select
          items='{monthsData}'
          bind:selectedItemId='{monthAgoForGoals}'
          placeholder='This month' />
      </div>
      {#if !showGoalsForm}
        <div class='flex space-x-4 ... -mt-4'>
          <div class='flex-1'>
            <h3
              class='text-xl dark:text-truegray-50 font-semibold leading-tight'>
              Designs Goal
            </h3>
            {#if designCountPercentage < 100}
              <p class='text-base dark:text-truegray-50'>
                You received <span
                class='text-{goalColor}-500 text-lg font-medium'
              >{designCountDiff}/{designCountGoal}</span>
                designs for your goal {@html isDesignCountGoalComplete
                ? ' - ' +
                `<span class='text-${goalColor}-500 text-lg'>Complete</span>`
                : ''}
              </p>
            {:else}
              <p class='text-base dark:text-truegray-50'>
                You've <span class='text-{goalColor}-500 text-lg font-medium'
              >completed</span> your goal. Congratulations! 🎉
              </p>
            {/if}
            <ProgressBar
              color='{goalColor}'
              percentage='{designCountPercentage}'
              size='1/8' />
          </div>
          <div class='flex-1'>
            <h3
              class='text-xl dark:text-truegray-50 font-semibold leading-tight'>
              Clients Goal
            </h3>
            {#if clientCountPercentage < 100}
              <p class='text-base dark:text-truegray-50'>
                You have <span class='text-{goalColor}-500 text-lg font-medium'
              >{clientCountDiff}/{clientCountGoal}</span>
                new clients each with
                <span class='text-{goalColor}-500 text-lg font-medium'
                >${invoiceThreshold}</span>
                or more to complete your goal {@html isClientCountGoalComplete
                ? ' - ' +
                `<span class='text-${goalColor}-500 text-lg'>Complete</span>`
                : ''}
              </p>
            {:else}
              <p class='text-base dark:text-truegray-50'>
                You <span class='text-{goalColor}-500 text-lg font-medium'
              >completed</span> your goal. Congratulations! 🎉
              </p>
            {/if}
            <ProgressBar
              color='{goalColor}'
              percentage='{clientCountPercentage}' />
          </div>
        </div>
      {:else}
        <GoalForm
          bind:showForm='{showGoalsForm}'
          bind:suggestedDesignCount
          bind:suggestedClientCount
          bind:designCount
          bind:clientCount
          bind:reward
          title='{goalFormTitle}'
          bind:isFormInvalid
          bind:callGoal='{callMinuteGoals}'
          error='{goalsFormErrorMsg}'
          submit='{submitGoal}' />
      {/if}
    </main>
    <div class='w-full'>
      <Datatable
        title='Client Pending Designs'
        rows='{ordersData}'
        columns='{columns}'
        process='local'
        exportable='{false}'
        printable='{false}'
        searchable='{false}'
        on:row-click='{handleRowClick}'
        columnToCustomize='status'
        customizedTargetColor='{{
          ...getOrdersTableStatusColumnIcons(),
          ...getApprovalStatusColumnIcons(),
        }}' />
    </div>
    <div class='border-t dark:border-truegray-700 w-96 container'></div>
    {#if callsAggregate && Object.keys(callsAggregate).length && now.getDate() <= 10 && !(showPayslip && callMinutes)}
      <div class='container pt-4'>
        <div>
          <span class='text-xl pt-2 text-blue-500'>
            Are these call stats final for {getMonthName(now.getMonth() - 1)}
            {now.getFullYear()}?
          </span>
          <span class='cursor-pointer text-red-500 float-right'>
            <svg viewBox='0 0 20 20' class='h-7 w-7'>
              <path
                fill='currentColor'
                d='M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z'
              ></path>
            </svg>
          </span>
          <span
            class='cursor-pointer text-green-500 pl-4 px-2 float-right'
            on:click='{markFileAsFinal}'>
            <svg viewBox='0 0 20 20' class='h-7 w-7'>
              <path
                fill='currentColor'
                d='M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z'
              ></path>
            </svg>
          </span>
          <span></span>
        </div>
      </div>
    {/if}
    <div class='flow-root px-96 md:px-64 pt-6'>
      <div class='float-left'>
        <h2 class='text-2xl dark:text-white font-semibold leading-tight mt-2'>
          Calls History Data
        </h2>
      </div>
      <div class='float-right'>
        <Uploader
          showSlimView='{true}'
          fileUploadUrl='https://test.dev/goal/uploadCsv'
          bind:uploadedFileNames />
      </div>
    </div>
    <div class='container w-full mx-auto'>
      <canvas id='callsHistoryAggregate'></canvas>
      {#if callsAggregate && Object.keys(callsAggregate).length}
        <div class='grid grid-cols-3 gap-4 place-items-center my-4'>
          <p class='text-base dark:text-truegray-50'>
            Avg. valid calls/day: <span class='text-blue-500 text-lg'
          >{(callsAggregate?.avgCallsPerDay ?? 0).toFixed()}</span>
          </p>
          <p class='text-base dark:text-truegray-50'>
            Successful calls percentage: <span class='text-blue-500 text-lg'
          >{(
            (callsAggregate?.validCallCount /
              (callsAggregate?.validCallCount +
                callsAggregate?.invalidCallCount)) *
            100
          ).toFixed(2)}%</span>
          </p>
          <p class='text-base dark:text-truegray-50'>
            Total minutes: <span class='text-blue-500 text-lg'
          >{callsAggregate?.totalMinutes ?? 0} mins</span>
          </p>
        </div>
      {/if}
      <div class='grid place-items-center py-2'>
        <div>
          <h2
            class='text-2xl dark:text-white font-semibold leading-tight float-left'>
            Show payslip
          </h2>
          <div class='inline px-4'>
            <Switch
              id='showPayslipSwitch'
              text=''
              bind:checked='{showPayslip}' />
          </div>
        </div>
      </div>
      {#if payslipClientsRevenue && showPayslip}
        <Payslip
          clientRevenue='{payslipClientsRevenue}'
          goalIncentive='{payslipGoalsIncentive}'
          basicPay='{basicPay}'
          newClientsId='{newClientsId}'
          workingDays='{workingDays}'
          usdRate='{usdRate}'
          leaves='{payslipEmployeeData?.leaves ?? 0}'
          numOfDaysCalled='{numOfDaysCalled}'
          salesData='{payslipClientsRevenue
            .filter((x) => x.isSelected)
            .map((x) => x.invoiceid)}'
          employeeData='{employeeData}'
          totalIncentives='{payslipEmployeeData?.totalIncentives}'
          isPayslipSaved='{payslipEmployeeData.saved}'
          callMinutes='{callMinutes}' />
      {/if}
    </div>
  </div>
</div>

<style>
    @media print {
        html * {
            visibility: hidden;
        }

        #printPayslip * {
            visibility: visible;
        }
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .shim {
        position: relative;
        overflow: hidden;
    }

    .shim::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
                90deg,
                rgba(233, 233, 233, 1) 0,
                rgba(233, 233, 233, 0.9) 50%,
                rgba(233, 233, 233, 0.8) 100%
        );
        animation: shimmer 2s ease-out infinite;
        content: '';
    }

    @keyframes shimmer {
        100% {
            transform: translateX(0%);
            opacity: 0;
        }
    }
</style>
