<script>
    import {onDestroy, onMount} from "svelte";
    import {getData} from "../../services/fetchData.js";
    import {fly} from "svelte/transition";
    import Spinner from "../Spinner.svelte";
    import Textbox from "./components/Textbox.svelte";
    import Switch from "../Switch.svelte";
    import Select from "../select/Select.svelte";
    import {companiesStore, emergencyPasswordHashStore, employeesStore, payMethodsStore,} from "./stores/index2";
    import {AES} from "../../helpers/aes";
    import {pickColor, reverse} from "../../helpers/functions";


    export let submit;
    export let cancel = () => (showForm = false);

    export let showForm = false;
    export let title = "Client Form";

    export let id;
    export let clientName = "";
    export let email = "";
    export let phone = "";
    export let address = "";
    export let orderNotes = "";
    export let selectedPaymentMethod;
    export let selectedCompany;
    export let selectedEmployee;
    export let PPTPrice;
    export let isTCRequired = true;
    export let isFlatRate = true;
    export let emergencyMode;

    let emergencyModePassInput;
    let showEmergencyPasswordInput = false;
    let emergencyModeBeforeChange;

    let emergencyModePassword;

    const decryptionSequence = [23, 45, 58, 96, 89];

  export let showSpinner;
  $: showSpinner = fetchCompanies && fetchEmployees && fetchPayMethods;

    let isMounted;
    let fetchEmployees,
        fetchCompanies,
        fetchPayMethods,
        fetchEmergencyPasswordHash,
        designCatPercent = {};

    $: employeesData = $employeesStore;
    $: companiesData = $companiesStore;
    $: payMethodData = $payMethodsStore;
    $: emergencyPasswordHash = $emergencyPasswordHashStore;

    $: if (
        typeof emergencyModeBeforeChange == "undefined" &&
        typeof emergencyMode != "undefined"
    ) {
        emergencyModeBeforeChange = emergencyMode;
    }
    // form validation
    export let isFormInvalid;

    let isClientNameDirty = false;
    let isEmailDirty = false;
    let isPhoneDirty = false;
    let isAddressDirty = false;
    let isPaymentMethodDirty = false;
    let isCompanyDirty = false;
    let isPPTStitchPayModeDirty = false;
    let isEmployeeDirty = false;

    $: isClientNameEmpty = clientName.length < 1 ? true : false;
    $: isEmailInvalid = !validateEmail(email);
    $: isPhoneEmpty = phone.length < 1 ? true : false;
    $: isAddressEmpty = address.length < 1 ? true : false;

    $: isPaymentMethodEmpty =
        isPaymentMethodDirty && selectedPaymentMethod == null ? true : false;

    $: isCompanyEmpty = isCompanyDirty && selectedCompany == null ? true : false;
    $: isPPTStitchPayModeEmpty =
        isPPTStitchPayModeDirty && PPTPrice == null ? true : false;

    $: isEmployeeEmpty =
        isEmployeeDirty && selectedEmployee == null ? true : false;

    $: if (emergencyMode != emergencyModeBeforeChange) {
        showEmergencyPasswordInput = true;
        emergencyModePassInput.focus();
    }

    $: isFormInvalid =
        isClientNameEmpty ||
        isPhoneEmpty ||
        isEmailInvalid ||
        isAddressEmpty ||
        isPaymentMethodEmpty ||
        isCompanyEmpty ||
        isPPTStitchPayModeEmpty ||
        isEmployeeEmpty;

    function validateEmail(value) {
        return Boolean(email);
        // return (
        //   (value &&
        //     !!value.match(
        //       /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        //     )) ||
        //   false
        // );
    }

    // end form validation

    const fetchDesignCategoryPercentage = async id => {
        const response = await getData(
            `https://test.dev/statistics/designCategoryPercentByClient/${id}`
        );
        designCatPercent = await response.json();
    }

    const fetchData = async () => {
        if (
            isMounted &&
            $employeesStore.length === 0 &&
            $companiesStore.length === 0 &&
            $payMethodsStore.length === 0
        ) {
            fetchEmployees = getData("https://test.dev/entity/read/employee");
            fetchCompanies = getData("https://test.dev/entity/read/company");
            fetchPayMethods = getData("https://test.dev/entity/read/paym");
            fetchEmergencyPasswordHash = getData("https://test.dev/user/read/0");

            [
                fetchEmployees,
                fetchCompanies,
                fetchPayMethods,
                fetchEmergencyPasswordHash,
            ] = await Promise.all([
                fetchEmployees,
                fetchCompanies,
                fetchPayMethods,
                fetchEmergencyPasswordHash,
            ]);
            $employeesStore = await fetchEmployees.json();
            $companiesStore = await fetchCompanies.json();
            $payMethodsStore = await fetchPayMethods.json();
            const passwordHash = await fetchEmergencyPasswordHash.json();
            $emergencyPasswordHashStore = passwordHash.password;
        }
    };

    const handleKeydown = (e) => {
        if (e.key === "Escape") {
            cancel();
        } else if (
            e.key === "Enter" &&
            e.srcElement.id == "emergencyModePassInput"
        ) {
            toggleEmergencyMode();
            showEmergencyPasswordInput = false;
        }
    };

    const toggleEmergencyMode = async () => {
        let decrypted;
        if (emergencyModePassword) {
            decrypted = emergencyModePassword;
        } else {
            decrypted = await AES().decrypt(
                emergencyPasswordHash,
                null,
                decryptionSequence
            );
        }
        if (decrypted != emergencyModePassInput) {
            emergencyMode = emergencyModeBeforeChange;
        } else {
            emergencyModePassword = decrypted;
        }
        emergencyModePassInput = "";
    };

    $: if (id) fetchDesignCategoryPercentage(id);
    $: console.log(id);

    onMount(() => {
        isMounted = true;
        fetchData();
    });

    onDestroy(() => {
        isMounted = false;
    });
</script>

<svelte:window on:keydown="{handleKeydown}"/>


<form class="container mx-auto my-48 w-full max-w-lg">
    <div>
        <h2 class="text-2xl dark:text-white my-6 font-semibold leading-tight">
            {title}
        </h2>
    </div>
    <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full md:w-2/5 px-3 mb-6 md:mb-0">
            <Textbox bind:isTextboxDirty={isClientNameDirty} bind:text={clientName} isAutoFocus={true}
                     placeholder="Client Name" showError={isClientNameEmpty}/>

        </div>
        <div class="w-full md:w-3/5 px-3 mb-6 md:mb-0">
            <Textbox bind:isTextboxDirty={isEmailDirty} bind:text={email} placeholder="Email Address"
                     showError={isEmailInvalid}/>
        </div>
    </div>

    <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full md:w-3/5 px-3 mb-6 md:mb-0">
            <Textbox bind:isTextboxDirty={isPhoneDirty} bind:text={phone} placeholder="(XXX) XXX-XXXX"
                     showError={isPhoneEmpty}/>
        </div>
        <div class="w-full md:w-2/5 px-3 mb-6 md:mb-0">
            <Select
                    bind:selectedItemId="{selectedPaymentMethod}"
                    items="{payMethodData}"
                    on:focus="{() => (isPaymentMethodDirty = true)}"
                    placeholder="Select Payment Method"/>
            {#if isPaymentMethodDirty && isPaymentMethodEmpty}
                <p class="text-red-500 text-xs italic">Please fill out this field.</p>
            {/if}
        </div>
    </div>

    <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full px-3 mb-6 md:mb-0">
            <Textbox bind:isTextboxDirty={isAddressDirty} bind:text={address}
                     placeholder="Street Address, City, Zipcode, State"
                     showError={isAddressEmpty}/>
        </div>
    </div>

    <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <Select
                    bind:selectedItemId="{selectedEmployee}"
                    items="{employeesData}"
                    on:focus="{() => (isEmployeeDirty = true)}"
                    placeholder="Select Employee"/>
            {#if isEmployeeDirty && isEmployeeEmpty}
                <p class="text-red-500 text-xs italic">Please fill out this field.</p>
            {/if}
        </div>
        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <Select
                    bind:selectedItemId="{selectedCompany}"
                    items="{companiesData}"
                    on:focus="{() => (isCompanyDirty = true)}"
                    placeholder="Select Company"/>
            {#if isCompanyDirty && isCompanyEmpty}
                <p class="text-red-500 text-xs italic">Please fill out this field.</p>
            {/if}
        </div>
    </div>

    <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <Switch
                    bind:checked="{isTCRequired}"
                    id="tcCheckbox"
                    text="Apply charges"/>
        </div>
        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <Switch
                    bind:checked="{isFlatRate}"
                    id="pricingModeCheckbox"
                    offText="Per thousand stitches"
                    text="Flat-rate pricing"
                    useAlternateColors="{true}"/>
        </div>
    </div>

    <div class="flex flex-wrap -mx-3 mb-6">
        <div class="px-3 {!isFlatRate ? 'w-9/12' : 'w-full'} mb-6 md:mb-0">
            <input
                    bind:value="{orderNotes}"
                    class="appearance-none block w-full text-gray-700 dark:text-truegray-300 border border-black-900 shadow dark:shadow-blue rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-truegray-800 dark:bg-truegray-900 focus:shadow-lg border-none dark:focus:text-white focus:text-black"
                    placeholder="Add notes that you want to attach to every order"
                    type="text"/>
        </div>
        {#if !isFlatRate}
            <div class="w-3/12 mb-6 md:mb-0">
                <input
                        bind:value="{PPTPrice}"
                        class="appearance-none block w-full text-gray-700 dark:text-truegray-300 border border-black-900 shadow dark:shadow-blue rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-truegray-800 dark:bg-truegray-900 focus:shadow-lg border-none dark:focus:text-white focus:text-black"
                        type="number"
                        placeholder="Price/1000 stitches"
                        on:focus="{() => (isPPTStitchPayModeDirty = true)}"/>
                {#if !isFlatRate && isPPTStitchPayModeDirty && isPPTStitchPayModeEmpty}
                    <p class="text-red-500 text-xs italic">
                        Please fill out this field or switch to flat-rate pricing
                    </p>
                {/if}
            </div>
        {/if}
    </div>

    {#if title == "Edit Client"}
        <div class="flex justify-center items-center -mx-3 mb-6">
            {#if !showEmergencyPasswordInput}
                <Switch
                        bind:checked="{emergencyMode}"
                        text="Emergency Mode"
                        id="emergencyModeCheckbox"/>
            {:else}
                <input
                        in:fly="{{ y: 50, duration: 500 }}"
                        out:fly="{{ y: 50, duration: 5 }}"
                        bind:value="{emergencyModePassInput}"
                        id="emergencyModePassInput"
                        class="appearance-none block w-1/2 text-gray-700 dark:text-truegray-300 border border-black-900 shadow dark:shadow-blue rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-truegray-800 dark:bg-truegray-900 focus:shadow-lg border-none dark:focus:text-white focus:text-black"
                        type="password"
                        placeholder="Emergency Mode Password"/>
            {/if}
        </div>
    {/if}

    <div class="flex items-center justify-center -mx-3 mb-6 rounded-lg">
        {#each Object.keys(designCatPercent) as cat, i}
            <div class="px-2 py-1 h-auto text-center truncate overflow-hidden"
                 class:rounded-l-lg="{i == 0}"
                 class:rounded-r-lg="{i == Object.keys(designCatPercent).length - 1}"
                 style="width: {designCatPercent[cat]}%; direction: rtl; background-color:{pickColor(cat+reverse(cat))}">{cat}
                : {designCatPercent[cat]}%
            </div>
        {/each}
    </div>

    <div
            class="px-5 py-5 bg-white dark:bg-truegray-900 border-t dark:border-truegray-700 flex flex-col xs:flex-row items-center
    xs:justify-between ">
        <div class="mt-2 px-2 xs:mt-0 items-center">
            <button
                    class="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold
        py-2 px-4 rounded-l shadow dark:shadow-blue"
                    on:click="{submit}"
                    type="button">
                {title.split(" ")[0]}
            </button>
            <button
                    class="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold
        py-2 px-4 rounded-r shadow dark:shadow-blue"
                    on:click="{cancel}"
                    type="button">
                Cancel
            </button>
        </div>
    </div>
</form>
