<script>
  import {onMount} from 'svelte';

  export let text;
  export let label;
  export let isTextboxDirty;
  export let showError = false;
  export let isAutoFocus = false;
  export let placeholder;
  export let additionalClasses = '';
  export let isDisabled;
  export let type = 'text';

  let ref;

  $: classes = `appearance-none block w-full text-gray-700 dark:text-truegray-300 border border-black-900 shadow dark:shadow-blue rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-truegray-800 dark:bg-truegray-900 focus:shadow-lg border-none dark:focus:text-white focus:text-black ${additionalClasses}`;

  onMount(() => {
    if (isAutoFocus) ref.focus();
  });
</script>

{#if type == 'text'}
  <input
    bind:this="{ref}"
    bind:value="{text}"
    class="{classes}"
    type="text"
    disabled="{isDisabled}"
    placeholder="{placeholder}"
    on:focus="{() => (isTextboxDirty = true)}" />
{:else if type == 'password'}
  <input
    bind:this="{ref}"
    bind:value="{text}"
    class="{classes}"
    type="password"
    disabled="{isDisabled}"
    placeholder="{placeholder}"
    on:focus="{() => (isTextboxDirty = true)}" />
{:else if type == 'number'}
  <input
          bind:this="{ref}"
          bind:value="{text}"
          class="{classes}"
          type="number"
          disabled="{isDisabled}"
          placeholder="{placeholder}"
          on:focus="{() => (isTextboxDirty = true)}"/>
{/if}
{#if label}
  <p class="text-truegray-700 px-4 dark:text-truegray-500 text-xs italic">{label}</p>
{/if}
{#if isTextboxDirty && showError}
  <p class="text-red-500 text-xs italic">Please fill out this field.</p>
{/if}
