import { appTheme } from "../../../stores/index";
import {get } from "svelte/store";

export default [
    // Array of objects
    {
        label: "Serial", // Column name
        field: "serial", // Field name from row
        numeric: true, // Affects sorting
        html: false, // Escapes output if false.
    },
    {
        label: "Name", // Column name
        field: function(data) {
            if (data.revisionCount > 2 && data.statusDesc == "pending") {
                return (
                    `<p class="py-2 text-gray-900 dark:text-truegray-50">${data.name}<span class="text-xl font-thin top-1 right-0 z-2 text-orange-500 dark:text-orange-300">⦷</span></p>`
                );
            }
            if ((data.fwdsent != 1 || !data.bwd_threadid) && data.statusDesc == "pending") {
                return (
                    `<p class="py-2 text-gray-900 dark:text-truegray-50">${data.name}<span class="text-xs font-thin top-1 right-0 z-2"> ❗</span></p>`
                );
            }
            return `<p class="text-gray-900 dark:text-truegray-50">${data.name}</p>`;
        }, // Field name from row
        numeric: false, // Affects sorting
        html: true, // Escapes output if false.
    },
    {
        label: "Price",
        field: function(data) {
            return "$" + data.price;
        },
        numeric: false,
        html: true,
    },
    {
        label: "Client",
        field: "clientname",
        numeric: false,
        html: false,
    },
    {
        label: "Vendor",
        field: "vendorname",
        numeric: false,
        html: false,
    },
    {
        label: "Time",
        field: "date",
        numeric: false,
        html: false,
    },
    {
        label: "Status",
        field: function(data) {
            return (
                '<button name="statusChangeBtn" class="relative border-none">' +
                data.statusInfo +
                "</button>"
            );
        },
        numeric: false,
        html: true,
    },
    {
        label: "Status Description",
        field: "statusDesc",
        numeric: true,
        html: false,
    },
    {
        label: "Action",
        field: function(data) {
            return (
                "<div>" +
                '<Button class="mr-2 border-none"><img class="h-10 w-10" name="editOrderBtn" src="/img/icons/' + get(appTheme) + '/edit.svg"/></Button>' +
                '<Button class="mr-2 border-none"><img class="h-10 w-10" name="deleteOrderBtn" src="/img/icons/' + get(appTheme) + '/trash.svg"/></Button>' +
                "</div>"
            );
        },
        numeric: false,
        html: true,
    },
];