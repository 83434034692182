export function groupBy(array, group) {
    var groups = {};

    if (!Array.isArray(array)) {
        return;
    }
    array.forEach(function(item) {
        var list = groups[item[group]];

        if (list) {
            list.push(item);
        } else {
            groups[item[group]] = [item];
        }
    });
    return groups;
}

export function reverse(s) {
    return s.split("").reverse().join("");
}

function hexToDec(s) {
    var i, j, digits = [0],
        carry;
    for (i = 0; i < s.length; i += 1) {
        carry = parseInt(s.charAt(i), 16);
        for (j = 0; j < digits.length; j += 1) {
            digits[j] = digits[j] * 16 + carry;
            carry = digits[j] / 10 | 0;
            digits[j] %= 10;
        }
        while (carry > 0) {
            digits.push(carry % 10);
            carry = carry / 10 | 0;
        }
    }
    return digits.reverse().join('');
}

export function getDateFromHexId(hex) {
    const timeHex = hex.slice(0, -5);
    const ms = hexToDec(timeHex);
    return new Date(parseInt(ms));
}

export function formatSalesContributionLineChartData(rawData) {
    if (rawData === undefined) return;

    const result = { label: [], data: {} };

    result.label = rawData.label;
    result.data = rawData.data;

    return result;
}

export function SortAccentColorsByEmployees(employees, colors, colorType, opacity = 0.5) {
    if (!colors) return colors;

    const result = [];
    for (const employee of employees) {
        if (colors.hasOwnProperty(employee))
            result.push(hexToRgbaString(colors[employee][colorType], opacity));
        else
            result.push(hexToRgbaString("#000000", opacity));
    }
    return result;
}

export function hexToRgbaString(hex, opacity = 0.5) {
    const rgb = hexToRgb(hex);
    return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${opacity})`;
}

export function formatSalesContributionBarChartData(rawData) {
    if (rawData === undefined) return;

    let result = { label: [], data: [] };

    const employeeData = rawData["data"];
    const employees = Object.keys(employeeData).sort((a, b) => a.localeCompare(b));

    for (let employee of employees) {
        const designs = employeeData[employee];
        const designCount = designs[designs.length - 1];
        if (designCount > 0) {
            result["data"].push(designCount);
            result["label"].push(employee);
        }
    }

    return result;
}

// returns time in minutes
export function calculateReadingTime(text) {
    const wpm = 225;
    const words = text.trim().split(/\s+/).length;
    return Math.ceil(words / wpm);
}

export function getTimeDifferenceFromNow(date) {
    const now = new Date();
    const results = {};
    results['ms'] = (now - date);
    results['days'] = Math.floor(results['ms'] / 86400000); // days
    results['hours'] = Math.floor((results['ms'] % 86400000) / 3600000) + results['days'] * 24; // hours
    results['minutes'] = Math.round(((results['ms'] % 86400000) % 3600000) / 60000) + results['hours'] * 60; // minutes

    return results;
}

export function getOrdersTableStatusColumnIcons() {
    return {
        Pending: {
            light: 'yellow',
            dark: 'darkOrange',
            icon: `<path fill="none" d="M4.68,13.716v-0.169H4.554C4.592,13.605,4.639,13.658,4.68,13.716z M11.931,6.465
            c-0.307-0.087-0.623,0.106-0.706,0.432l-1.389,5.484c-0.901,0.084-1.609,0.833-1.609,1.757c0,0.979,0.793,1.773,1.773,1.773
            c0.979,0,1.773-0.794,1.773-1.773c0-0.624-0.324-1.171-0.812-1.486l1.377-5.439C12.422,6.887,12.239,6.552,11.931,6.465z
            M10.591,14.729H9.408v-1.182h1.183V14.729z M15.32,13.716c0.04-0.058,0.087-0.11,0.126-0.169H15.32V13.716z M10,3.497
            c-3.592,0-6.503,2.911-6.503,6.503H4.68c0-2.938,2.382-5.32,5.32-5.32s5.32,2.382,5.32,5.32h1.182
            C16.502,6.408,13.591,3.497,10,3.497z M10,0.542c-5.224,0-9.458,4.234-9.458,9.458c0,5.224,4.234,9.458,9.458,9.458
            c5.224,0,9.458-4.234,9.458-9.458C19.458,4.776,15.224,0.542,10,0.542z M15.32,16.335v0.167h-0.212
            c-1.407,1.107-3.179,1.773-5.108,1.773c-1.93,0-3.701-0.666-5.108-1.773H4.68v-0.167C2.874,14.816,1.724,12.543,1.724,10
            c0-4.571,3.706-8.276,8.276-8.276c4.57,0,8.275,3.706,8.275,8.276C18.275,12.543,17.126,14.816,15.32,16.335z"></path>`,
            name: 'statusChangeBtn',
        },
        Rush: {
            light: 'orange',
            dark: 'darkRed',
            icon: `<path fill="none" d="M4.68,13.716v-0.169H4.554C4.592,13.605,4.639,13.658,4.68,13.716z M11.931,6.465
            c-0.307-0.087-0.623,0.106-0.706,0.432l-1.389,5.484c-0.901,0.084-1.609,0.833-1.609,1.757c0,0.979,0.793,1.773,1.773,1.773
            c0.979,0,1.773-0.794,1.773-1.773c0-0.624-0.324-1.171-0.812-1.486l1.377-5.439C12.422,6.887,12.239,6.552,11.931,6.465z
            M10.591,14.729H9.408v-1.182h1.183V14.729z M15.32,13.716c0.04-0.058,0.087-0.11,0.126-0.169H15.32V13.716z M10,3.497
            c-3.592,0-6.503,2.911-6.503,6.503H4.68c0-2.938,2.382-5.32,5.32-5.32s5.32,2.382,5.32,5.32h1.182
            C16.502,6.408,13.591,3.497,10,3.497z M10,0.542c-5.224,0-9.458,4.234-9.458,9.458c0,5.224,4.234,9.458,9.458,9.458
            c5.224,0,9.458-4.234,9.458-9.458C19.458,4.776,15.224,0.542,10,0.542z M15.32,16.335v0.167h-0.212
            c-1.407,1.107-3.179,1.773-5.108,1.773c-1.93,0-3.701-0.666-5.108-1.773H4.68v-0.167C2.874,14.816,1.724,12.543,1.724,10
            c0-4.571,3.706-8.276,8.276-8.276c4.57,0,8.275,3.706,8.275,8.276C18.275,12.543,17.126,14.816,15.32,16.335z"></path>`,
            name: 'statusChangeBtn',
        },
        'Super Rush': {
            light: 'yellow',
            dark: 'purple',
            icon: `<svg xmlns="http://www.w3.org/2000/svg" height="19" width="19" class="mr-1">
              <g fill="none" fill-rule="evenodd">
                <g fill="#ffffff" stroke="#ff0000" stroke-width=".5">
                    <circle class="circle-3" cx="9.68225" cy="9.64925" fill-opacity=".75" r="7.5" />
                    <circle class="circle-4" cx="9.68225" cy="9.64925" r="5.1375" />
                </g>
              </g>
          </svg>`,
            name: 'statusChangeBtn',
            customSvgFormat: true,
        },
        Completed: {
            light: 'green',
            dark: 'teal',
            icon: `<path fill="none" d="M7.197,16.963H7.195c-0.204,0-0.399-0.083-0.544-0.227l-6.039-6.082c-0.3-0.302-0.297-0.788,0.003-1.087
              C0.919,9.266,1.404,9.269,1.702,9.57l5.495,5.536L18.221,4.083c0.301-0.301,0.787-0.301,1.087,0c0.301,0.3,0.301,0.787,0,1.087
              L7.741,16.738C7.596,16.882,7.401,16.963,7.197,16.963z"></path>`,
            name: 'statusChangeBtn',
        },
        Overdue: {
            light: 'red',
            dark: 'rose',
            icon: `<path d="M15.684,16.959L10.879,8.52c0.886-0.343,1.517-1.193,1.517-2.186c0-1.296-1.076-2.323-2.396-2.323S7.604,5.037,7.604,6.333c0,0.993,0.63,1.843,1.517,
          2.186l-4.818,8.439c-0.189,0.311,0.038,0.708,0.412,0.708h10.558C15.645,17.667,15.871,17.27,15.684,16.959 M8.562,6.333c0-0.778,0.645-1.382,1.438-1.382s1.438,0.604,
          1.438,1.382c0,0.779-0.645,1.412-1.438,1.412S8.562,7.113,8.562,6.333 M5.55,16.726L10,8.91l4.435,7.815H5.55z M15.285,9.62c1.26-2.046,1.26-4.525,0-6.572c-0.138-0.223-0.064-0.512,
          0.162-0.646c0.227-0.134,0.521-0.063,0.658,0.16c1.443,2.346,1.443,5.2,0,7.546c-0.236,0.382-0.641,0.17-0.658,0.159C15.221,10.131,15.147,9.842,15.285,9.62 M13.395,8.008c0.475-1.063,
          0.475-2.286,0-3.349c-0.106-0.238,0.004-0.515,0.246-0.62c0.242-0.104,0.525,0.004,0.632,0.242c0.583,1.305,0.583,2.801,0,4.106c-0.214,0.479-0.747,0.192-0.632,0.242C13.398,8.523,13.288,
          8.247,13.395,8.008 M3.895,10.107c-1.444-2.346-1.444-5.2,0-7.546c0.137-0.223,0.431-0.294,0.658-0.16c0.226,0.135,0.299,0.424,0.162,0.646c-1.26,2.047-1.26,4.525,0,6.572c0.137,0.223,0.064,
          0.512-0.162,0.646C4.535,10.277,4.131,10.489,3.895,10.107 M5.728,8.387c-0.583-1.305-0.583-2.801,0-4.106c0.106-0.238,0.39-0.346,0.631-0.242c0.242,0.105,0.353,0.382,0.247,0.62c-0.475,1.063-0.475,
          2.286,0,3.349c0.106,0.238-0.004,0.515-0.247,0.62c-0.062,0.027-0.128,0.04-0.192,0.04C5.982,8.668,5.807,8.563,5.728,8.387"></path>`,
            name: 'statusChangeBtn',
        },
        Revision: {
            light: 'teal',
            dark: 'indigo',
            icon: `<path fill="none" d="M19.305,9.61c-0.235-0.235-0.615-0.235-0.85,0l-1.339,1.339c0.045-0.311,0.073-0.626,0.073-0.949
                c0-3.812-3.09-6.901-6.901-6.901c-2.213,0-4.177,1.045-5.44,2.664l0.897,0.719c1.053-1.356,2.693-2.232,4.543-2.232
                c3.176,0,5.751,2.574,5.751,5.751c0,0.342-0.037,0.675-0.095,1l-1.746-1.39c-0.234-0.235-0.614-0.235-0.849,0
                c-0.235,0.235-0.235,0.615,0,0.85l2.823,2.25c0.122,0.121,0.282,0.177,0.441,0.172c0.159,0.005,0.32-0.051,0.44-0.172l2.25-2.25
                C19.539,10.225,19.539,9.845,19.305,9.61z M10.288,15.752c-3.177,0-5.751-2.575-5.751-5.752c0-0.276,0.025-0.547,0.062-0.813
                l1.203,1.203c0.235,0.234,0.615,0.234,0.85,0c0.234-0.235,0.234-0.615,0-0.85l-2.25-2.25C4.281,7.169,4.121,7.114,3.961,7.118
                C3.802,7.114,3.642,7.169,3.52,7.291l-2.824,2.25c-0.234,0.235-0.234,0.615,0,0.85c0.235,0.234,0.615,0.234,0.85,0l1.957-1.559
                C3.435,9.212,3.386,9.6,3.386,10c0,3.812,3.09,6.901,6.902,6.901c2.083,0,3.946-0.927,5.212-2.387l-0.898-0.719
                C13.547,14.992,12.008,15.752,10.288,15.752z"></path>`,
            name: 'statusChangeBtn',
        },
        Send: {
            customColorCombo: 'bg-gradient-to-r from-green-500 to-green-700 hover:bg-gradient-to-r hover:from-truegray-700 hover:via-truegray-900 hover:to-black text-truegray-50',
            icon: `<path d="M17.218,2.268L2.477,8.388C2.13,8.535,2.164,9.05,2.542,9.134L9.33,10.67l1.535,6.787c0.083,0.377,0.602,0.415,0.745,0.065l6.123-14.74C17.866,2.46,17.539,2.134,17.218,2.268 M3.92,8.641l11.772-4.89L9.535,9.909L3.92,8.641z M11.358,16.078l-1.268-5.613l6.157-6.157L11.358,16.078z"></path>`,
            name: 'statusChangeBtn',
        },
    };
}

export function getApprovalStatusColumnIcons() {
    return {
        'Approval Pending': {
            customColorCombo: 'bg-gradient-to-r from-orange-600 to-orange-500 text-orange-200 hover:from-amber-900 hover:to-amber-800 hover:text-truegray-50',
            icon: `<path fill="none" d="M4.68,13.716v-0.169H4.554C4.592,13.605,4.639,13.658,4.68,13.716z M11.931,6.465
            c-0.307-0.087-0.623,0.106-0.706,0.432l-1.389,5.484c-0.901,0.084-1.609,0.833-1.609,1.757c0,0.979,0.793,1.773,1.773,1.773
            c0.979,0,1.773-0.794,1.773-1.773c0-0.624-0.324-1.171-0.812-1.486l1.377-5.439C12.422,6.887,12.239,6.552,11.931,6.465z
            M10.591,14.729H9.408v-1.182h1.183V14.729z M15.32,13.716c0.04-0.058,0.087-0.11,0.126-0.169H15.32V13.716z M10,3.497
            c-3.592,0-6.503,2.911-6.503,6.503H4.68c0-2.938,2.382-5.32,5.32-5.32s5.32,2.382,5.32,5.32h1.182
            C16.502,6.408,13.591,3.497,10,3.497z M10,0.542c-5.224,0-9.458,4.234-9.458,9.458c0,5.224,4.234,9.458,9.458,9.458
            c5.224,0,9.458-4.234,9.458-9.458C19.458,4.776,15.224,0.542,10,0.542z M15.32,16.335v0.167h-0.212
            c-1.407,1.107-3.179,1.773-5.108,1.773c-1.93,0-3.701-0.666-5.108-1.773H4.68v-0.167C2.874,14.816,1.724,12.543,1.724,10
            c0-4.571,3.706-8.276,8.276-8.276c4.57,0,8.275,3.706,8.275,8.276C18.275,12.543,17.126,14.816,15.32,16.335z"></path>`,
            name: 'statusChangeBtn',
        },
        'Revision Approval': {
            customColorCombo: 'bg-gradient-to-r from-indigo-600 to-purple-700 hover:to-indigo-800 hover:from-purple-900 text-purple-200 hover:text-truegray-50',
            icon: `<path fill="none" d="M19.305,9.61c-0.235-0.235-0.615-0.235-0.85,0l-1.339,1.339c0.045-0.311,0.073-0.626,0.073-0.949
            c0-3.812-3.09-6.901-6.901-6.901c-2.213,0-4.177,1.045-5.44,2.664l0.897,0.719c1.053-1.356,2.693-2.232,4.543-2.232
            c3.176,0,5.751,2.574,5.751,5.751c0,0.342-0.037,0.675-0.095,1l-1.746-1.39c-0.234-0.235-0.614-0.235-0.849,0
            c-0.235,0.235-0.235,0.615,0,0.85l2.823,2.25c0.122,0.121,0.282,0.177,0.441,0.172c0.159,0.005,0.32-0.051,0.44-0.172l2.25-2.25
            C19.539,10.225,19.539,9.845,19.305,9.61z M10.288,15.752c-3.177,0-5.751-2.575-5.751-5.752c0-0.276,0.025-0.547,0.062-0.813
            l1.203,1.203c0.235,0.234,0.615,0.234,0.85,0c0.234-0.235,0.234-0.615,0-0.85l-2.25-2.25C4.281,7.169,4.121,7.114,3.961,7.118
            C3.802,7.114,3.642,7.169,3.52,7.291l-2.824,2.25c-0.234,0.235-0.234,0.615,0,0.85c0.235,0.234,0.615,0.234,0.85,0l1.957-1.559
            C3.435,9.212,3.386,9.6,3.386,10c0,3.812,3.09,6.901,6.902,6.901c2.083,0,3.946-0.927,5.212-2.387l-0.898-0.719
            C13.547,14.992,12.008,15.752,10.288,15.752z"></path>`,
            name: 'statusChangeBtn',
        },
        Approved: {
            light: 'blue',
            dark: 'blue',
            icon: `<path fill="none" d="M7.197,16.963H7.195c-0.204,0-0.399-0.083-0.544-0.227l-6.039-6.082c-0.3-0.302-0.297-0.788,0.003-1.087
              C0.919,9.266,1.404,9.269,1.702,9.57l5.495,5.536L18.221,4.083c0.301-0.301,0.787-0.301,1.087,0c0.301,0.3,0.301,0.787,0,1.087
              L7.741,16.738C7.596,16.882,7.401,16.963,7.197,16.963z"></path>`,
            name: 'statusChangeBtn',
        },
    };
}

export function getMonthName(monthNumber) {
    monthNumber = monthNumber < 0 ? 11 : monthNumber;
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    return months[monthNumber];
}

export function pkrFormatter() {
    // Create our number formatter.
    return new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'PKR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        currencyDisplay: 'narrowSymbol'
    });
}

export function capitalizeFirstLetter(str) {
    if (!str) return str;

    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getKeyWithMostValues(rawData) {
    if (rawData === undefined) return;

    const arr = rawData.map(a => a.salesrep);
    const obj = {};
    let mostFreq = 0,
        which = [];

    arr.forEach(ea => {
        if (!obj[ea]) {
            obj[ea] = 1;
        } else {
            obj[ea]++;
        }

        if (obj[ea] > mostFreq) {
            mostFreq = obj[ea];
            which = [ea];
        } else if (obj[ea] === mostFreq) {
            which.push(ea);
        }
    });

    return which;
}

export function getTextboxSupportedTypes() {
    const typeEnum = {password: 'password', text: 'text', number: 'number'};
    Object.freeze(typeEnum);
    return typeEnum;
}

/** Color functions start **/
export function getMonochromeColors(hex, results = 6) {
    let hsv = hexToHsv(hex);
    let h = hsv.h,
        s = hsv.s,
        v = hsv.v;
    const ret = [];
    const modification = 1 / results;

    while (results--) {
        ret.push(hsvToHex(...{h: h, s: s, v: v}));
        v = (v + modification) % 1;
    }

    return ret;
}

export function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

export function hashCode(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
}

export function pickColor(str) {
    return `hsl(${hashCode(str) % 240}, 100%, 80%)`;
}

export function hexToHsv(hex) {
    return rgbToHsv(...hexToRgb(hex));
}

export function hsvToHex(hex) {
    return rgbToHex(...hsvToRgb(hex));
}

export function hexToRgb(hex) {
    return hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
        .substring(1).match(/.{2}/g)
        .map(x => parseInt(x, 16));
}

export function rgbToHsv(r, g, b) {

    r = bound01(r, 255);
    g = bound01(g, 255);
    b = bound01(b, 255);

    var max = mathMax(r, g, b),
        min = mathMin(r, g, b);
    var h, s, v = max;

    var d = max - min;
    s = max === 0 ? 0 : d / max;

    if (max == min) {
        h = 0; // achromatic
    } else {
        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;
            case g:
                h = (b - r) / d + 2;
                break;
            case b:
                h = (r - g) / d + 4;
                break;
        }
        h /= 6;
    }
    return { h: h, s: s, v: v };
}

export function hsvToRgb(h, s, v) {

    h = bound01(h, 360) * 6;
    s = bound01(s, 100);
    v = bound01(v, 100);

    var i = Math.floor(h),
        f = h - i,
        p = v * (1 - s),
        q = v * (1 - f * s),
        t = v * (1 - (1 - f) * s),
        mod = i % 6,
        r = [v, q, p, p, t, v][mod],
        g = [t, v, v, q, p, p][mod],
        b = [p, p, t, v, v, q][mod];

    return { r: r * 255, g: g * 255, b: b * 255 };
}

export function rgbToHex(r, g, b, allow3Char = false) {

    var hex = [
        pad2(mathRound(r).toString(16)),
        pad2(mathRound(g).toString(16)),
        pad2(mathRound(b).toString(16))
    ];

    // Return a 3 character hex if possible
    if (allow3Char && hex[0].charAt(0) == hex[0].charAt(1) && hex[1].charAt(0) == hex[1].charAt(1) && hex[2].charAt(0) == hex[2].charAt(1)) {
        return hex[0].charAt(0) + hex[1].charAt(0) + hex[2].charAt(0);
    }

    return hex.join("");
}
/** Color functions end **/

export function range(a, b, c) {
    if (a > b)[a, b] = [b, a];
    a = b - a + 1;
    c = [];
    while (a--) c[a] = b--;
    return c
}

export function randomIntFromInterval(min, max) { // min and max included 
    min = parseInt(min);
    max = parseInt(max);
    return Math.floor(Math.random() * (max - min + 1) + min);
}

export function getOrdinalNum(number) {
    let selector;

    if (number <= 0) {
        selector = 4;
    } else if ((number > 3 && number < 21) || number % 10 > 3) {
        selector = 0;
    } else {
        selector = number % 10;
    }

    return number + ['th', 'st', 'nd', 'rd', ''][selector];
};

const fnGetFileNameFromContentDispostionHeader = function(header) {
    const contentDisposition = header.split(';')[1];

    const result = contentDisposition.split('"')[1];

    return result;
};

export function getTailwindColors() {
    return [
        "teal", "rose", "darkRed", "darkOrange", "red",
        "orange", "yellow", "green",
        "blue", "indigo", "purple", "pink"
    ]
}

export function generateAvatar(name) {
    if (!name) {
        return "";
    }
    name = name.replace(/\W+/g, " ");
    var initials = name.split(' ').map(function(str) { return str ? str[0].toUpperCase() : ""; }).slice(0, 2).join('');
    var canvas = document.createElement('canvas');
    var radius = 30;
    var margin = 5;
    canvas.width = radius * 2 + margin * 2;
    canvas.height = radius * 2 + margin * 2;

    // Get the drawing context
    var ctx = canvas.getContext('2d');
    ctx.beginPath();
    ctx.arc(radius + margin, radius + margin, radius, 0, 2 * Math.PI, false);
    ctx.closePath();
    ctx.fillStyle = 'grey';
    ctx.fill();
    ctx.fillStyle = "white";
    ctx.font = "bold 30px Arial";
    ctx.textAlign = 'center';
    ctx.fillText(initials, radius + 5, radius * 4 / 3 + margin);
    return canvas.toDataURL();
}

export const downloadPDF = async(postURL, body) => {
    if (typeof postURL === 'undefined') {
        console.log('no postURL given');
        return;
    }
    let headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');

    fetch(postURL, {
            method: 'POST',
            credentials: "include",
            headers: headers,
            body: JSON.stringify(body)
        })
        .then(async res => ({
            filename: fnGetFileNameFromContentDispostionHeader(res.headers.get('Content-Disposition')),
            blob: await res.arrayBuffer()
        }))
        .then(resObj => {
            // It is necessary to create a new blob object with mime-type explicitly set for all browsers except Chrome, but it works for Chrome too.
            const newBlob = new Blob([resObj.blob], { type: 'application/pdf' });

            // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
            } else {
                // For other browsers: create a link pointing to the ObjectURL containing the blob.
                const objUrl = window.URL.createObjectURL(newBlob);

                let link = document.createElement('a');
                link.href = objUrl;
                link.download = resObj.filename;
                link.click();

                // For Firefox it is necessary to delay revoking the ObjectURL.
                setTimeout(() => { window.URL.revokeObjectURL(objUrl); }, 250);
            }
        })
        .catch((error) => {
            console.log('DOWNLOAD ERROR', error);
        });
}

export const getFraction = (decimal) => {
    for (var denominator = 1;
        (decimal * denominator) % 1 !== 0; denominator++);
    return { numerator: decimal * denominator, denominator: denominator };
}

export function generateUuid() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}