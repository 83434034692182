import { appTheme } from "../../../stores/index";
import { getOrdinalNum, getMonthName } from "../../../helpers/functions";
import { get } from "svelte/store";

export default [
    // Array of objects
    {
        label: "id", // Column name
        field: "id", // Field name from row
        numeric: true, // Affects sorting
        html: false, // Escapes output if false.
    },
    {
        label: "Design In DOC", // Column name
        field: "designname", // Field name from row
        numeric: false, // Affects sorting
        html: false, // Escapes output if false.
    },
    {
        label: "Design In Email", // Column name
        field: "emailname", // Field name from row
        numeric: false, // Affects sorting
        html: false, // Escapes output if false.
    },
    {
        label: "Price",
        field: function (data) {
            return "$" + data.price;
        },
        numeric: false,
        html: true
    },
    {
        label: "Client", // Column name
        field: "clientname", // Field name from row
        numeric: false, // Affects sorting
        html: false, // Escapes output if false.
    },
    {
        label: "Date In DOC", // Column name
        field: function (data) {
            const date = new Date(data.designdate);
            return `${getOrdinalNum(date.getDate())} ${getMonthName(date.getMonth())} ${date.getFullYear()}`
        },
        numeric: false, // Affects sorting
        html: true, // Escapes output if false.
    },
    {
        label: "Date of Email", // Column name
        field: function (data) {
            const date = new Date(data.designdate);
            return `${getOrdinalNum(date.getDate())} ${getMonthName(date.getMonth())} ${date.getFullYear()}`
        },
        numeric: false, // Affects sorting
        html: true, // Escapes output if false.
    },
    {
        label: "Action",
        field: function (data) {
            return (
                "<div>" +
                '<Button class="mr-2 border-none"><img class="h-10 w-10" name="unverifyDesignBtn" src="/img/icons/' + get(appTheme) + '/crossed.svg"/></Button>' +
                "</div>"
            );
        },
        numeric: false,
        html: true,
    }
];
