import { appTheme } from "../../../stores/index";
import {get } from "svelte/store";

export default [
    // Array of objects
    {
        label: "clientid", // Column name
        field: "clientid", // Field name from row
        numeric: true, // Affects sorting
        html: false, // Escapes output if false.
    },
    {
        label: "Name", // Column name
        field: function(data) {
            return (
                data.invoiceDueCount > 1 ?
                `<span class="relative inline-block">
          ${data.clientname}
          <span class="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs leading-none text-red-100 bg-red-600 rounded-full">${data.invoiceDueCount}</span>
        </span>` :
                data.clientname
            );
        }, // Field name from row
        numeric: false, // Affects sorting
        html: true, // Escapes output if false.
    },
    {
        label: "Phone", // Column name
        numeric: false, // Affects sorting
        html: true, // Escapes output if false.
        field: function(data) {
            const numbers = data.phone.split('/');
            let content = '';
            numbers.map(n => content += `<span class="cursor-pointer hover:bg-blue-500 px-1" name="callClientNum">${n}</span>/`);

            return content.slice(0, -1);
        },
    },
    {
        label: "Email", // Column name
        field: "email", // Field name from row
        numeric: false, // Affects sorting
        html: false, // Escapes output if false.
    },
    {
        label: "Employee",
        field: "employeename",
        numeric: false,
        html: false,
    },
    {
        label: "Action",
        field: function(data) {
            return (
                "<div>" +
                '<Button class="mr-2 border-none"><img class="h-10 w-10" name="editClientBtn" src="/img/icons/' + get(appTheme) + '/edit.svg"/></Button>' +
                '<Button class="mr-2 border-none"><img class="h-10 w-10" name="deleteClientBtn" src="/img/icons/' + get(appTheme) + '/trash.svg"/></Button>' +
                "</div>"
            );
        },
        numeric: false,
        html: true,
    },
    // {
    //   label: "Invoice",
    //   field: function (data) {
    //     return (
    //       '<div>' +
    //       '<Button class="border-none"><img class="h-10 w-10" name="invoiceClientBtn" src="/img/icons/' + get(appTheme) + '/pdf.svg"/></Button>' +
    //       "</div>"
    //     );
    //   },
    //   numeric: false,
    //   html: true,
    // },
];