<script>
  import { onMount, onDestroy } from 'svelte';
  import Select from '../select/Select.svelte';
  import Uploader from '../ChunkedUploader.svelte';
  import Spinner from '../Spinner.svelte';
  import { getData } from '../../services/fetchData.js';
  import { submitData } from '../../services/postData';
  import { linear } from 'svelte/easing';
  import { fly, fade } from 'svelte/transition';
  import {
    clientsStore,
    vendorsStore,
    pricesStore,
    companiesStore,
    perStitchClientsStore,
  } from './stores/index2';
  import Switch from '../Switch.svelte';
  import Textbox from './components/Textbox.svelte';

  export let submit;
  export let cancel = () => (showForm = false);

  export let showForm = false;
  export let rush, superRush;
  export let title = 'Form';
  export let orderName;
  export let suggestedPrice;
  export let emailSubjectAddendum;
  export let emailBody;
  export let bwdEmailSubject;
  export let selectedCompany;
  export let isOrderFetchedFromServer;

  export let disableActionButton = false;

  export let uuid, bwdThreadId;
  export let attachedFileNames, uploadedFileNames;

  export let chipMessage;
  export let mildError,
    internalSuccess = false;

  let rfcMsgId;
  let isEmailBodyFirstLoad = true;
  let emailBodyContentWasAttentedTo = false;

  let forceAllDirty = false;

  let emailBodyInputRef;

  let price;
  let attachedFileNamesFromServer;

  let fetchClients,
    fetchVendors,
    fetchPrices,
    fetchCompanies,
    fetchPerStitchClients;
  let clientEmailData,
    dataFilledFromRFC = false;
  let isMounted;
  let isServerAttachmentDownloadComplete = false;

  let isPriceFieldDisabled = false;

  $: clientsData = $clientsStore;
  $: vendorsData = $vendorsStore;
  $: pricesData = $pricesStore;
  $: companiesData = $companiesStore;
  $: perStitchClientsData = $perStitchClientsStore;

  $: if (Object.keys(perStitchClientsData).includes(selectedClient)) {
    price = 0;
    suggestedPrice = 0;
    isPriceFieldDisabled = true;
  } else {
    isPriceFieldDisabled = false;
  }

  $: if (orderName) {
    if (orderName.toLowerCase().includes('fullback') && isNaN(price))
      suggestedPrice = ' Price';
    if (!selectedVendor) {
      if (
        ![' mockup', ' artwork', ' vector'].every(
          (x) => orderName.toLowerCase().indexOf(x) === -1
        )
      ) {
        selectedVendor = vendorsData.find((x) => x.dept == 'Vector')?.value;
      } else if (orderName.toLowerCase().includes(' logo')) {
        selectedVendor = vendorsData.find((x) => x.dept == 'Digitizing')?.value;
      }
    }
  }

  $: if (!isPriceEmpty) {
    suggestedPrice = price;
  } else if (
    !isClientEmpty &&
    pricesData &&
    selectedClient in pricesData &&
    !orderName.toLowerCase().includes('fullback') &&
    title !== 'Edit Order'
  ) {
    suggestedPrice = pricesData[selectedClient];
  } else if (title !== 'Edit Order') {
    suggestedPrice = ' Price';
  }

  if (title === 'Edit Order') {
    price = suggestedPrice;
  }

  // form validation
  export let isFormInvalid;

  let isOrderNameDirty = false;
  let isPriceDirty = false;
  $: isOrderNameEmpty = orderName.length < 1 ? true : false;
  $: isPriceEmpty = (!price || price.length < 1) && price != 0 ? true : false;

  let isClientSelectDirty = false;
  let isVendorSelectDirty = false;
  let showEmailBodyError = false;

  $: isClientEmpty = selectedClient == null ? true : false;

  $: isVendorEmpty = selectedVendor == null ? true : false;

  $: isFormInvalid =
    isOrderNameEmpty ||
    isNaN(suggestedPrice) ||
    isClientEmpty ||
    isVendorEmpty ||
    (!emailBodyContentWasAttentedTo && isOrderFetchedFromServer);

  $: if (isFormInvalid && forceAllDirty) {
    isOrderNameDirty = true;
    isPriceDirty = true;
    isVendorSelectDirty = true;
    isClientSelectDirty = true;
    showEmailBodyError = true;
  }

  // validation portion complete

  $: if (selectedCompany && rfcMsgId) {
    (async () => {
      if (dataFilledFromRFC) return false;
      let response = await submitData('https://test.dev/email/rfc', {
        id: selectedCompany,
        rfcId: encodeURIComponent(rfcMsgId),
      });
      if (response.ok) {
        clientEmailData = await response.json();
        if (Array.isArray(clientEmailData) && !clientEmailData.length) {
          chipMessage = 'RFC returned empty response';
          mildError = true;
          return false;
        }
        dataFilledFromRFC = true;
        chipMessage = 'RFC loaded';
        internalSuccess = true;
        emailBody = clientEmailData.body;
        bwdEmailSubject = clientEmailData.subject;
        if (clientEmailData['body'] && clientEmailData['designName']) {
          if (
            [
              'rush',
              'asap',
              'soon',
              'today',
              'fast',
              'quick',
              'urgent',
              'today',
              'tonight',
            ].some(
              (x) =>
                clientEmailData.body.toLowerCase().includes(x) ||
                clientEmailData.designName.join(' ').toLowerCase().includes(x)
            )
          ) {
            rush = true;
          }
        }
        if (clientEmailData['clientid'])
          selectedClient = clientEmailData.clientid;
        else selectedClient = null;

        if (clientEmailData['attachments'].length > 0)
          attachedFileNamesFromServer = [...clientEmailData['attachments']];
        if (clientEmailData['bwd_msgId']) uuid = clientEmailData['bwd_msgId'];
        if (clientEmailData['bwd_threadId'])
          bwdThreadId = clientEmailData['bwd_threadId'];

        const downloadAttachments = await getData(
          `https://test.dev/email/downloadEmailAttachments/${selectedCompany}/${clientEmailData.bwd_msgId}`
        );
        isServerAttachmentDownloadComplete = await downloadAttachments.json();
      }
    })();
  }

  $: if (isServerAttachmentDownloadComplete) {
    chipMessage = 'Preview ready';
    internalSuccess = true;
    isServerAttachmentDownloadComplete = false;
  }
  $: if (rfcMsgId) dataFilledFromRFC = false;

  const fetchData = async () => {
    if (
      isMounted &&
      ($clientsStore.length === 0 ||
        $vendorsStore.length === 0 ||
        $companiesStore.length === 0 ||
        $pricesStore.length === 0)
    ) {
      fetchClients = getData('https://test.dev/entity/read/client/true');
      fetchVendors = getData('https://test.dev/entity/read/vendor/true');
      fetchCompanies = getData('https://test.dev/entity/read/company');
      fetchPrices = getData('https://test.dev/client/price');
      fetchPerStitchClients = getData(
        'https://test.dev/client/variablePricing'
      );

      [
        fetchClients,
        fetchVendors,
        fetchPrices,
        fetchCompanies,
        fetchPerStitchClients,
      ] = await Promise.all([
        fetchClients,
        fetchVendors,
        fetchPrices,
        fetchCompanies,
        fetchPerStitchClients,
      ]);

      $clientsStore = await fetchClients.json();
      $vendorsStore = await fetchVendors.json();
      $pricesStore = await fetchPrices.json();
      $companiesStore = await fetchCompanies.json();
      $perStitchClientsStore = await fetchPerStitchClients.json();
    } else {
      fetchVendors = await getData('https://test.dev/entity/read/vendor/true');
      if (fetchVendors.ok) {
        $vendorsStore = await fetchVendors.json();
      }
    }
  };

  export let selectedClient;

  export let selectedVendor;

  const handleSend = async () => {
    forceAllDirty = true;
    await submit();
    if (!disableActionButton && !isFormInvalid && showForm) {
      dataFilledFromRFC = false;
    }
  };

  const handleKeydown = (e) => {
    if (e.key === 'Enter' && e.srcElement.tagName.toLowerCase() == 'input')
      e.preventDefault();
    if (e.key === 'Escape') cancel();
    if (e.key === 'Enter' && e.ctrlKey) handleSend();
  };

  onMount(() => {
    isMounted = true;
    emailBody = null;
    emailBodyInputRef.addEventListener('input', function () {
      if (!isEmailBodyFirstLoad) {
        emailBodyContentWasAttentedTo = true;
      }
      isEmailBodyFirstLoad = false;
    });

    fetchData();
  });

  onDestroy(() => {
    isMounted = false;
  });
</script>

<svelte:window on:keydown="{handleKeydown}" />

{#await fetchClients && fetchPrices && fetchVendors}
  <Spinner />
{/await}

<div class="h-screen w-screen sm:px-8 md:px-16 sm:py-8">
  <form class="container mx-auto max-w-screen-lg h-full">
    <div>
      <h2 class="text-2xl dark:text-white my-6 font-semibold leading-tight">
        {title}
      </h2>
    </div>
    <div class="flex flex-wrap -mx-3 mb-6">
      <div class="w-full md:w-3/5 px-3 mb-6 md:mb-0">
        <Textbox
          bind:text="{rfcMsgId}"
          placeholder="RFC Message-Id"
          isAutoFocus="{true}" />
      </div>
      <div class="w-full md:w-2/5 px-3 mb-6 md:mb-0">
        <Select
          items="{companiesData}"
          bind:selectedItemId="{selectedCompany}"
          placeholder="Select Company" />
      </div>
    </div>
    <div class="flex flex-wrap -mx-3 mb-6">
      <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
        <input
          bind:value="{orderName}"
          class="appearance-none block w-full text-gray-700 dark:text-truegray-300 border
            border-black-900 shadow dark:shadow-blue rounded py-3 px-4 leading-tight focus:outline-none
            focus:bg-white dark:focus:bg-truegray-800 dark:bg-truegray-900 focus:shadow-lg border-none dark:focus:text-white focus:text-black"
          type="text"
          placeholder="Order Name"
          on:focus="{() => (isOrderNameDirty = true)}" />
        {#if isOrderNameDirty && isOrderNameEmpty}
          <p class="text-red-500 text-xs italic">Please fill out this field.</p>
        {/if}
      </div>
      <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
        <Select
          items="{clientsData}"
          discouragedItemHint="Deprec"
          warnItemBadgeHint="🚫"
          bind:selectedItemId="{selectedClient}"
          on:focus="{() => (isClientSelectDirty = true)}"
          placeholder="Select Client" />
        {#if isClientSelectDirty && isClientEmpty}
          <p class="text-red-500 text-xs italic">Please fill out this field.</p>
        {/if}
      </div>
    </div>
    <div class="flex flex-wrap -mx-3 mb-6">
      <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
        <Select
          items="{vendorsData}"
          bind:selectedItemId="{selectedVendor}"
          on:focus="{() => (isVendorSelectDirty = true)}"
          placeholder="Select Vendor"
          discouragedItemBadgeHint="🚫" />
        {#if isVendorSelectDirty && isVendorEmpty}
          <p class="text-red-500 text-xs italic">Please fill out this field.</p>
        {/if}
      </div>
      <div class="px-3 mb-6 md:mb-0">
        <input
          bind:value="{price}"
          class="appearance-none block w-32 text-gray-700 dark:text-truegray-300 border border-black-900 shadow dark:shadow-blue
            rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-truegray-800 dark:bg-truegray-900
            focus:shadow-lg border-none dark:focus:text-white focus:text-black dark:disabled:bg-trugray-700 disabled:bg-trugray-300"
          type="number"
          placeholder="${suggestedPrice}"
          disabled="{isPriceFieldDisabled}"
          on:focus="{() => (isPriceDirty = true)}" />
        {#if isPriceDirty && isPriceEmpty && isNaN(suggestedPrice)}
          <p class="text-red-500 text-xs italic">Please fill out this field.</p>
        {/if}
      </div>
      <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
        <div class="flex flex-wrap">
          <div class="w-1/2 my-1 mb-2">
            <Switch
              bind:checked="{rush}"
              id="rushSwitch"
              text="Rush"
              offText="Not rush" />
          </div>
          {#if rush}
            <div
              class="w-full md:w-1/2 my-1 mb-2"
              in:fly="{{ x: -200, easing: linear, duration: 50 }}"
              out:fly="{{ x: -200, duration: 50 }}">
              <Switch
                bind:checked="{superRush}"
                id="superRushSwitch"
                text="Super rush" />
            </div>
          {/if}
        </div>
      </div>
    </div>
    <div class="flex flex-wrap -mx-3 mb-6">
      <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
        <input
          bind:value="{emailSubjectAddendum}"
          class="appearance-none block w-full text-gray-700 dark:text-truegray-300 border
            border-black-900 shadow dark:shadow-blue rounded py-3 px-4 leading-tight focus:outline-none
            focus:bg-white dark:focus:bg-truegray-800 dark:bg-truegray-900 focus:shadow-lg border-none dark:focus:text-white focus:text-black"
          type="text"
          placeholder="Append to email subject" />
        <div class="mt-5">
          <div
            bind:this="{emailBodyInputRef}"
            bind:innerHTML="{emailBody}"
            class="overflow-auto appearance-none block w-full text-gray-700 dark:text-truegray-300 border border-black-900 shadow dark:shadow-blue rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-truegray-800 dark:bg-truegray-900 focus:shadow-lg border-none dark:focus:text-white focus:text-black h-96"
            contenteditable="true"
            placeholder="Email body">
          </div>
          {#if !emailBodyContentWasAttentedTo && showEmailBodyError && isOrderFetchedFromServer}
            <p class="text-red-500 text-xs italic">
              Please change this field a bit.
            </p>
          {/if}
        </div>
      </div>
      <div class="w-full md:w-2/3 px-3 mb-6 md:mb-0">
        <Uploader
          bind:uuid="{uuid}"
          bind:uploadedFileNames="{uploadedFileNames}"
          bind:attachedFileNames="{attachedFileNames}"
          bind:attachedFileNamesFromServer="{attachedFileNamesFromServer}" />
      </div>
    </div>
    <div
      class="px-5 py-5 bg-white dark:bg-truegray-900 border-t dark:border-truegray-700 flex flex-col xs:flex-row items-center
        xs:justify-between">
      <div class="mt-2 px-2 xs:mt-0 items-center">
        <button
          class="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold
            py-2 px-4 rounded-l shadow dark:shadow-blue focus:shadow-lg border-none dark:focus:text-white focus:text-black"
          class:cursor-not-allowed="{disableActionButton}"
          on:click="{handleSend}"
          id="submit"
          disabled="{disableActionButton}"
          type="button">
          {title.split(' ')[0]}
        </button>
        <button
          class="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold
            py-2 px-4 rounded-r shadow dark:shadow-blue focus:shadow-lg border-none dark:focus:text-white focus:text-black"
          on:click="{cancel}"
          id="cancel"
          type="button">
          Cancel
        </button>
      </div>
    </div>
  </form>
</div>

<style>
  [contenteditable='true']:empty:before {
    content: attr(placeholder);
    pointer-events: none;
    color: lightgray;
    display: block;
    position: absolute;
  }

  button:disabled,
  button[disabled] {
    background-color: #cccccc;
    color: #666666;
  }

  #overlay p,
  i {
    opacity: 0;
  }

  #overlay.draggedover {
    background-color: rgba(255, 255, 255, 0.7);
  }
  #overlay.draggedover p,
  #overlay.draggedover i {
    opacity: 1;
  }

  .group:hover .group-hover\:text-blue-800 {
    color: #2b6cb0;
  }
</style>
