export default [
    {
        label: "DESIGN IN VENDOR INVOICE", // Column name
        field: "vendor", // Field name from row
        numeric: false, // Affects sorting
        html: false // Escapes output if false.
    },
    {
        label: "DESIGN IN DOC",
        field: "docname",
        numeric: false,
        html: false
    }
]