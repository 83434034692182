<script>
  import {onMount} from 'svelte';
  import {getData} from '../../services/fetchData.js';
  import Switch from '../Switch.svelte';
  import Chip from '@fouita/chip';
  import {fly} from 'svelte/transition';
  import {emergencyPasswordHashStore} from './stores/index2';
  import {capitalizeFirstLetter, generateAvatar, getDateFromHexId, getTailwindColors,} from '../../helpers/functions';
  import {getRelativeTime} from '../../helpers/relativeTime';
  import {AES} from '../../helpers/aes';

  const removeChip = (position) => {
    if (clientEmail.length == 1) {
      return;
    }
    clientEmail.splice(position, 1);
    clientEmail = [...clientEmail];
  };

  export let clientName = 'Loading';
  export let orderAttachmentNames = {};
  export let clientEmail = [];
  export let emailBody = 'Loading';
  export let bwdEmailSubject = 'Loading';
  export let revFwdMsgId;
  export let isClientDefaulter = false;
  export let emergencyMode = false;
  export let markDesignCompleted;
  export let submit;
  export let setApprovalWarning,
  bypassApproval = false;
  
  const decryptionSequence = [23, 45, 58, 96, 89];
  
  let attachmentSelect = [];
  let revisionAttachments = [];
  let selectedAttachmentGroups = [];
  let emergencyModeText = "Emergency mode is on for this client. Please verify to send design.";

  let isMounted = false,
    isFirstLoad = false;

  let emergencyModePassInput, emergencyModePassword;
  let fetchEmergencyPasswordHash;

  $: keys = Object.keys(orderAttachmentNames);
  $: if (
    revisionAttachments.length > 0 &&
    !bwdEmailSubject.toLowerCase().includes('(rev')
  )
    bwdEmailSubject += ' (Rev.)';

  $: emergencyPasswordHash = $emergencyPasswordHashStore;

  const fetchData = async () => {
    if (!$emergencyPasswordHashStore) {
      fetchEmergencyPasswordHash = getData('https://test.dev/user/read/0');

      [fetchEmergencyPasswordHash] = await Promise.all([
        fetchEmergencyPasswordHash,
      ]);
      const passwordHash = await fetchEmergencyPasswordHash.json();
      $emergencyPasswordHashStore = passwordHash.password;
    }
  };

  let allowBulkRename = false,
    showRenameBox = false,
    renameText = '';
  let selectedAttachments = [];

  const emailColors = { true: 'green', false: 'white' };
  const colors = getTailwindColors();

  const handleAttachments = (key, id) => {
    const attachment = orderAttachmentNames[key][id];
    if (!attachment) delete orderAttachmentNames[key][id];
    orderAttachmentNames[key][id] = orderAttachmentNames[key][id];
    orderAttachmentNames = { ...orderAttachmentNames };
  };

  const handleAttachmentSelect = (e, id) => {
    if (e.ctrlKey) {
      selectedAttachments.push(id);
      e.target.classList.add('dark:bg-truegray-200');
      e.target.classList.add('bg-truegray-900');
    }
  };

  const ProcessRename = elementId => {
    if (!renameText || elementId != 'attachmentList' || !selectedAttachments.length) {
      showRenameBox = false;
      return;
    }
    const firstAttachmentId = selectedAttachments[0];
    const key = firstAttachmentId.substring(0, firstAttachmentId.indexOf('~'));

    for (const id of selectedAttachments) {
      if (!orderAttachmentNames[key][id]) continue;

      const ext = orderAttachmentNames[key][id].split('.').pop();
      if (renameText.toUpperCase() == '*DEL')
        orderAttachmentNames[key][id] = '';
      else orderAttachmentNames[key][id] = renameText + '.' + ext;
    }
    selectedAttachments = [];
    showRenameBox = false;
  };

  const handleKeydown = (e) => {
    switch (e.key) {
      case 'F2':
        showRenameBox = +!showRenameBox;
        if (!selectedAttachments.length) break;
        const firstAttachmentId = selectedAttachments[0];
        const key = firstAttachmentId.substring(
                0,
                firstAttachmentId.indexOf('~')
        );

        renameText = orderAttachmentNames[key][firstAttachmentId]
                .trim()
                .replace(/\.[^/.]+$/, '');

        break;

      case 'Enter':
        switch (e.ctrlKey) {
          case true:
            submit();
            break;
          case false:
            if (e.srcElement.id === 'emergencyModePassInput' && emergencyMode) {
              toggleEmergencyMode();
              return;
            }
            ProcessRename(e.srcElement.id);
            break;
        }
    }
  };

  const toggleEmergencyMode = async () => {
    let decrypted;
    if (emergencyModePassword) {
      decrypted = emergencyModePassword;
    } else {
      try {
        decrypted = await AES().decrypt(
          emergencyPasswordHash,
          null,
          decryptionSequence
        );
      } catch (error) {
        $emergencyPasswordHashStore = null;
        emergencyModeText += "\n An error occured while decrypting the password. Please try again."
      }
    }
    if (decrypted == emergencyModePassInput) {
      emergencyModePassword = decrypted;
      emergencyMode = false;
    }
    emergencyModePassInput = '';
  };

  function formatMsgIdToDate(uuid) {
    const msgDate = getDateFromHexId(uuid);
    return `${capitalizeFirstLetter(
      getRelativeTime(msgDate)
    )} at ${msgDate.toLocaleString('en-US', {
      hour12: true,
      timeZone: 'Asia/Karachi',
      hour: 'numeric',
      minute: 'numeric',
    })}`;
  }

  function handleAttachmentGroupSelect(orderAttachments) {
    selectedAttachmentGroups = Object.keys(orderAttachments).filter(
      (o, i) => attachmentSelect[i]
    );
    for (const key in orderAttachments) {
      if (!selectedAttachmentGroups.includes(key)) {
        Object.entries(orderAttachments[key]).forEach(([id, attachment]) => {
          if (!id.includes('~Removed')) {
            orderAttachments[key][id + '~Removed'] = attachment;
            delete orderAttachments[key][id];
          }
        });
      } else {
        Object.entries(orderAttachments[key]).forEach(([id, attachment]) => {
          if (id.includes('~Removed')) {
            orderAttachments[key][id.replaceAll('~Removed', '')] = attachment;
            delete orderAttachments[key][id];
          }
        });
      }
    }
  }

  onMount(() => {
    isMounted = true;
    isFirstLoad = true;
  });

  $: if (keys.length > 0 && isFirstLoad) {
    keys.forEach((key, index) => {
      const isMsgAfterRev = revFwdMsgId
        ? getDateFromHexId(key) > getDateFromHexId(revFwdMsgId)
        : false;
      attachmentSelect[index] = isMsgAfterRev;
      if (isMsgAfterRev) revisionAttachments.push(key);
    });
    attachmentSelect[attachmentSelect.length - 1] = true;
    isFirstLoad = false;
  }

  $: if (attachmentSelect) handleAttachmentGroupSelect(orderAttachmentNames);

  $: if (!setApprovalWarning || bypassApproval) fetchData();
</script>

<svelte:window on:keydown="{handleKeydown}" />

<section class="mx-auto my-8 w-full max-w-lg">
  {#if setApprovalWarning}
    <Switch
      text="Design not approved to send. Bypass approval anyway?"
      bind:checked="{bypassApproval}"
      id="bypassSwitch" />
    {#if bypassApproval}
      <button
        class="text-sm bg-gray-300 border-none mx-auto hover:bg-gray-400
        font-semibold p-2 shadow dark:shadow-blue rounded block my-2"
        on:click="{() => (setApprovalWarning = false)}">Bypass</button>
    {/if}
  {:else}
    <div class="flex justify-between items-center pb-2">
      <div class="flex space-x-4 items-center">
        <div class="h-12 w-12 rounded-full overflow-hidden">
          <img
            src="{generateAvatar(clientName)}"
            loading="lazy"
            class="w-full object-cover" />
        </div>
        <div class="flex flex-col">
          <h3
            class="text-lg text-truegray-900 dark:text-truegray-50 ml-2"
            class:loading="{'Loading' === clientName}">
            {clientName}
          </h3>
          <div class="flex flex-wrap items-end">
            {#each clientEmail.slice(0, clientEmail.length <= 2 ? clientEmail.length : 2) as email, i}
              <Chip
                color="{isClientDefaulter
                  ? 'red'
                  : emailColors[String(i == 0)]}"
                onDelete="{() => removeChip(i)}"
                rounded="full">{email}</Chip>
            {/each}
          </div>
          {#if clientEmail.length > 2}
            <div class="flex items-end">
              {#each clientEmail.slice(2, clientEmail.length + 1) as email, i}
                <Chip
                  color="white"
                  onDelete="{() => removeChip(i + 2)}"
                  rounded="full">{email}</Chip>
              {/each}
            </div>
          {/if}
        </div>
      </div>
    </div>
    <section>
      <h1
        class="text-2xl dark:text-truegray-50 py-2 border-b-2"
        class:loading="{'Loading' === bwdEmailSubject}"
        contenteditable="true"
        bind:innerHTML="{bwdEmailSubject}">
      </h1>
      <article
        class="my-8 text-truegray-900 dark:text-truegray-50"
        class:loading="{'Loading' === emailBody}"
        contenteditable="true"
        bind:innerHTML="{emailBody}">
      </article>
      {#if !showRenameBox}
        <ul>
          {#each keys as key, index}
            <li class="mb-2">
              <div class="flex items-center mb-1">
                <span
                  class="h-6 w-6 text-truegray-400"
                  class:enabled="{attachmentSelect[index]}"
                  class:disabled="{!attachmentSelect[index]}"
                  class:revision="{revisionAttachments.includes(key)}">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                    ></path>
                  </svg>
                </span>
                <div
                  class="flex-1 ml-4 text-lg flex flex-col xs:flex-row xs:justify-between"
                  class:enabled="{attachmentSelect[index]}"
                  class:disabled="{!attachmentSelect[index]}"
                  class:revision="{revisionAttachments.includes(key)}">
                  {formatMsgIdToDate(key) +
                    ` (${Object.keys(orderAttachmentNames[key]).length} files)`}
                </div>
                <div class="flex items-center justify-end p-2">
                  <Switch
                    bind:checked="{attachmentSelect[index]}"
                    value="{key}"
                    id="{`${key}~Switch`}" />
                </div>
              </div>
              {#if attachmentSelect[index]}
                <ul in:fly="{{ y: 100, duration: 500 }}" class="ml-2">
                  {#each Object.entries(orderAttachmentNames[key]) as [id, value]}
                    {#if orderAttachmentNames[key][id]}
                      <li class="mb-2">
                        <div class="flex items-center mb-1">
                          <!-- svelte-ignore a11y-mouse-events-have-key-events -->
                          <div
                            class="bg-{colors[
                              (Math.random() * colors.length) | 0
                            ]}-300 rounded-full text-sm font-semibold h-8 w-8 items-center justify-center flex z-10"
                            class:cursor-pointer="{allowBulkRename}"
                            on:click="{(e) => handleAttachmentSelect(e, id)}"
                            on:mouseover="{(e) =>
                              (allowBulkRename = e.ctrlKey)}"
                            on:mouseleave="{(e) => (allowBulkRename = false)}">
                            {value.split('.').pop().toUpperCase()}
                          </div>
                          <div
                            class="flex-1 ml-4 text-lg text-gray-900 dark:text-truegray-50 flex flex-col xs:flex-row xs:justify-between"
                            contenteditable="true"
                            on:blur="{() => handleAttachments(key, id)}"
                            bind:textContent="{orderAttachmentNames[key][id]}">
                          </div>
                        </div>
                      </li>
                    {/if}
                  {/each}
                </ul>
              {/if}
            </li>
          {/each}
        </ul>
      {:else}
        <input
          bind:value="{renameText}"
          class="appearance-none block w-full text-gray-700 dark:text-truegray-300 border
            border-black-900 shadow dark:shadow-blue rounded py-3 px-4 leading-tight focus:outline-none
            focus:bg-white dark:focus:bg-truegray-800 dark:bg-truegray-900 focus:shadow-lg border-none dark:focus:text-white focus:text-black"
          type="text"
          id="attachmentList"
          placeholder="Rename design here"
          autofocus />
      {/if}
      <!-- <ul class="flex space-x-4 mt-12">
      <li
        class="w-10 h-10 border rounded-lg p-1 cursor-pointer transition duration-200 text-green-600 hover:bg-blue-100">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 20 20"
          stroke="currentColor">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
            d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
          ></path>
        </svg>
      </li>
    </ul> -->
    </section>
    <section class="mt-6 rounded mb-3">
      {#if !emergencyMode}
        <div
          class="flex items-center justify-end p-2"
          in:fly="{{ x: 50, duration: 450 }}">
          <button
            class="text-sm bg-gray-300 border-none mx-1 hover:bg-gray-400
      font-semibold p-2 shadow dark:shadow-blue rounded"
            on:click="{markDesignCompleted}">✔️ Mark Complete</button>
          <button
            class="text-sm bg-gray-300 border-none hover:bg-gray-400
      font-semibold p-2 shadow dark:shadow-blue rounded"
            on:click="{submit}">📤 Send</button>
        </div>
      {:else}
        <div class="flex items-center mb-1">
          <span class="h-6 w-6 dark:text-truegray-50 text-truegray-900">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
                d="M17.308,7.564h-1.993c0-2.929-2.385-5.314-5.314-5.314S4.686,4.635,4.686,7.564H2.693c-0.244,0-0.443,0.2-0.443,0.443v9.3c0,0.243,0.199,0.442,0.443,0.442h14.615c0.243,0,0.442-0.199,0.442-0.442v-9.3C17.75,7.764,17.551,7.564,17.308,7.564 M10,3.136c2.442,0,4.43,1.986,4.43,4.428H5.571C5.571,5.122,7.558,3.136,10,3.136 M16.865,16.864H3.136V8.45h13.729V16.864z M10,10.664c-0.854,0-1.55,0.696-1.55,1.551c0,0.699,0.467,1.292,1.107,1.485v0.95c0,0.243,0.2,0.442,0.443,0.442s0.443-0.199,0.443-0.442V13.7c0.64-0.193,1.106-0.786,1.106-1.485C11.55,11.36,10.854,10.664,10,10.664 M10,12.878c-0.366,0-0.664-0.298-0.664-0.663c0-0.366,0.298-0.665,0.664-0.665c0.365,0,0.664,0.299,0.664,0.665C10.664,12.58,10.365,12.878,10,12.878"
              ></path>
            </svg>
          </span>
          <input
            in:fly="{{ y: 50, duration: 50 }}"
            bind:value="{emergencyModePassInput}"
            id="emergencyModePassInput"
            class="appearance-none block w-1/2 ml-4 text-gray-700 dark:text-truegray-300 border border-black-900 shadow dark:shadow-blue rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-truegray-800 dark:bg-truegray-900 focus:shadow-lg border-none dark:focus:text-white focus:text-black"
            type="password"
            placeholder="Emergency Mode Password"
            autofocus />
        </div>
        <p class="text-red-500 text-xs mt-1 pl-10 italic">
          {emergencyModeText}
        </p>
      {/if}
    </section>
  {/if}
</section>

<style>
  .disabled {
    @apply text-truegray-500;
  }
  .enabled {
    @apply text-green-600;
  }
  .revision {
    @apply text-purple-500;
  }
</style>
