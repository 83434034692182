<script>
  import { Link } from "svelte-routing";

  export let to = "";
  export let externalClasses = "";

  function getProps({ location, href, isPartiallyCurrent, isCurrent }) {
    const isActive = href === "/" ? isCurrent : isPartiallyCurrent || isCurrent;

    if (externalClasses) {
      return {
        class: externalClasses,
      };
    }
    // The object returned here is spread on the anchor element's attributes
    if (isActive) {
      return {
        class:
          "px-4 py-2 mt-2 text-base font-semibold text-truegray-900 bg-truegray-200 rounded-lg dark:bg-truegray-100 dark:hover:bg-truegray-600 dark:focus:bg-truegray-600 dark:focus:text-white dark:hover:text-white dark:text-truegray-900 md:mt-0 md:ml-4 hover:text-truegray-900 focus:text-truegray-900 hover:bg-truegray-200 focus:bg-truegray-200 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
      };
    }
    return {
      class:
        "px-4 py-2 mt-2 text-base font-semibold bg-transparent rounded-lg dark:bg-transparent dark:hover:bg-truegray-600 dark:focus:bg-truegray-600 dark:focus:text-white dark:hover:text-white dark:text-truegray-200 md:mt-0 md:ml-4 hover:text-truegray-900 focus:text-truegray-900 hover:bg-truegray-200 focus:bg-truegray-200 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out",
    };
  }
</script>

<Link to="{to}" getProps="{getProps}">
  <slot />
</Link>
