<script>
  import VirtualList from './VirtualList.svelte';
  import { clickOutside } from './clickOutside.js';
  import { tick } from 'svelte';
  import { createPopper } from '@popperjs/core';
  import { fuzzy } from '../../helpers/fuzzy';
  import { appTheme } from '../../stores/index';

  import { createEventDispatcher } from 'svelte';
  const dispatch = createEventDispatcher();

  export let isDisabled;
  export let items = [];
  export let listIsOpen = false;
  export let labelField = 'label';
  export let valueField = 'value';
  export let badgeField = 'badge';
  export let placeholder = 'Select item';
  export let openOnFocus = true;
  export let height = '12rem';
  export let width = '100%';
  export let itemHeight = undefined;
  export let discouragedItemHint = 'A really long string to not match';
  export let discouragedItemBadgeHint = 'Another long string to not match';
  export let warnItemBadgeHint = 'Another long string to not match';
  export let warnItemHint = 'Another long string to not match';
  export let customIconSvgEmbed = '';
  export let customIconClickHandler = (customIconToggle) => {
    console.log(customIconToggle);
  };
  export let customIconToggle = 0;

  const allItemsUniqueStrings = new Set();

  let search = '';
  let search_element;
  let ulElement;
  $: filteredItems = items;
  let popper;

  $: attachAllUniqueLabelStrings(items);

  $: if (listIsOpen)
    if (!search) {
      filteredItems = items;
    } else {
      const searchAll = fuzzy(items, labelField, [...allItemsUniqueStrings]);
      filteredItems = searchAll(search);
    }

  let highlightedItemIndex = 0;
  let highlightedItemObj = {};
  export let selectedItemId = null;
  let selectedItemObj = {};
  let mainElement; // DOM reference

  $: highlightedItemObj = filteredItems[highlightedItemIndex];

  $: selectedItemObj = !selectedItemId
    ? {}
    : filteredItems.find(function (element) {
        // for some reason filteredItems is empty
        return element[valueField] == selectedItemId; // so it returns {} as selectedItemObj
      }) || selectedItemObj;

  function onSelect(item) {
    //		console.log('onSelect',item)
    //highlightedItemObj = item
    //selectedItemObj = item // clone?
    selectedItemId = item[valueField];
    search = null; //item[labelField]
    closeList();
  }

  function attachAllUniqueLabelStrings(items) {
    if (allItemsUniqueStrings.size !== 0 || !items) return false;

    items.map((v) => {
      const words = v[labelField].split(' ');
      words.forEach((word) => {
        allItemsUniqueStrings.add(word);
      });
    });
  }

  function closeList() {
    if (popper) popper.destroy();
    listIsOpen = false;
    highlightedItemObj = null;
    highlightedItemIndex = 0;
    search = null;
  }

  async function openList(e) {
    //console.log('openList()', e)
    if (e.type == 'keydown' && e.ctrlKey) {
      return;
    }
    if (e.type != 'keydown') search_element.select();
    if (!listIsOpen) {
      listIsOpen = true;
      await tick();
      window.process = { env: { NODE_ENV: 'production' } }; // Kretenizam, use https://github.com/rollup/plugins/tree/master/packages/replace
      ulElement.style.width = search_element.offsetWidth + 'px' || '20em';
      popper = createPopper(search_element, ulElement, {
        placement: 'bottom-start',
        strategy: 'fixed',
      });
      await tick();
      popper.update();
    }
  }
  /*
	function highlight(item){
		highlightedItemObj = item
		//highlightedItemIndex = index
  }
  */
  function handleKeydown(event) {
    // console.log('$$props', $$props)
    //    console.log('$$rest', $$rest)
    //    console.log('$$restProps', $$restProps)

    // for (const key of Object.keys($$props)) {
    // console.log('key ', key, '=', $$props[key])

    //      if (key !== "children" && key !== "$$scope" && key !== "$$slots") {
    //        rest[key] = $$props[key];
    //    }
    //}

    if (!mainElement.contains(event.target)) return;
    //console.log(listIsOpen, event)

    // Enter
    if (event.keyCode == 13 && listIsOpen && highlightedItemObj) {
      event.preventDefault();
      //selectedItemObj = { ...highlightedItemObj }
      selectedItemId = highlightedItemObj[valueField];
      search = null; //selectedItemObj[labelField]
      closeList();
      return;
    }

    // Escape
    if (event.keyCode == 27) {
      event.preventDefault();
      closeList();
      return;
    }

    // Key Down
    if (event.keyCode == 40) {
      highlightedItemIndex++;
      if (highlightedItemIndex >= filteredItems.length)
        highlightedItemIndex = 0;
      highlightedItemObj = filteredItems[highlightedItemIndex];
      event.preventDefault();
    }

    // Key Up
    if (event.keyCode == 38) {
      highlightedItemIndex--;
      if (highlightedItemIndex < 0)
        highlightedItemIndex = filteredItems.length - 1;
      highlightedItemObj = filteredItems[highlightedItemIndex];
      event.preventDefault();
    }

    let ulElement2 = ulElement && ulElement.firstElementChild;
    let clientHeight = ulElement2 && ulElement2.clientHeight;
    let scrollHeight = ulElement2 && ulElement2.scrollHeight;
    if (clientHeight && scrollHeight && filteredItems && filteredItems.length) {
      ulElement2.scrollTop =
        (scrollHeight / filteredItems.length) * highlightedItemIndex -
        clientHeight / 2 +
        20;
    } else ulElement2.scrollTop = 0;
  }

  function onFocus(event) {
    if (openOnFocus) openList(event);
    dispatch('focus', event.detail);
  }
</script>

<div
  on:keydown="{handleKeydown}"
  bind:this="{mainElement}"
  style="width:{width}">
  <div class="search-select" use:clickOutside on:click_outside="{closeList}">
    <input
      bind:this="{search_element}"
      type="search"
      disabled={isDisabled}
      style="padding-right: 2rem"
      class="appearance-none block w-full text-gray-700 dark:text-truegray-300 border
      border-black-900 shadow dark:shadow-blue rounded py-3 px-4 leading-tight focus:outline-none
      focus:bg-white dark:focus:bg-truegray-800 dark:bg-truegray-900 focus:shadow-lg border-none dark:focus:text-white focus:text-black"
      bind:value="{search}"
      on:focus="{onFocus}"
      on:click="{openList}"
      on:keydown="{openList}"
      on:focusout="{(event) => {
        if (!mainElement.contains(event.relatedTarget)) closeList();
      }}"
      placeholder="{selectedItemObj && selectedItemObj[labelField]
        ? ''
        : placeholder}" />

    <!-- selected item overlay -->
    {#if !search && !listIsOpen && selectedItemObj && selectedItemObj[labelField]}
      <div
        class="text-gray-900 dark:text-truegray-50 mb-2 ml-4 padding-top-2125 truncate">
        {(selectedItemObj && selectedItemObj[labelField]) || placeholder}
        <span
          class="font-medium float-right h-6 w-6 mr-1 cursor-pointer"
          class:text-green-500="{Boolean(customIconToggle)}"
          class:animate-pulse="{!Boolean(customIconToggle)}"
          on:click="{() => {
            customIconToggle = +!customIconToggle;
            customIconClickHandler(customIconToggle);
          }}">
          {@html customIconSvgEmbed}
        </span>
      </div>
    {/if}

    <!-- Close icon in input -->
    {#if selectedItemObj && selectedItemObj[labelField]}
      <div
        class="form-control mb-0 close-icon"
        on:click="{() => {
          search = null;
          selectedItemId = null;
        }}">
        <i class="fas fa-times"></i>
      </div>
    {/if}

    <!-- Dropdown icon in input -->
    {#if !listIsOpen && !(selectedItemObj && selectedItemObj[labelField])}
      <div class="form-control mb-0 close-icon" on:click="{openList}">
        <i class="fas fa-angle-down"></i>
      </div>
    {/if}

    {#if listIsOpen}
      <ul
        class="absolute text-gray-700 dark:text-truegray-200 py-1 w-1/2 border
        border-black-900 shadow-lg dark:shadow-blue rounded search-list bg-white dark:bg-truegray-900 focus:shadow-lg border-none dark:focus:text-white focus:text-black"
        bind:this="{ulElement}"
        style="height:{height}"
        tabindex="-1">
        {#if !items || !items.length}<i>No matches found</i>{/if}
        <!--						{#each filteredItems as item, ix} -->
        <VirtualList items="{filteredItems}" let:item itemHeight="{itemHeight}">
          <li
            on:click="{() => onSelect(item)}"
            on:mouseover="{() => {
              highlightedItemObj = item;
            }}"
            on:focus="{() => {
              highlightedItemObj = item;
            }}"
            class="rounded hover:bg-gray-400 py-2 px-4 block whitespace-nowrap"
            class:bg-gray-300="{selectedItemObj &&
              selectedItemObj[valueField] == item[valueField]}"
            class:bg-gray-400="{highlightedItemObj &&
              highlightedItemObj[valueField] == item[valueField]}"
            class:dark:bg-truegray-600="{selectedItemObj &&
              selectedItemObj[valueField] == item[valueField]}"
            class:text-truegray-600="{item[labelField]
              .toLowerCase()
              .includes(discouragedItemHint.toLowerCase()) ||
              (item[badgeField] &&
                item[badgeField]
                  .toLowerCase()
                  .includes(discouragedItemBadgeHint.toLowerCase()))}"
            class:text-orange-600="{Boolean(
              item[labelField].toLowerCase().match(warnItemHint.toLowerCase())
            ) ||
              (item[badgeField] &&
                Boolean(
                  item[badgeField]
                    .toLowerCase()
                    .match(warnItemBadgeHint.toLowerCase())
                ))}"
            class:dark:bg-truegray-700="{highlightedItemObj &&
              highlightedItemObj[valueField] == item[valueField]}">
            <slot prop="{item}">{item[labelField]}</slot>
            {#if item[badgeField]}
              <span
                style="padding-top: 0.1em; padding-bottom: 0.1rem"
                class="text-xs font-medium px-3 bg-gray-200 text-truegray-900 rounded-full float-right mt-1"
                >{item[badgeField]}</span>
            {/if}
          </li>
        </VirtualList>
      </ul>
    {/if}
  </div>
  <!--
	<hr>
	<br><br><br><br><br><br><br><br><br>
	out
	<code><pre style="width:100%">
	{ JSON.stringify({highlightedItemObj, selectedItemObj, listIsOpen, selectedItemId}, null, 4) }
	clientHeight = {ulElement && ulElement.clientHeight}
	scrollHeight = {ulElement && ulElement.scrollHeight}
	offsetTop = {search_element && search_element.offsetTop}
	scrollTop = {search_element && search_element.scrollTop}
	
	</pre></code>
-->
</div>

<style>
  .search-select {
    position: relative;
  }
  .search-list {
    z-index: 11;
    min-width: 12rem;
    /*	position: absolute;
		left:0;
		right:0;*/
    overflow: auto;
  }
  .list-group-item {
    cursor: pointer;
  }

  .close-icon:hover {
    opacity: 1;
  }

  .close-icon {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    border: none;
    background-color: rgba(255, 255, 255, 0);
    cursor: pointer;
    opacity: 0.5;
  }

  .padding-top-2125 {
    margin-top: -2.125rem;
  }
</style>
