<script>
  export let listData = [];
  export let showAddBtn = false;

  export let fetchData = (id) => {
    console.log('fetch data was called with id ' + id);
  };

  export let create = () => {
    console.log('fetch data was called with id ' + id);
  };
</script>

<div class="flex flex-wrap justify-center my-3 items-center mx-56">
  {#each listData as item}
    <!-- svelte-ignore a11y-missing-attribute -->
    <p
      class="leading-5 font-medium cursor-pointer my-1 text-center text-truegray-800 dark:text-truegray-200 bg-truegray-300 hover:bg-truegray-400 dark:bg-truegray-700 dark:hover:bg-truegray-600 rounded-full py-1 mx-1 px-3 items-center"
      on:click="{() => fetchData(item?.id)}">
      {item?.title}
    </p>
  {/each}
  {#if showAddBtn}
    <p
      class="leading-5 font-medium cursor-pointer my-1 text-center dark:text-truegray-200 bg-green-600 hover:bg-green-700 rounded-full py-1 mx-1 px-3 items-center"
      on:click="{() => create()}">
      ➕ Add New
    </p>
  {/if}
</div>
