<script>
  import {onDestroy, onMount} from "svelte";
  import {getData} from "../services/fetchData.js";
  import Spinner from "../components/Spinner.svelte";
  import Select from "../components/select/Select.svelte";
  import Switch from "../components/Switch.svelte";
  import Chart from "chart.js/auto";
  import CustomLineChart from "../components/charts/CustomLineChart";

  import {
    avgJobPriceStore,
    avgWeeklyJobsStore,
    employeeColorsStore,
    lastCachedDateStore,
    lastWeekCompareStore,
    mostClientsRepStore,
    oneTimeClientsStore,
    vendorAvgStore,
    vendorStore,
  } from "./stores/index";
  import {appTheme} from "../stores/index";
  import {
    formatSalesContributionBarChartData,
    formatSalesContributionLineChartData,
    getTimeDifferenceFromNow,
    groupBy,
    hexToRgbaString,
    SortAccentColorsByEmployees,
  } from "../helpers/functions";

  let isMounted = false;

  let fetchLastWeekCompare,
    fetchAvgWeeklyJobs,
    fetchAvgJobPrice,
    fetchClientsStatusInfo,
    fetchOneTimeClients,
    fetchEmployeesJobContribution,
    fetchRevenueByCompany,
    fetchMonthlyRevenueDetails,
    fetchVendors,
    fetchRepWithMostNewClients,
    fetchVendorAverage,
    fetchEmployeesColors;

  let revenueByCompanyChart,
    monthlyRevenueDetailsChart,
    employeeContributionChart,
    employeesJobsContributionThisMonthChart,
    topClientsChart,
    employeesColors;

  const salesContributionModeEnum = { false: "count", true: "revenue" };
  Object.freeze(salesContributionModeEnum);
  let salesContributionMode = false;

  let lastWeekCompare;
  let avgWeeklyJobs;
  let avgJobPrice;

  let newClients;
  let departedClients;
  let slowerClients;
  let topClients;
  let oneTimeClients;

  let repWithMostNewClients;
  let employeesJobsContribution;
  let employeesJobsContributionThisMonth;

  let revenueByCompany;

  let monthlyRevenueDetails;

  let vendorAverage;

  let vendorsData;
  let vendorIdForSummary = 11;
  let monthAgoForSummary = 1;
  let vendorSummary;
  let workingDays;

  let olderAvgWeeklyJobs = $avgWeeklyJobsStore;
  let olderAvgJobPrice = $avgJobPriceStore;

  let currentDate = new Date();
  let lastCachedDate =
    $lastCachedDateStore == 0 || isNaN(Date.parse($lastCachedDateStore))
      ? new Date("2022-01-02")
      : new Date($lastCachedDateStore);

  const monthsData = [
    { value: 1, label: "Last Month" },
    { value: 2, label: "2 months ago" },
    { value: 3, label: "3 months ago" },
    { value: 4, label: "4 months ago" },
    { value: 5, label: "5 months ago" },
  ];

  const fetchContributionStats = async () => {
    if (
      !employeeContributionChart ||
      !employeesJobsContributionThisMonth ||
      !topClientsChart
    ) {
      return;
    }
    const dataMode = salesContributionModeEnum[salesContributionMode];

    fetchClientsStatusInfo = getData(
      `https://test.dev/statistics/read/${dataMode}`
    );
    fetchEmployeesJobContribution = getData(
      `https://test.dev/statistics/salesContributionByEmployee/${dataMode}`
    );

    [fetchClientsStatusInfo, fetchEmployeesJobContribution] = await Promise.all(
      [fetchClientsStatusInfo, fetchEmployeesJobContribution]
    );

    (async function () {
      employeesJobsContribution = await fetchEmployeesJobContribution.json();

      let clientsInfo = await fetchClientsStatusInfo.json();
      topClients = clientsInfo.top;
    })();
    employeeContributionChart.destroy();
    employeesJobsContributionThisMonthChart.destroy();
    topClientsChart.destroy();
  };

  const isSecondaryCacheInvalid = () => {
    return [
      $vendorAvgStore,
      $oneTimeClientsStore,
      $vendorStore,
      $employeeColorsStore,
      $lastWeekCompareStore,
      $mostClientsRepStore,
    ].some((item) => item === 0 || item === undefined);
  };

  $: if (isMounted && salesContributionModeEnum[salesContributionMode]) {
    fetchContributionStats();
  }

  const fetchData = async () => {
    const dataMode = salesContributionModeEnum[salesContributionMode];

    fetchClientsStatusInfo = getData(
      `https://test.dev/statistics/read/${dataMode}`
    );
    fetchEmployeesJobContribution = getData(
      `https://test.dev/statistics/salesContributionByEmployee/${dataMode}`
    );
    fetchRevenueByCompany = getData(
      "https://test.dev/statistics/revenueByCompany"
    );
    fetchMonthlyRevenueDetails = getData(
      "https://test.dev/statistics/monthlyRevenueDetails"
    );

    const isCacheInvalid = isSecondaryCacheInvalid();
    const sinceLastCached = getTimeDifferenceFromNow(lastCachedDate);

    if (sinceLastCached["days"] > 7 || isCacheInvalid) {
      fetchLastWeekCompare = getData(
        "https://test.dev/statistics/compareLastWeekJobs"
      );
      fetchAvgWeeklyJobs = getData("https://test.dev/statistics/avgWeeklyJobs");
      fetchRepWithMostNewClients = getData(
        "https://test.dev/statistics/repWithMostNewClients"
      );
      fetchOneTimeClients = getData(
        "https://test.dev/statistics/oneTimeClients"
      );
      fetchAvgJobPrice = getData("https://test.dev/statistics/avgJobPrice");
      fetchVendorAverage = getData("https://test.dev/statistics/vendorAverage");
      fetchVendors = getData("https://test.dev/entity/read/vendor");
      fetchEmployeesColors = getData("https://test.dev/user/preferredColors");

      lastCachedDateStore.set(currentDate);
    }

    if (sinceLastCached["hours"] > 6 || isCacheInvalid) {
      fetchLastWeekCompare = getData(
        "https://test.dev/statistics/compareLastWeekJobs"
      );
      fetchRepWithMostNewClients = getData(
        "https://test.dev/statistics/repWithMostNewClients"
      );

      lastCachedDateStore.set(currentDate);
    }

    [
      fetchLastWeekCompare,
      fetchAvgWeeklyJobs,
      fetchAvgJobPrice,
      fetchClientsStatusInfo,
      fetchEmployeesJobContribution,
      fetchRevenueByCompany,
      fetchMonthlyRevenueDetails,
      fetchVendors,
      fetchRepWithMostNewClients,
      fetchVendorAverage,
      fetchEmployeesColors,
      fetchOneTimeClients,
    ] = await Promise.all([
      fetchLastWeekCompare || null, // these are cached
      fetchAvgWeeklyJobs || null,
      fetchAvgJobPrice || null,
      fetchClientsStatusInfo,
      fetchEmployeesJobContribution,
      fetchRevenueByCompany,
      fetchMonthlyRevenueDetails,
      fetchVendors || null,
      fetchRepWithMostNewClients,
      fetchVendorAverage || null,
      fetchEmployeesColors || null,
      fetchOneTimeClients || null,
    ]);

    await (async function () {
      lastWeekCompare = fetchLastWeekCompare
        ? await fetchLastWeekCompare.json()
        : $lastWeekCompareStore;
      avgWeeklyJobs = fetchAvgWeeklyJobs
        ? await fetchAvgWeeklyJobs.json()
        : $avgWeeklyJobsStore;
      avgJobPrice = fetchAvgJobPrice
        ? await fetchAvgJobPrice.json()
        : $avgJobPriceStore;

      employeesJobsContribution = await fetchEmployeesJobContribution.json();

      revenueByCompany = await fetchRevenueByCompany.json();
      monthlyRevenueDetails = await fetchMonthlyRevenueDetails.json();

      vendorAverage = fetchVendorAverage
        ? await fetchVendorAverage.json()
        : $vendorAvgStore;
      vendorsData = fetchVendors ? await fetchVendors.json() : $vendorStore;
      repWithMostNewClients = fetchRepWithMostNewClients
        ? await fetchRepWithMostNewClients.json()
        : $mostClientsRepStore;
      employeesColors = fetchEmployeesColors
        ? await fetchEmployeesColors.json()
        : $employeeColorsStore;
      fetchOneTimeClients = fetchOneTimeClients
        ? await fetchOneTimeClients.json()
        : $oneTimeClientsStore;

      if (repWithMostNewClients)
        repWithMostNewClients.sort((a, b) => b.count.localeCompare(a.count));

      let clientsInfo = await fetchClientsStatusInfo.json();
      topClients = clientsInfo.top;
      departedClients = groupBy(clientsInfo.departing, "salesrep");
      newClients = groupBy(clientsInfo.new, "salesrep");
      slowerClients = groupBy(clientsInfo.slow, "salesrep");
      oneTimeClients = groupBy(fetchOneTimeClients, "salesrep");
    })();
  };

  const fetchVendorSummary = async () => {
    let fetchSummary = getData(
      `https://test.dev/statistics/vendorSummary/${vendorIdForSummary}/${monthAgoForSummary}`
    );

    let fetchWorkingDays = getData(
      `https://test.dev/statistics/workingDays/${monthAgoForSummary}`
    );

    [fetchSummary, fetchWorkingDays] = await Promise.all([
      fetchSummary,
      fetchWorkingDays,
    ]);
    vendorSummary = await fetchSummary.json();
    workingDays = await fetchWorkingDays.json();
  };

  const storeNumericalStats = async () => {
    if (avgWeeklyJobs && olderAvgWeeklyJobs == 0)
      avgWeeklyJobsStore.set(avgWeeklyJobs);
    if (avgJobPrice && olderAvgJobPrice == 0) avgJobPriceStore.set(avgJobPrice);

    oneTimeClientsStore.set(fetchOneTimeClients);
    vendorAvgStore.set(vendorAverage);
    vendorStore.set(vendorsData);
    employeeColorsStore.set(employeesColors);
    lastWeekCompareStore.set(lastWeekCompare);
    mostClientsRepStore.set(repWithMostNewClients);
  };

  onMount(async () => {
    isMounted = true;
    Chart.register(CustomLineChart);
    await fetchData();
    storeNumericalStats();
  });

  onDestroy(() => {
    isMounted = false;
  });

  $: if (vendorIdForSummary || monthAgoForSummary) {
    fetchVendorSummary();
  }

  $: employeesJobsContributionThisMonth = formatSalesContributionBarChartData(
    employeesJobsContribution
  );

  $: employeesJobsContributionData = formatSalesContributionLineChartData(
    employeesJobsContribution
  );

  // chart rendering starts below

  $: if (isMounted && revenueByCompany) {
    const data = [];
    for (const companyName in revenueByCompany) {
      data.push(Object.values(revenueByCompany[companyName]));
    }
    revenueByCompanyChart = new Chart(
      document.getElementById("revenueByCompanyChart"),
      {
        type: "doughnut",
        data: {
          labels: Object.keys(revenueByCompany),
          datasets: [
            {
              label: "Issues",
              data: data,
              backgroundColor: ["#270044", "#0361a7", "#fd6001"],
            },
          ],
        },
        options: {
          plugins: {
            legend: { display: false },
          },
          scales: {
            yAxis: {
              ticks: {
                callback: function (val, index) {
                  // Hide the label of every 2nd dataset
                  return "";
                },
                color: $appTheme == "dark" ? "Gainsboro" : "grey",
              },
              gridLines: {
                color: "rgba(136, 136, 136, 0.5)",
              },
            },
            xAxis: {
              ticks: {
                callback: function (val, index) {
                  // Hide the label of every 2nd dataset
                  return "";
                },
                color: $appTheme == "dark" ? "Gainsboro" : "grey",
              },
              gridLines: {
                color: "rgba(136, 136, 136, 0.5)",
              },
            },
          },
          legend: {
            display: false,
          },
          maintainAspectRatio: false,
        },
      }
    );
  }

  $: if (isMounted && monthlyRevenueDetails) {
    let ctx = document.getElementById("monthlyRevenueChart").getContext("2d");

    let gradientStrokeR = ctx.createLinearGradient(500, 0, 100, 0);
    gradientStrokeR.addColorStop(0, "rgba(255, 255, 0, 0.6)");
    gradientStrokeR.addColorStop(1, "rgba(255, 215, 0, 0.6)");

    let gradientStrokeC = ctx.createLinearGradient(500, 0, 100, 0);
    gradientStrokeC.addColorStop(0, "#17B169");
    gradientStrokeC.addColorStop(1, "#018749");

    let gradientFillC = ctx.createLinearGradient(500, 0, 100, 0);
    gradientFillC.addColorStop(0, "rgba(0, 106, 78, 0.6)");
    gradientFillC.addColorStop(1, "rgba(1, 135, 73, 0.6)");

    monthlyRevenueDetailsChart = new Chart(
      document.getElementById("monthlyRevenueChart"),
      {
        type: "CustomLineChart",
        data: {
          labels: monthlyRevenueDetails.label,
          datasets: [
            {
              label: "Revenue Posted",
              data: monthlyRevenueDetails.data.revenue,
              lineTension: 0.4,
              borderColor: gradientStrokeR,
              pointBorderColor: gradientStrokeR,
              pointBackgroundColor: gradientStrokeR,
              pointHoverBackgroundColor: gradientStrokeR,
              pointHoverBorderColor: gradientStrokeR,
              pointBorderWidth: 2,
              pointHoverRadius: 6,
              pointHoverBorderWidth: 1,
              pointRadius: 3,
              fill: false,
              borderWidth: 2,
            },
            {
              label: "Actual Collection",
              data: monthlyRevenueDetails.data.pending,
              borderColor: "#f05e23",
              backgroundColor: "rgba(247, 90, 64, 0.4)",
              lineTension: 0.4,
              borderColor: gradientStrokeC,
              pointBorderColor: gradientStrokeC,
              pointBackgroundColor: gradientStrokeC,
              pointHoverBackgroundColor: gradientStrokeC,
              pointHoverBorderColor: gradientStrokeC,
              pointBorderWidth: 2,
              pointHoverRadius: 6,
              pointHoverBorderWidth: 1,
              pointRadius: 3,
              fill: true,
              backgroundColor: gradientFillC,
              borderWidth: 4,
            },
          ],
        },
        options: {
          plugins: {
            tooltip: {
              mode: "index",
              intersect: false,
            },
          },
          scales: {
            yAxis: {
              ticks: {
                beginAtZero: true,
                callback: function (val, index) {
                  // Hide the label of every 2nd dataset
                  return index % 2 === 0 ? this.getLabelForValue(val) : "";
                },
                color: $appTheme == "dark" ? "Gainsboro" : "grey",
              },
              gridLines: {
                color: "rgba(136, 136, 136, 0.5)",
              },
            },
            xAxis: {
              ticks: {
                callback: function (val, index) {
                  // Hide the label of every 2nd dataset
                  return index % 2 === 0 ? this.getLabelForValue(val) : "";
                },
                color: $appTheme == "dark" ? "Gainsboro" : "grey",
              },
              gridLines: {
                color: "rgba(136, 136, 136, 0.5)",
              },
            },
          },
          legend: {
            labels: {
              color: $appTheme == "dark" ? "Gainsboro" : "grey",
            },
          },
        },
      }
    );
  }

  $: if (isMounted && topClients) {
    const getFirstName = (str) => str.split(" ").shift();

    const ttColors = ["#ff8e47", "#ff8133", "#ff7119", "#ff680a", "#f55e00"];
    const itecColors = ["#d283ff", "#bd68ee", "#ab51e3", "#8b2fc9", "#9102de"];
    const bwwColors = ["#00a6fb", "#0582ca", "#006494", "#005066", "#006daa"];

    const companyColors = { 1: itecColors, 2: bwwColors, 3: ttColors };
    const backgroundcolors = [];
    const bordercolors = [];

    for (let i = 0; i < topClients.company.length; i++) {
      const companyid = topClients.company[i];
      backgroundcolors.push(
        hexToRgbaString(companyColors[companyid][i % 5], 0.8)
      );
      bordercolors.push(hexToRgbaString(companyColors[companyid][i % 5], 0.9));
    }
    topClientsChart = new Chart(document.getElementById("topClientsChart"), {
      type: "bar",
      data: {
        labels: topClients.label.map(getFirstName),
        datasets: [
          {
            label: "Stats",
            data: topClients.data,
            backgroundColor: backgroundcolors,
            borderColor: bordercolors,
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          yAxis: {
            ticks: {
              beginAtZero: true,
              color: $appTheme == "dark" ? "Gainsboro" : "grey",
            },
            gridLines: {
              color: "rgba(136, 136, 136, 0.5)",
            },
          },
          xAxis: {
            ticks: {
              color: $appTheme == "dark" ? "Gainsboro" : "grey",
            },
            gridLines: {
              color: "rgba(136, 136, 136, 0.5)",
            },
          },
        },
        legend: {
          labels: {
            color: $appTheme == "dark" ? "Gainsboro" : "grey",
          },
        },
      },
    });
  }

  $: if (isMounted && vendorAverage) {
    const getFirstName = (str) => str.split(" ").shift();

    new Chart(document.getElementById("vendorAverageChart"), {
      type: "bar",
      data: {
        labels: vendorAverage.label.map(getFirstName),
        datasets: [
          {
            label: "Design Count",
            data: vendorAverage.data,
            backgroundColor: [
              "rgba(139, 0, 255, 0.6)",
              "rgba(254, 20, 146, 0.6)",
              "rgba(153, 244, 161, 0.6)",
              "rgba(255, 246, 212, 0.6)",
              "rgba(255, 208, 199, 0.6)",
            ],
            borderColor: [
              "purple",
              "hotpink",
              "green",
              "yellow",
              "red",
              "black",
            ],
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          yAxis: {
            ticks: {
              beginAtZero: true,
              color: $appTheme == "dark" ? "Gainsboro" : "grey",
            },
            gridLines: {
              color: "rgba(136, 136, 136, 0.5)",
            },
          },
          xAxis: {
            ticks: {
              color: $appTheme == "dark" ? "Gainsboro" : "grey",
            },
            gridLines: {
              color: "rgba(136, 136, 136, 0.5)",
            },
          },
        },
        legend: {
          labels: {
            color: $appTheme == "dark" ? "Gainsboro" : "grey",
          },
        },
      },
    });
  }

  $: if (isMounted && employeesJobsContributionThisMonth && employeesColors) {
    employeesJobsContributionThisMonthChart = new Chart(
      document.getElementById("employeeContributionThisMonthChart"),
      {
        type: "bar",
        data: {
          labels: employeesJobsContributionThisMonth.label,
          datasets: [
            {
              label: "Stats",
              data: employeesJobsContributionThisMonth.data,
              backgroundColor: SortAccentColorsByEmployees(
                employeesJobsContributionThisMonth.label,
                employeesColors,
                "second_color",
                0.5
              ),
              borderColor: SortAccentColorsByEmployees(
                employeesJobsContributionThisMonth.label,
                employeesColors,
                "accent_color",
                0.9
              ),
              borderWidth: 2,
            },
          ],
        },
        options: {
          indexAxis: "y",
          scales: {
            yAxis: {
              ticks: {
                color: $appTheme == "dark" ? "Gainsboro" : "grey",
                beginAtZero: true,
              },
              gridLines: {
                color: "rgba(136, 136, 136, 0.5)",
              },
            },
            xAxis: {
              ticks: {
                color: $appTheme == "dark" ? "Gainsboro" : "grey",
              },
              gridLines: {
                color: "rgba(136, 136, 136, 0.5)",
              },
            },
          },
          legend: {
            labels: {
              color: $appTheme == "dark" ? "Gainsboro" : "grey",
            },
          },
        },
      }
    );
  }

  $: if (isMounted && employeesJobsContributionData && employeesColors) {
    let datasets = [];

    for (const key in employeesJobsContributionData.data) {
      const element = employeesJobsContributionData.data[key];
      let dataObject = {
        label: key,
        data: element,
        backgroundColor: employeesColors.hasOwnProperty(key)
          ? hexToRgbaString(employeesColors[key]["second_color"], 0.05)
          : hexToRgbaString("#000000"),
        borderColor: employeesColors.hasOwnProperty(key)
          ? hexToRgbaString(
              employeesColors[key][
                $appTheme == "dark" ? "second_color" : "accent_color"
              ],
              0.9
            )
          : hexToRgbaString("#000000"),
        fill: true,
        lineTension: 0.4,
      };
      datasets.push(dataObject);
    }

    employeeContributionChart = new Chart(
      document.getElementById("employeeContributionChart"),
      {
        type: "CustomLineChart",
        data: {
          labels: employeesJobsContributionData.label,
          datasets: datasets,
        },
        options: {
          plugins: {
            tooltip: {
              mode: "index",
              intersect: false,
            },
          },
          scales: {
            yAxis: {
              ticks: {
                color: $appTheme == "dark" ? "Gainsboro" : "grey",
              },
              gridLines: {
                color: "rgba(136, 136, 136, 0.5)",
              },
            },
            xAxis: {
              ticks: {
                color: $appTheme == "dark" ? "Gainsboro" : "grey",
              },
              gridLines: {
                color: "rgba(136, 136, 136, 0.5)",
              },
            },
          },
          legend: {
            labels: {
              color: $appTheme == "dark" ? "Gainsboro" : "grey",
            },
          },
        },
      }
    );
  }
</script>

<svelte:head>
  <title>Revenue + Stats</title>
</svelte:head>

{#await fetchLastWeekCompare && fetchAvgWeeklyJobs && fetchAvgJobPrice && fetchClientsStatusInfo && fetchEmployeesJobContribution && fetchRevenueByCompany && fetchMonthlyRevenueDetails && fetchVendors && fetchVendorAverage}
  <Spinner />
{/await}

<!--Container-->
<div class="container w-full mx-auto pt-10">
  <div class="w-full px-4 md:px-0 mb-16 text-gray-800 leading-normal">
    {#if !isNaN(lastWeekCompare)}
      <p class="text-center mb-10 dark:text-truegray-50">
        We received
        <span
          class:text-green-500="{lastWeekCompare >= 0}"
          class:text-red-500="{lastWeekCompare < 0}">
          {Math.abs(lastWeekCompare)}
          {lastWeekCompare >= 0 ? "more" : "less"}
        </span>
        designs than the previous week.
      </p>
    {:else}
      <p class="text-center dark:text-truegray-50 mb-10">
        The current week is being recorded. Check back on Mondays and Fridays.
      </p>
    {/if}
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 xl:w-1/3 p-3">
        <!--Metric Card-->
        <div
          class="bg-white dark:bg-truegray-900 rounded-md shadow dark:shadow-blue p-2">
          <div class="flex flex-row items-center">
            <div class="flex-shrink pr-4">
              <img
                class="block h-16 w-16"
                src="/img/icons/{$appTheme}/job.svg"
                alt="Job logo" />
            </div>
            <div class="flex-1 text-center md:text-center">
              <h5 class="font-medium leading-tight text-gray-500 uppercase">
                Avg. jobs in a week
              </h5>
              <h3 class="font-semibold text-2xl dark:text-white">
                {avgWeeklyJobs}
                <span
                  class:text-green-500="{avgWeeklyJobs - olderAvgWeeklyJobs >=
                    0}"
                  class:text-red-500="{avgWeeklyJobs - olderAvgWeeklyJobs < 0}">
                  {avgWeeklyJobs - olderAvgWeeklyJobs >= 0 ? "▲" : "▼"}
                </span>
              </h3>
            </div>
          </div>
        </div>
        <!--/Metric Card-->
      </div>
      <div class="w-full md:w-1/2 xl:w-1/3 p-3">
        <!--Metric Card-->
        <div
          class="bg-white dark:bg-truegray-900 rounded-md shadow dark:shadow-blue p-2">
          <div class="flex flex-row items-center">
            <div class="flex-shrink pr-4">
              <img
                class="block h-16 w-16"
                src="/img/icons/{$appTheme}/money-flow.svg"
                alt="Avg price of a job" />
            </div>
            <div class="flex-1 text-center md:text-center">
              <h5 class="font-medium leading-tight text-gray-500 uppercase">
                Avg. price of a job
              </h5>
              <h3 class="font-semibold text-2xl dark:text-white">
                ${avgJobPrice}
                <span
                  class:text-green-500="{avgJobPrice - olderAvgJobPrice >= 0}"
                  class:text-red-500="{avgJobPrice - olderAvgJobPrice < 0}">
                  {avgJobPrice - olderAvgJobPrice >= 0 ? "▲" : "▼"}
                </span>
              </h3>
            </div>
          </div>
        </div>
        <!--/Metric Card-->
      </div>
      <div class="w-full md:w-1/2 xl:w-1/3 p-3">
        <!--Metric Card-->
        <div
          class="bg-white dark:bg-truegray-900 rounded-md shadow dark:shadow-blue p-2 h-20 overflow-auto
          fixed-size-card">
          <div class="flex flex-row items-center">
            <div class="flex-shrink pr-4">
              <img
                class="block h-16 w-16"
                src="/img/icons/{$appTheme}/winner.svg"
                alt="Jobs weekly" />
            </div>
            <div class="flex-1 text-center md:text-center">
              <h5 class="font-medium leading-tight text-gray-500 uppercase">
                Rep with most new clients
              </h5>
              {#if repWithMostNewClients}
                <h3 class="font-semibold text-2xl dark:text-white">
                  {#each repWithMostNewClients as rep}
                    {rep.label}:
                    <span class="text-green-500">
                      {rep.count}
                    </span>
                    <br />
                  {/each}
                </h3>
              {/if}
            </div>
          </div>
        </div>
        <!--/Metric Card-->
      </div>
    </div>

    <!--Divider-->
    <hr class="border-b-2 border-gray-400 dark:border-truegray-700 my-8 mx-4" />

    <div class="grid place-items-center">
      <Switch
        id="salesContributionSwitch"
        text="Currently showing revenue amount"
        offText="Currently showing design count"
        useAlternateColors="{true}"
        bind:checked="{salesContributionMode}" />
    </div>

    <div class="flex flex-row flex-wrap flex-grow mt-2">
      <div class="w-full md:w-1/3 p-2">
        <!--Graph Card-->
        <div
          class="bg-white dark:bg-truegray-900 rounded-md shadow dark:shadow-blue">
          <div
            class="px-5 py-3 border-b-2 border-gray-200 dark:border-truegray-700 bg-gray-100 dark:bg-truegray-800 font-medium rounded-md
              leading-tight text-gray-500 uppercase flex justify-between">
            <h5 class="uppercase text-gray-600">
              Current Month Sales Performance
            </h5>
            {salesContributionModeEnum[salesContributionMode] == "count"
              ? "🔢"
              : "💲"}
          </div>
          <div class="p-1">
            <canvas
              id="employeeContributionThisMonthChart"
              class="chartjs"
              width="undefined"
              height="undefined"></canvas>
          </div>
        </div>
        <!--/Graph Card-->

        <!--Graph Card-->
        <div
          class="bg-white dark:bg-truegray-900 rounded-md shadow dark:shadow-blue mt-5">
          <div
            class="px-5 py-3 border-b-2 border-gray-200 dark:border-truegray-700 bg-gray-100 dark:bg-truegray-800 font-medium rounded-md
              leading-tight text-gray-500 uppercase">
            <h5 class="uppercase text-gray-600">Revenue By Company</h5>
          </div>
          <div class="p-1">
            <canvas
              id="revenueByCompanyChart"
              class="chartjs"
              width="undefined"
              height="150px"></canvas>
          </div>
        </div>
      </div>

      <div class="w-full md:w-2/3 p-2">
        <!--Graph Card-->
        <div
          class="bg-white dark:bg-truegray-900 rounded-md shadow dark:shadow-blue">
          <div
            class="px-5 py-3 border-b-2 border-gray-200 dark:border-truegray-700 bg-gray-100 dark:bg-truegray-800 font-medium rounded-md
              leading-tight text-gray-500 uppercase flex justify-between">
            <h5 class="uppercase text-gray-600">
              Sales Contribution throughout the year
            </h5>
            {salesContributionModeEnum[salesContributionMode] == "count"
              ? "🔢"
              : "💲"}
          </div>
          <div class="p-5">
            <canvas
              id="employeeContributionChart"
              class="chartjs"
              width="undefined"
              height="undefined"></canvas>
          </div>
        </div>
        <!--/Graph Card-->
      </div>

      <div class="w-full p-2">
        <!--Graph Card-->
        <div
          class="bg-white dark:bg-truegray-900 rounded-md shadow dark:shadow-blue">
          <div
            class="px-5 py-3 border-b-2 border-gray-200 dark:border-truegray-700 bg-gray-100 dark:bg-truegray-800 font-medium rounded-md
              leading-tight text-gray-500 uppercase flex justify-between">
            <h5 class="uppercase text-gray-600">
              Monthly Revenue/Pending Chart
            </h5>

            {#if monthlyRevenueDetails}
              <p>
                <span class="dark:text-green-400 text-green-800">
                  {(
                    ([...monthlyRevenueDetails.data.pending].pop() * 100) /
                    [...monthlyRevenueDetails.data.revenue].pop()
                  ).toFixed(2)}%
                </span> collection done
              </p>
            {/if}
          </div>
          <div class="p-5">
            <canvas
              id="monthlyRevenueChart"
              class="chartjs"
              width="undefined"
              height="100px"></canvas>
          </div>
        </div>
        <!--/Graph Card-->
      </div>

      <div class="w-full md:w-1/3 xl:w-1/3 p-3">
        <!--Graph Card-->
        <div
          class="bg-white dark:bg-truegray-900 rounded-md shadow dark:shadow-blue">
          <div
            class="px-5 py-3 border-b-2 border-gray-200 dark:border-truegray-700 bg-gray-100 dark:bg-truegray-800 font-medium rounded-md
              leading-tight text-gray-500 uppercase flex justify-between">
            <h5 class="uppercase text-gray-600">Top Clients This Month</h5>
            {salesContributionModeEnum[salesContributionMode] == "count"
              ? "🔢"
              : "💲"}
          </div>
          <div class="p-1">
            <canvas
              id="topClientsChart"
              class="chartjs"
              width="undefined"
              height="undefined"></canvas>
          </div>
        </div>
        <!--/Graph Card-->
      </div>

      <div class="w-full md:w-1/3 xl:w-1/3 p-3">
        <!--Graph Card-->
        <div
          class="bg-white dark:bg-truegray-900 rounded-md shadow dark:shadow-blue">
          <div
            class="px-5 py-3 border-b-2 border-gray-200 dark:border-truegray-700 bg-gray-100 dark:bg-truegray-800 font-medium rounded-md
              leading-tight text-gray-500 uppercase">
            <h5 class="uppercase text-gray-600">Vendors Averages</h5>
          </div>
          <div class="p-1">
            <canvas
              id="vendorAverageChart"
              class="chartjs"
              width="undefined"
              height="undefined"></canvas>
          </div>
        </div>
        <!--/Graph Card-->
      </div>

      <div class="w-full md:w-1/3 xl:w-1/3 p-3">
        <!--Template Card-->
        <div
          class="bg-white dark:bg-truegray-900 rounded-md shadow dark:shadow-blue h-60 overflow-auto fixed-size-card">
          <div
            class="px-5 py-3 border-b-2 border-gray-200 dark:border-truegray-700 bg-gray-100 dark:bg-truegray-800 font-medium rounded-md
              leading-tight text-gray-500 uppercase">
            <h5 class="uppercase text-gray-600">Design Count Summary</h5>
          </div>
          <div class="p-1">
            {#if vendorsData}
              <div class="w-full md:w-3/4 p-2 mb-6 md:mb-0">
                <Select
                  items="{vendorsData}"
                  bind:selectedItemId="{vendorIdForSummary}"
                  placeholder="Select vendor" />
              </div>
            {/if}
            <div class="w-full md:w-3/4 px-2 pb-3 mb-6 md:mb-0">
              <Select
                items="{monthsData}"
                bind:selectedItemId="{monthAgoForSummary}"
                placeholder="Select month(s) ago" />
            </div>
            {#if vendorSummary}
              <p class="px-2">
                <span class="font-semibold dark:text-truegray-50">Vendor:</span>
                <span class="dark:text-truegray-50">{vendorSummary.name}</span>
                <br /><span class="font-semibold dark:text-truegray-50"
                  >Simple/Avg:</span>
                <span class="text-green-500">{vendorSummary.leftchests}</span>
                <br /><span class="font-semibold dark:text-truegray-50"
                  >Complicated/Fullback:</span>
                <span class="text-green-500">{vendorSummary.fullbacks} </span>
              </p>
            {/if}
            <p class="px-2">
              <span class="font-semibold dark:text-truegray-50"
                >Working days during this period:</span>
              <span class="text-green-500">{workingDays}</span>
            </p>
          </div>
        </div>
        <!--/Template Card-->
      </div>
    </div>

    <div class="flex content-center flex-wrap">
      <div class="w-1/3 p-2 h-20">
        <div
          class="bg-white h-64 overflow-auto
          fixed-size-card dark:bg-truegray-900 rounded-md shadow dark:shadow-blue">
          <div
            class="px-5 py-3 border-b-2 border-gray-200 dark:border-truegray-700 bg-gray-100 dark:bg-truegray-800 font-medium rounded-md
              leading-tight text-gray-500 uppercase">
            <h5 class="uppercase text-gray-600">New/Returning Clients 🎉</h5>
          </div>
          <div class="px-2">
            {#if newClients}
              {#each Object.keys(newClients) as employee}
                <li
                  class="text-left list-none text-lg dark:text-white py-2 font-semibold">
                  {employee}
                  <ul>
                    {#each newClients[employee] as client}
                      <li
                        class="list-disc flex flex-wrap text-base font-normal">
                        <p class="text-left w-3/4">
                          <span class="text-xs text-green-500">⬤</span>
                          {client.name}
                        </p>
                        <p class="text-right w-1/4">{client.value}</p>
                      </li>
                    {/each}
                  </ul>
                </li>
              {/each}
            {:else}
              <p class="p-2">No new clients this month</p>
            {/if}
          </div>
        </div>
      </div>
      <div class="w-1/3 p-2">
        <div
          class="bg-white h-64 overflow-auto
          fixed-size-card dark:bg-truegray-900 rounded-md shadow dark:shadow-blue">
          <div
            class="px-5 py-3 border-b-2 border-gray-200 dark:border-truegray-700 bg-gray-100 dark:bg-truegray-800 font-medium rounded-md
              leading-tight text-gray-500 uppercase">
            <h5 class="uppercase text-gray-600">Clients Taking Off ❌</h5>
          </div>
          <div class="px-2">
            {#if departedClients}
              {#each Object.keys(departedClients) as employee}
                <li
                  class="text-left list-none text-lg dark:text-white py-2 font-semibold">
                  {employee}
                  <ul>
                    {#each departedClients[employee] as client}
                      <li
                        class="list-disc flex flex-wrap text-base font-normal">
                        <p class="text-left w-3/4">
                          <span class="text-xs text-red-500">⬤</span>
                          {client.name}
                        </p>
                        <p class="text-right w-1/4">{client.value}</p>
                      </li>
                    {/each}
                  </ul>
                </li>
              {/each}
            {:else}
              <p class="p-2">No clients leaving this month</p>
            {/if}
          </div>
        </div>
      </div>
      <div class="w-1/3 p-2">
        <div
          class="bg-white h-64 overflow-auto
          fixed-size-card dark:bg-truegray-900 rounded-md shadow dark:shadow-blue">
          <div
            class="px-5 py-3 border-b-2 border-gray-200 dark:border-truegray-700 bg-gray-100 dark:bg-truegray-800 font-medium rounded-md
              leading-tight text-gray-500 uppercase">
            <h5 class="uppercase text-gray-600">
              Clients w/ slower business ⚠
            </h5>
          </div>
          <div class="px-2">
            {#if slowerClients}
              {#each Object.keys(slowerClients) as employee}
                <li
                  class="text-left list-none text-lg dark:text-white py-2 font-semibold">
                  {employee}
                  <ul>
                    {#each slowerClients[employee] as client}
                      <li
                        class="list-disc flex flex-wrap text-base font-normal">
                        <p class="text-left w-3/4">
                          <span class="text-xs text-orange-500">⬤</span>
                          {client.name}
                        </p>
                        <p class="text-right w-1/4">{client.value}</p>
                      </li>
                    {/each}
                  </ul>
                </li>
              {/each}
            {:else}
              <p class="p-2">No clients with slower business</p>
            {/if}
          </div>
        </div>
      </div>
      <div class="w-1/3 p-2">
        <div
          class="bg-white h-64 overflow-auto
          fixed-size-card dark:bg-truegray-900 rounded-md shadow dark:shadow-blue">
          <div
            class="px-5 py-3 border-b-2 border-gray-200 dark:border-truegray-700 bg-gray-100 dark:bg-truegray-800 font-medium rounded-md
              leading-tight text-gray-500 uppercase">
            <h5 class="uppercase text-gray-600">One-time Clients 👎</h5>
          </div>
          <div class="px-2">
            {#if oneTimeClients}
              {#each Object.keys(oneTimeClients) as employee}
                <li
                  class="text-left list-none text-lg dark:text-white py-2 font-semibold">
                  {employee}
                  <ul>
                    {#each oneTimeClients[employee] as client}
                      <li
                        class="list-disc flex flex-wrap text-base font-normal">
                        <p class="text-left w-3/4">
                          <span class="text-xs text-purple-500">⬤</span>
                          {client.name}
                        </p>
                      </li>
                    {/each}
                  </ul>
                </li>
              {/each}
            {:else}
              <p class="p-2">No one-time clients within one year! Woohoo 🎉</p>
            {/if}
          </div>
        </div>
      </div>
      <div class="w-1/3 p-2">
        <div
          class="bg-white h-64 overflow-auto
          fixed-size-card dark:bg-truegray-900 rounded-md shadow dark:shadow-blue">
          <div
            class="px-5 py-3 border-b-2 border-gray-200 dark:border-truegray-700 bg-gray-100 dark:bg-truegray-800 font-medium rounded-md
              leading-tight text-gray-500 uppercase">
            <h5 class="uppercase text-gray-600">Average turnaround time</h5>
          </div>
          <div class="px-2">
            {#if oneTimeClients}
              {#each Object.keys(oneTimeClients) as employee}
                <li
                  class="text-left list-none text-lg dark:text-white py-2 font-semibold">
                  {employee}
                  <ul>
                    {#each oneTimeClients[employee] as client}
                      <li
                        class="list-disc flex flex-wrap text-base font-normal">
                        <p class="text-left w-3/4">
                          <span class="text-xs text-purple-500">⬤</span>
                          {client.name}
                        </p>
                      </li>
                    {/each}
                  </ul>
                </li>
              {/each}
            {:else}
              <p class="p-2">No one-time clients within one year! Woohoo 🎉</p>
            {/if}
          </div>
        </div>
      </div>
    </div>

    <!--/ Console Content-->
  </div>
</div>

<!--/container-->
<style>

  ::-webkit-scrollbar {
    width: 0px;
    /* remove scrollbar space */
    background: transparent;
    /* optional: just make scrollbar invisible */
  }
</style>
