<script>
    import {onDestroy, onMount} from 'svelte';
    import Select from '../select/Select.svelte';
    import Spinner from '../Spinner.svelte';
    import Timeline from '../Timeline.svelte';
    import Textbox from './components/Textbox.svelte';
    import {fade, fly} from 'svelte/transition';
    import {getData} from '../../services/fetchData.js';
    import {
        clientsStore,
        perStitchClientsStore,
        pricesStore,
        sendMailonVendorChangeStore,
        vendorsStore,
    } from './stores/index2';
    import {getTextboxSupportedTypes, getTimeDifferenceFromNow,} from '../../helpers/functions';
    import Switch from '../Switch.svelte';

    export let submit;
    export let cancel = () => (showForm = false);

    export let sendMailToVendor = $sendMailonVendorChangeStore;
    const vendorSelectCustomIconClickHandler = (sendToggle) => {
        $sendMailonVendorChangeStore = sendToggle;
    };

    const mailSendSvgEmbed = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    stroke="black"
    fill="currentColor"
    viewBox="0 0 48 48">
      <path
        d="M24 25.29L4.02 14.5C4.28 11.42 6.86 9 10 9h28c3.14 0 5.72 2.42 5.98 5.5L24 25.29zM23.76 28.48C22.64 30.4 22 32.62 22 35c0 2.16.53 4.2 1.47 6H10c-3.31 0-6-2.69-6-6V17.89l19.29 10.43C23.44 28.4 23.6 28.46 23.76 28.48zM44 17.89v7.74c-2.04-1.97-4.73-3.28-7.72-3.56L44 17.89zM46 35c0 6.075-4.925 11-11 11s-11-4.925-11-11 4.925-11 11-11S46 28.925 46 35zM42.707 35.293l-3-3c-.391-.391-1.023-.391-1.414 0s-.391 1.023 0 1.414L39.586 35H31.17c-1.14 0-2.112-.849-2.167-1.892-.029-.556.165-1.083.546-1.484C29.931 31.222 30.446 31 31 31c1.103 0 2 .897 2 2 0 .553.448 1 1 1s1-.447 1-1c0-2.206-1.794-4-4-4-1.092 0-2.15.454-2.902 1.247-.763.804-1.15 1.857-1.092 2.967C27.117 35.337 28.946 37 31.17 37h8.416l-1.293 1.293c-.391.391-.391 1.023 0 1.414C38.488 39.902 38.744 40 39 40s.512-.098.707-.293l3-3C43.098 36.316 43.098 35.684 42.707 35.293z">
      </path>
  </svg>`;

    export let showForm = false;
    export let rush, superRush;
    export let title = 'Form';
    export let orderId;
    export let orderName;
    export let suggestedPrice;
    export let selectedDate;
    export let pctAmountPaid;
    export let isDesignFromServer;
    export let orderStatus;
    export let isPPTPrice;

    export let chipMessage;
    export let actionSuccess = false;

    let forceAllDirty = false;

    export let disableActionButton = false;

    let price,
        isPriceTypeSetExplicitly = false;

    let fetchClients, fetchVendors, fetchPrices, fetchPerStitchClients;
    let revisionData;
    let bwdRfc;
    let fwdRfc;
    let isMounted;

    const priceTypeEnum = {flatrate: '💲', ppts: '🧵'};
    Object.freeze(priceTypeEnum);
    let priceType;

    $: isPPTPrice = priceType == priceTypeEnum.ppts;

    $: clientsData = $clientsStore;
    $: vendorsData = $vendorsStore;
    $: pricesData = $pricesStore;
    $: perStitchClientsData = $perStitchClientsStore;

    $: isPPTClient = Object.keys(perStitchClientsData).includes(selectedClient);

    $: if (isPPTClient && !isPriceTypeSetExplicitly) {
        if (suggestedPrice > 1000 || price > 1000) priceType = priceTypeEnum.ppts;
        else priceType = priceTypeEnum.flatrate;

        if (
            (suggestedPrice && suggestedPrice.toString().includes('.')) ||
            (price && price.toString().includes('.'))
        )
            priceType = priceTypeEnum.flatrate;
        else priceType = priceTypeEnum.ppts;
    } else if (!isPriceTypeSetExplicitly) {
        priceType = priceTypeEnum.flatrate;
    }

    $: if (orderName.toLowerCase().includes('fullback') && isNaN(price)) {
        suggestedPrice = ' Price';
    }

    $: if (!isPriceEmpty) {
        suggestedPrice = price;
    } else if (
        !isClientEmpty &&
        pricesData &&
        selectedClient in pricesData &&
        !orderName.toLowerCase().includes('fullback') &&
        title !== 'Edit Order'
    ) {
        suggestedPrice = pricesData[selectedClient];
    } else if (title !== 'Edit Order') {
        suggestedPrice = ' Price';
    }

    if (title === 'Edit Order') {
        price = suggestedPrice;
    }

    // form validation
    export let isFormInvalid;

    let isOrderNameDirty = false;
    let isPriceDirty = false;
    let isPctAmountPaidDirty = false;
    $: isOrderNameEmpty = orderName.length < 1 ? true : false;
    $: isPriceEmpty = (!price || price.length < 1) && price != 0 ? true : false;
    $: isPctValid = pctAmountPaid < 0 || pctAmountPaid > 100 ? false : true;

    let isClientSelectDirty = false;
    let isVendorSelectDirty = false;

    $: isClientEmpty = selectedClient == null ? true : false;

    $: isVendorEmpty = selectedVendor == null ? true : false;

    $: isFormInvalid =
        isOrderNameEmpty || isNaN(suggestedPrice) || isClientEmpty || isVendorEmpty;

    $: if (isFormInvalid && forceAllDirty) {
        isOrderNameDirty = true;
        isPriceDirty = true;
    }

    $: isPatch = title === 'Edit Order' && /patch|piece|pcs/i.test(orderName);

    // validation portion complete

    const fetchRevisionData = async () => {
        if (!isMounted || !orderId) return;
        const fetchRevision = await getData(
            `https://test.dev/revision/read/${orderId}`
        );

        revisionData = await fetchRevision.json();
    };

    const fetchRfcMsgId = async () => {
        if (!isMounted || !orderId || !isDesignFromServer) return;
        const fetchRfcMsg = await getData(
            `https://test.dev/order/getRfcMsgId/${orderId}/true`
        );

        const rfc = await fetchRfcMsg.json();
        bwdRfc = rfc?.bwd?.replaceAll("+", "%2B");
        fwdRfc = rfc?.fwd?.replaceAll("+", "%2B");
    };

    const forceSuggest = async () => {
        getData(`https://test.dev/order/forceSuggest/${orderId}`);
        chipMessage = 'Design will be available for Send in a bit.';
        actionSuccess = true;
        cancel();
    };

    const fetchData = async () => {
        if (
            isMounted &&
            $clientsStore.length === 0 &&
            $vendorsStore.length === 0 &&
            $pricesStore.length === 0
        ) {
            fetchClients = getData('https://test.dev/entity/read/client');
            fetchVendors = getData('https://test.dev/entity/read/vendor/true');
            fetchPrices = getData('https://test.dev/client/price');
            fetchPerStitchClients = getData(
                'https://test.dev/client/variablePricing'
            );

            [fetchClients, fetchVendors, fetchPrices, fetchPerStitchClients] =
                await Promise.all([
                    fetchClients,
                    fetchVendors,
                    fetchPrices,
                    fetchPerStitchClients,
                ]);

            $clientsStore = await fetchClients.json();
            $vendorsStore = await fetchVendors.json();
            $pricesStore = await fetchPrices.json();
            $perStitchClientsStore = await fetchPerStitchClients.json();
        }
        await fetchRevisionData();
        fetchRfcMsgId();
    };

    export let selectedClient;

    export let selectedVendor;

    const handleKeydown = (e) => {
        if (e.key == 'Escape') {
            cancel();
        } else if (e.key == 'Enter' && e.ctrlKey) {
            isClientSelectDirty = true;
            isPriceDirty = true;
            isOrderNameDirty = true;
            isVendorSelectDirty = true;
            submit();
        } else if (e.key.toLowerCase() == 's' && e.altKey) {
            if (priceType == priceTypeEnum.flatrate) priceType = priceTypeEnum.ppts;
            else priceType = priceTypeEnum.flatrate;

            isPriceTypeSetExplicitly = true;
        }
    };

    const showPopup = url => {
        const h = 640;
        const w = 920;
        const y = window.outerHeight / 2 + window.screenY - (h / 2);
        const x = window.outerWidth / 2 + window.screenX - (w / 2);
        const newWindow = window.open(url, '', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + y + ', left=' + x);
        if (window.focus) {
            newWindow.focus()
        }
        return false;
    }

    onMount(() => {
        isMounted = true;
        fetchData();
    });

    onDestroy(() => {
        isMounted = false;
    });
</script>

<svelte:window on:keydown="{handleKeydown}"/>

{#await fetchClients && fetchPrices && fetchVendors}
    <Spinner/>
{/await}

<form class="container mx-auto my-48 w-full max-w-lg">
    <div>
        <h2 class="text-2xl dark:text-white my-6 font-semibold leading-tight">
            {title}
        </h2>
    </div>
    <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full md:w-2/3 px-3 mb-6 md:mb-0">
            <Textbox
                    bind:isTextboxDirty="{isOrderNameDirty}"
                    bind:text="{orderName}"
                    isAutoFocus="{true}"
                    placeholder="Order Name"
                    showError="{isOrderNameEmpty}"/>
        </div>
        {#if title === 'Edit Order'}
            <div class="w-full md:w-1/3 px-3">
                <input
                        class="appearance-none block w-full text-gray-700 dark:text-truegray-300 border border-black-900 shadow dark:shadow-blue rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-truegray-800 dark:bg-truegray-900 focus:shadow-lg border-none dark:focus:text-white focus:text-black"
                        bind:value="{selectedDate}"/>
            </div>
        {/if}
    </div>
    <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full md:w-2/3 px-3 mb-6 md:mb-0">
            <Select
                    bind:selectedItemId="{selectedClient}"
                    discouragedItemHint="Deprec"
                    items="{clientsData}"
                    on:focus="{() => (isClientSelectDirty = true)}"
                    placeholder="Select Client"/>
            {#if isClientSelectDirty && isClientEmpty}
                <p class="text-red-500 text-xs italic">Please fill out this field.</p>
            {/if}
        </div>
        <div class="w-full md:w-1/3 px-3">
            <label data-type="{priceType}">
                <Textbox
                        additionalClasses="pl-8"
                        bind:isTextboxDirty="{isPriceDirty}"
                        bind:text="{price}"
                        placeholder="${suggestedPrice}"
                        showError="{isPriceEmpty && isNaN(suggestedPrice)}"
                        type="{getTextboxSupportedTypes().number}"/>
            </label>
        </div>
    </div>
    <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full md:w-2/3 px-3 mb-6 md:mb-0">
            <Select
                    bind:customIconToggle="{sendMailToVendor}"
                    bind:selectedItemId="{selectedVendor}"
                    customIconClickHandler="{vendorSelectCustomIconClickHandler}"
                    customIconSvgEmbed="{mailSendSvgEmbed}"
                    items="{vendorsData}"
                    on:focus="{() => (isVendorSelectDirty = true)}"
                    placeholder="Select Vendor"/>
            {#if isVendorSelectDirty && isVendorEmpty}
                <p class="text-red-500 text-xs italic">Please fill out this field.</p>
            {/if}
        </div>
        <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <div class="flex flex-wrap my-1 mb-2">
                <div class="w-1/2">
                    <Switch bind:checked="{rush}" id="rushSwitch" text="Rush"/>
                </div>
                {#if rush}
                    <div class="w-1/2">
                        <Switch
                                bind:checked="{superRush}"
                                id="superRushSwitch"
                                text="Super"/>
                    </div>
                {/if}
            </div>
        </div>
    </div>
    {#if title === 'Edit Order' && isPatch}
        <div class="flex flex-wrap -mx-3 mb-2">
            <div class="w-full px-3 mb-6 md:mb-0">
                <input
                        bind:value="{pctAmountPaid}"
                        class="appearance-none block w-full text-gray-700 dark:text-truegray-300 border border-black-900 shadow dark:shadow-blue rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-truegray-800 dark:bg-truegray-900 focus:shadow-lg border-none dark:focus:text-white focus:text-black"
                        type="number"
                        placeholder="0% paid"
                        on:focus="{() => (isPctAmountPaidDirty = true)}"/>
                {#if isPctAmountPaidDirty && !isPctValid}
                    <p class="text-red-500 text-xs italic">
                        Please enter a percentage greater than 0 and less than or equal to 100
                    </p>
                {/if}
            </div>
        </div>
    {/if}
    <div
            class="px-5 py-5 border-t dark:border-truegray-700 flex flex-col xs:flex-row items-center
  xs:justify-between">
        <div class="flex flex-wrap">
            <div class="w-full md:w-2/3 px-3">
                {#if bwdRfc}
                    <div class="text-red-600 h-6 w-6 cursor-pointer" on:click={showPopup(bwdRfc)} in:fade>
                        <svg xmlns="http://www.w3.org/2000/svg"
                             stroke="white"
                             fill="currentColor"
                             viewBox="0 0 20 20">
                            <path fill="none"
                                  d="M9.634,10.633c0.116,0.113,0.265,0.168,0.414,0.168c0.153,0,0.308-0.06,0.422-0.177l4.015-4.111c0.229-0.235,0.225-0.608-0.009-0.836c-0.232-0.229-0.606-0.222-0.836,0.009l-3.604,3.689L6.35,5.772C6.115,5.543,5.744,5.55,5.514,5.781C5.285,6.015,5.29,6.39,5.522,6.617L9.634,10.633z"></path>
                            <path fill="none"
                                  d="M17.737,9.815c-0.327,0-0.592,0.265-0.592,0.591v2.903H2.855v-2.903c0-0.327-0.264-0.591-0.591-0.591c-0.327,0-0.591,0.265-0.591,0.591V13.9c0,0.328,0.264,0.592,0.591,0.592h15.473c0.327,0,0.591-0.264,0.591-0.592v-3.494C18.328,10.08,18.064,9.815,17.737,9.815z"></path>
                        </svg>
                    </div>
                {/if}
            </div>
            <div class="w-full md:w-1/3 px-3">
                {#if fwdRfc}
                    <div class="text-red-600 h-6 w-6 cursor-pointer" on:click={showPopup(fwdRfc)} in:fade>
                        <svg xmlns="http://www.w3.org/2000/svg"
                             stroke="white"
                             fill="currentColor"
                             viewBox="0 0 20 20">
                            <path fill="none"
                                  d="M16.198,10.896c-0.252,0-0.455,0.203-0.455,0.455v2.396c0,0.626-0.511,1.137-1.138,1.137H5.117c-0.627,0-1.138-0.511-1.138-1.137V7.852c0-0.626,0.511-1.137,1.138-1.137h5.315c0.252,0,0.456-0.203,0.456-0.455c0-0.251-0.204-0.455-0.456-0.455H5.117c-1.129,0-2.049,0.918-2.049,2.047v5.894c0,1.129,0.92,2.048,2.049,2.048h9.488c1.129,0,2.048-0.919,2.048-2.048v-2.396C16.653,11.099,16.45,10.896,16.198,10.896z"></path>
                            <path fill="none"
                                  d="M14.053,4.279c-0.207-0.135-0.492-0.079-0.63,0.133c-0.137,0.211-0.077,0.493,0.134,0.63l1.65,1.073c-4.115,0.62-5.705,4.891-5.774,5.082c-0.084,0.236,0.038,0.495,0.274,0.581c0.052,0.019,0.103,0.027,0.154,0.027c0.186,0,0.361-0.115,0.429-0.301c0.014-0.042,1.538-4.023,5.238-4.482l-1.172,1.799c-0.137,0.21-0.077,0.492,0.134,0.629c0.076,0.05,0.163,0.074,0.248,0.074c0.148,0,0.294-0.073,0.382-0.207l1.738-2.671c0.066-0.101,0.09-0.224,0.064-0.343c-0.025-0.118-0.096-0.221-0.197-0.287L14.053,4.279z"></path>
                        </svg>
                    </div>
                {/if}
            </div>
        </div>
        <div class="mt-3 px-2 xs:mt-0 items-center">
            <button
                    class="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold
          py-2 px-4 rounded-l shadow dark:shadow-blue"
                    class:cursor-not-allowed="{disableActionButton}"
                    disabled="{disableActionButton}"
                    on:click="{() => {
          submit();
          forceAllDirty = true;
        }}"
                    type="button">
                {title.split(' ')[0]}
            </button>
            <button
                    class="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold
          py-2 px-4 rounded-r shadow dark:shadow-blue"
                    on:click="{cancel}"
                    type="button">
                Cancel
            </button>
            {#if title == 'Edit Order' && isDesignFromServer && orderStatus.includes('pending') && getTimeDifferenceFromNow(new Date(Date.parse(selectedDate)))['ms'] > 1800000}
                <button
                        class="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold
          py-2 px-4 rounded-r shadow dark:shadow-blue"
                        on:click="{forceSuggest}"
                        type="button">
                    Force Suggest
                </button>
            {/if}
        </div>
    </div>

    {#if revisionData && title == 'Edit Order'}
        <div class="flex mb-6" in:fly>
            <div class="w-full">
                <Timeline revisionData="{revisionData}" orderId="{orderId}"/>
            </div>
        </div>
    {/if}
</form>

<style>
    button:disabled,
    button[disabled] {
        background-color: #cccccc;
        color: #666666;
    }

    label,
    input {
        position: relative;
        display: block;
        box-sizing: border-box;
    }

    label::after {
        content: attr(data-type);
        position: absolute;
        top: 10px;
        left: 8px;
        display: block;
        @apply text-truegray-600;
    }
</style>
