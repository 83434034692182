import { LineController } from 'chart.js';

class CustomLineChart extends LineController {
    draw() {
        super.draw();

        if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
            let activePoint = this.chart.tooltip._active[0],
                ctx = this.chart.ctx,
                x = activePoint.element.x,
                topY = this.chart.scales.yAxis.top,
                bottomY = this.chart.scales.yAxis.bottom;

            // Render line on canvas
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(x, bottomY);
            ctx.lineTo(x, topY);
            ctx.lineWidth = 1;
            ctx.strokeStyle = 'rgba(180, 180, 180, 0.3)';
            ctx.stroke();
            ctx.restore();
        }
    }
}

CustomLineChart.id = 'CustomLineChart';
CustomLineChart.defaults = CustomLineChart.defaults;

export default CustomLineChart;

/*
To create CustomLineChart:
Chart.register(
  ...
  Tooltip,
  CustomLineChart
);
new Chart(element, {
  type: 'NwLineChart',
    data: {...},
    options: {...}
});
  
*/