<script>
    import {onMount} from 'svelte';
    import {Link, navigate, Route, Router} from 'svelte-routing';
    import {AccordionItem} from 'svelte-accessible-accordion';
    import {getData} from '../services/fetchData.js';
    import {submitData} from '../services/postData.js';
    import {calculateReadingTime} from '../helpers/functions';
    import Document from '../components/Document.svelte';
    import Uploader from '../components/ChunkedUploader.svelte';
    import Tab from '../components/Tab.svelte';
    import {fly} from 'svelte/transition';
    import {linear} from 'svelte/easing';
    import Accordion from '../components/Accordion.svelte';
    import DocumentSelector from '../components/DocumentSelector.svelte';

    let isMounted;

    export let showSpinner;
    $: showSpinner = fetchList && fetchData;

    export let chipMessage;
    export let mildError,
        severeError,
        infoMsg,
        warnMsg,
        actionSuccess,
        internalSuccess = false;

    let fetchList,
        listData = [],
        fetchData,
        data,
        uploadedFileNames = [],
        attachedFileNames = [],
        attachedFileNamesFromServer = [],
        dataId;

    let openTab = 1;

    let fileUploadUrl = 'https://test.dev/email/upload';
    let previewUrl = 'https://test.dev/email/getAttachmentPreview';

    let showSaveBtn = false,
        showAddBtn = true;

    let articleBody;
    let articleHeading;
    let emailChildrenTemplates = [];

    $: pageTitle = openTab == 1 ? 'Scripts' : 'Email Templates';

    const fetchScriptsList = async () => {
        if (isMounted) {
            fetchList = getData('https://test.dev/script/read');
            [fetchList] = await Promise.all([fetchList]);
            listData = await fetchList.json();
        }
    };

    const fetchEmailTemplatesList = async () => {
        if (isMounted) {
            fetchList = getData('https://test.dev/email/read');
            [fetchList] = await Promise.all([fetchList]);
            listData = await fetchList.json();
        }
    };

    const fetchScriptData = async (id) => {
        if (isMounted) {
            navigate('write');
            fetchData = getData(`https://test.dev/script/read/${id}`);
            [fetchData] = await Promise.all([fetchData]);
            data = await fetchData.json();
            articleHeading = data.title;
            articleBody = data.content;
            dataId = id;
        }
    };

    const addChildTemplate = (id, i, attachedFiles = null) => {
        const obj = {
            id: id,
            articleBody: null,
            articleHeading: `Child template #${i + 1}`,
            expanded: false,
            files: {
                uuid: id,
                uploadedFileNames: [],
                attachedFileNames: [],
                attachedFileNamesFromServer: attachedFiles ?? [],
            },
        };
        emailChildrenTemplates.push(obj);
        emailChildrenTemplates = [...emailChildrenTemplates];
    };

    const fetchEmailTemplateData = async (id, isChild = false) => {
        if (isMounted) {
            navigate('write');
            fetchData = getData(`https://test.dev/email/read/${id}`);
            [fetchData] = await Promise.all([fetchData]);
            data = await fetchData.json();
            if (isChild) {
                emailChildrenTemplates.forEach((template, i) => {
                    if (template.id != id) return;

                    template.articleBody = data.content;
                    template.files.attachedFileNamesFromServer = data?.attachedFileNames;
                });
                emailChildrenTemplates = [...emailChildrenTemplates];
                restart();
            } else {
                emailChildrenTemplates = [];
                articleHeading = data.title;
                articleBody = data.content;
                attachedFileNamesFromServer = data?.attachedFileNames;
                dataId = id;
            }

            if (data.childrenTemplateIds && !isChild) {
                data.childrenTemplateIds.forEach((id, i) =>
                    addChildTemplate(id, i, data?.attachedFileNames)
                );
                emailChildrenTemplates = [...emailChildrenTemplates];
            }
        }
    };

    const createScript = () => {
        articleHeading = '';
        articleBody = '';
        uploadedFileNames = [];
        attachedFileNames = [];
        attachedFileNamesFromServer = [];
        dataId = null;
        showSaveBtn = true;
        showAddBtn = false;
    };

    const handleSaveResponse = response => {
        if (response.ok) {
            chipMessage = 'Saved successfully';
            actionSuccess = true;
        } else {
            chipMessage = 'Unable to save. You may not be authorized.';
            mildError = true;
        }
    }

    const handleScriptSave = async () => {
        const postURL = dataId
            ? 'https://test.dev/script/update'
            : 'https://test.dev/script/create';
        const response = await submitData(postURL, {
            id: dataId,
            title: articleHeading,
            content: articleBody,
        });
        handleSaveResponse(response);
        fetchScriptsList();
        showAddBtn = true;
    };

    const handleEmailTemplateSave = async (templateId) => {
        const postURL = templateId
            ? 'https://test.dev/email/update'
            : 'https://test.dev/email/write';
        const response = await submitData(postURL, prepareBody(templateId, dataId != templateId));
        handleSaveResponse(response);
        fetchEmailTemplatesList();
        showAddBtn = true;
    };

    const prepareBody = (id, isChild) => {
        if (isChild) {
            const template = emailChildrenTemplates.find(temp => temp.id == id);
            return {
                id: id,
                title: template.articleHeading,
                content: template.articleBody,
                parentId: dataId,
            };
        }
        return {
            id: id,
            title: articleHeading,
            content: articleBody
        }
    }

    const handleKeydown = (e) => {
        if (e.key === 'Escape') {
            createScript();
        }
    };

    const toggleTabs = (tab) => {
        dataId = null;
        openTab = tab;
        switch (openTab) {
            case 1:
                fetchScriptsList();
                break;

            case 2:
                fetchEmailTemplatesList();
                break;
            default:
                break;
        }
    };

    onMount(() => {
        isMounted = true;
        fetchScriptsList();
    });

    let unique = {};

    function restart() {
        unique = {}; // every {} is unique, {} === {} evaluates to false
    }
</script>

<svelte:head>
    <title>{pageTitle}</title>
</svelte:head>

<svelte:window on:keydown="{handleKeydown}"/>


<div class="container mx-auto px-8">
    <div class="flex flex-wrap">
        <div class="w-full">
            <Tab
                    bind:openTab
                    tabsData="{[
          { title: 'Call Scripts', id: 1 },
          { title: 'Email Templates', id: 2 },
        ]}"
                    toggleTabs="{toggleTabs}"/>
        </div>
    </div>
</div>

<Router>
    <Link to="write"/>
    <Link to="read"/>
    <Route path="read">
        {#if openTab == 1}
            <div
                    class="container mx-auto px-8"
                    in:fly="{{ x: -300, easing: linear, delay: 100, duration: 300 }}"
                    out:fly="{{ x: 1000, duration: 500 }}">
                <div class="w-full border-t dark:border-truegray-700 flex flex-col">
                    <main
                            class="w-full flex-grow p-6 container mx-auto ml-16 px-4 sm:px-8">
                        <div class="flex flex-wrap">
                            {#each listData as item}
                                <div
                                        on:click="{() => fetchScriptData(item.id)}"
                                        class="relative shadow dark:shadow-blue md:w-5/12 m-2 bg-truegray-200 dark:bg-truegray-800 block p-4 overflow-hidden rounded-lg cursor-pointer">
                                    <div class="justify-between sm:flex">
                                        <div>
                                            <h5 class="text-xl font-bold text-gray-100">
                                                {item.title}
                                            </h5>
                                            <p class="mt-1 text-xs font-medium text-gray-400">
                                                By admin
                                            </p>
                                        </div>
                                    </div>

                                    <div class="mt-4 sm:pr-8">
                                        <p class="text-sm text-gray-200">
                                            {item.content}...
                                        </p>
                                    </div>

                                    <dl class="flex mt-6">
                                        {#if item.date}
                                            <div class="flex flex-col-reverse">
                                                <dt class="text-sm font-medium text-gray-400">
                                                    Published
                                                </dt>
                                                <dd class="text-xs text-gray-300">
                                                    {new Date(Date.parse(item.date))
                                                        .toISOString()
                                                        .substring(0, 10)}
                                                </dd>
                                            </div>
                                        {/if}

                                        <div class="flex flex-col-reverse ml-3 sm:ml-6">
                                            <dt class="text-sm font-medium text-gray-400">
                                                Reading time
                                            </dt>
                                            <dd class="text-xs text-gray-300">
                                                {calculateReadingTime(item.content)} minutes
                                            </dd>
                                        </div>
                                    </dl>
                                </div>
                            {/each}
                        </div>
                    </main>
                </div>
            </div>
        {:else if openTab == 2}
            <div
                    class="container mx-auto px-8"
                    in:fly="{{ x: -300, easing: linear, delay: 100, duration: 300 }}"
                    out:fly="{{ x: 1000, duration: 500 }}">
                <div class="w-full border-t dark:border-truegray-700 flex flex-col">
                    <main
                            class="w-full flex-grow p-6 container mx-auto ml-16 px-4 sm:px-8">
                        <div class="flex flex-wrap">
                            {#each listData as item}
                                <div
                                        on:click="{() => fetchEmailTemplateData(item.id)}"
                                        class="relative shadow dark:shadow-blue md:w-5/12 m-2 bg-truegray-200 dark:bg-truegray-800 block p-4 overflow-hidden rounded-lg cursor-pointer">
                                    <div class="justify-between sm:flex">
                                        <div>
                                            <h5 class="text-xl font-bold text-gray-100">
                                                {item.title}
                                            </h5>
                                            <p class="mt-1 text-xs font-medium text-gray-400">
                                                By admin
                                            </p>
                                        </div>
                                    </div>

                                    <div class="mt-4 sm:pr-8">
                                        <p class="text-sm text-gray-200">
                                            {item.content}...
                                        </p>
                                    </div>

                                    <dl class="flex mt-6">
                                        <div class="flex flex-col-reverse">
                                            <dt class="text-sm font-medium text-gray-400">
                                                Published
                                            </dt>
                                            {#if item.date}
                                                <dd class="text-xs text-gray-300">
                                                    {new Date(Date.parse(item.date))
                                                        .toISOString()
                                                        .substring(0, 10)}
                                                </dd>
                                            {/if}
                                        </div>

                                        <div class="flex flex-col-reverse ml-3 sm:ml-6">
                                            <dt class="text-sm font-medium text-gray-400">
                                                Reading time
                                            </dt>
                                            <dd class="text-xs text-gray-300">
                                                {calculateReadingTime(item.content)} minutes
                                            </dd>
                                        </div>
                                    </dl>
                                </div>
                            {/each}
                        </div>
                    </main>
                </div>
            </div>
        {/if}
    </Route>

    <Route path="write">
        {#if openTab == 1}
            <DocumentSelector
                    listData="{listData}"
                    showAddBtn="{showAddBtn}"
                    fetchData="{fetchScriptData}"
                    create="{createScript}"/>

            <Document
                    handleSave="{handleScriptSave}"
                    showSaveBtn="{showSaveBtn}"
                    placeholder="Script Name"
                    bind:articleBody
                    bind:articleHeading/>
        {:else if openTab == 2}
            <DocumentSelector
                    listData="{listData}"
                    showAddBtn="{showAddBtn}"
                    fetchData="{fetchEmailTemplateData}"
                    create="{createScript}"/>

            <Document
                    handleSave="{() => handleEmailTemplateSave(dataId)}"
                    showCustomBtn="{true}"
                    customBtnTitle="Add Child"
                    customBtnAction="{() =>
          addChildTemplate(null, emailChildrenTemplates.length)}"
                    showSaveBtn="{showSaveBtn}"
                    placeholder="Email Subject"
                    bind:articleBody
                    bind:articleHeading/>

            <div class="container px-36 pt-4 pb-10">
                <Uploader
                        uuid="{dataId}"
                        fileUploadUrl="{fileUploadUrl}"
                        bind:attachedFileNamesFromServer
                        previewUrl="{previewUrl}"
                        bind:uploadedFileNames
                        bind:attachedFileNames/>
            </div>
            <div class="container mx-auto max-w-screen-lg">
                <Accordion>
                    {#each emailChildrenTemplates as template}
                        <AccordionItem
                                title="{template.articleHeading}"
                                bind:expanded="{template.expanded}"
                                on:click="{() => {
                fetchEmailTemplateData(template.id, true);
              }}">
                            <Document
                                    handleSave="{() => handleEmailTemplateSave(template.id)}"
                                    showSaveBtn="{true}"
                                    hideHeadingInput="{true}"
                                    placeholder="Email Template Name"
                                    bind:articleHeading="{template.articleHeading}"
                                    bind:articleBody="{template.articleBody}"/>
                            <div class="container">
                                {#key unique}
                                    <Uploader
                                            bind:uuid="{template.files.uuid}"
                                            bind:uploadedFileNames="{template.files.uploadedFileNames}"
                                            bind:attachedFileNames="{template.files.attachedFileNames}"
                                            bind:attachedFileNamesFromServer="{template.files
                      .attachedFileNamesFromServer}"
                                            isAttachmentDataServerFetched="{true}"
                                            previewUrl="{previewUrl}"
                                            fileUploadUrl="{fileUploadUrl}"/>
                                {/key}
                            </div>
                        </AccordionItem>
                    {/each}
                </Accordion>
            </div>
        {/if}
    </Route>
</Router>
