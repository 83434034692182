<script>
  import {onMount} from "svelte";
  import {getData} from "../services/fetchData.js";
  import {submitData} from "../services/postData.js";
  import Datatable from "../components/datatable/DataTable.svelte";
  import Select from "../components/select/Select.svelte";
  import columns from "../components/datatable/column-data/vendor-verification";

  let value = "Paste a column from an excel file";
  let fetchOrders, fetchVendors, ordersData, vendorData;
  let selectedVendor = 2;
  let monthAgoForVerification = 2;

  export let showSpinner;
$: showSpinner = fetchOrders || fetchVendors; 

  export let chipMessage;
  export let mildError,
    severeError,
    infoMsg,
    warnMsg,
    actionSuccess,
    internalSuccess = false;

  const monthsData = [
    { value: 2, label: "Last Month" },
    { value: 3, label: "2 months ago" },
    { value: 4, label: "3 months ago" },
    { value: 5, label: "4 months ago" },
    { value: 6, label: "5 months ago" },
  ];

  const fetchData = async () => {
    fetchVendors = await getData("https://test.dev/entity/read/vendor");
    vendorData = await fetchVendors.json();
  };

  const submit = async () => {
    chipMessage = "Results would be returned in a bit.";
    actionSuccess = true;
    fetchOrders = await submitData("https://test.dev/verification/read", {
      content: value,
      vendorid: selectedVendor,
      monthAgo: monthAgoForVerification,
    });
    ordersData = await fetchOrders.json();
  };

  onMount(() => {
    fetchData();
  });
</script>

<svelte:head>
  <title>Vendor Verification</title>
</svelte:head>




<div class="flex container">
  <div class="p-4 pt-10">
    <textarea bind:value class="h-96 w-96 rounded p-1"></textarea>

    <div class="flex flex-wrap my-6">
      <div class="w-1/3 mb-6 md:mb-0">
        <Select
                items="{vendorData}"
                bind:selectedItemId="{selectedVendor}"
                placeholder="Select Vendor"/>
      </div>
      <div class="w-1/3 px-1">
        <Select
                items="{monthsData}"
                bind:selectedItemId="{monthAgoForVerification}"
          placeholder="Select month(s) ago" />
      </div>
      <button
        class="text-sm bg-gray-300 border-none hover:bg-gray-400 text-gray-800 font-semibold py-2 mt-1 px-4 shadow dark:shadow-blue rounded-r rounded-l"
        on:click="{submit}">Check</button>
    </div>
  </div>
  {#if ordersData}
    <div class="flex-1">
      <Datatable
        columns="{columns}"
        rows="{ordersData}"
        process="local"
        title="Vendor Verifications" />
    </div>
  {/if}
</div>
