export const submitData = async (postURL, body) => {
  if (typeof postURL === 'undefined') {
    console.log('no postURL given');
    return;
  }
  const response = fetch(postURL, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: JSON.stringify(body),
  });
  return response;
};