import { writable } from "../../helpers/localStorageStore.js";

export let avgWeeklyJobsStore = writable("avgWeeklyJobsStore", 0);
export let mostClientsRepStore = writable("mostClientsRepStore", 0);
export let avgJobPriceStore = writable("avgJobPriceStore", 0);
export let vendorAvgStore = writable("vendorAvgStore", 0);
export let oneTimeClientsStore = writable("oneTimeClientsStore", 0);
export let vendorStore = writable("vendorStore", 0);
export let employeeColorsStore = writable("employeeColorsStore", 0);
export let lastWeekCompareStore = writable("lastWeekCompareStore", 0);
export let lastCachedDateStore = writable("lastCachedDateStore", 0);
export let employeeDataStore = writable("employeeDataStore", 0);