<script>
  import { onMount } from 'svelte';
  import { getData } from '../services/fetchData.js';
  import { submitData } from '../services/postData.js';
  import Chip from '../components/Chip.svelte';
  import Quill from 'quill';
  import Spinner from '../components/Spinner.svelte';
  import Tab from '../components/Tab.svelte';

  let fetchContentsResponse;
  
  export let showSpinner;
  $: showSpinner = fetchContentsResponse;

  export let chipMessage;
  export let mildError,
    severeError,
    infoMsg,
    warnMsg,
    actionSuccess,
    internalSuccess = false;

  let changes = false;
  let timesChanged = 0;
  let disableSave = false;
  let caretPositions = [0, 0, 0, 0];
  let openTab = 1;
  let quill;
  let change;
  const toolbarOptions = [
    ['save', 'load'],
    ['bold', 'italic', 'underline', 'strike'],

    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }], // superscript/subscript

    [{ size: ['small', false, 'large', 'huge'] }],

    [{ color: [] }, { background: [] }],
    [{ align: [] }],

    ['clean'], // remove formatting button
  ];

  const tabsData = [
    {
      title: 'Leads 1',
      id: 1,
    },
    {
      title: 'Leads 2',
      id: 2,
    },
    {
      title: 'Leads 3',
      id: 3,
    },
  ];

  $: pageTitle = `Leads: Page ${openTab}`;

  const saveCaretPosition = (tabNumber) => {
    if (!quill) {
      return;
    }
    tabNumber = tabNumber || 0;
    caretPositions[tabNumber] = quill.getSelection(true);
  };
  const loadCaretPosition = (tabNumber) => {
    if (!quill) {
      return;
    }
    tabNumber = tabNumber || 0;
    quill.setSelection(caretPositions[tabNumber]);
  };

  const saveLeads = async () => {
    if (disableSave || timesChanged < 1) {
      chipMessage = 'Leads not saved. Perform some changes first.';
      warnMsg = true;
      return false;
    }
    let contents = JSON.stringify(quill.getContents());
    if (!contents.trim()) {
      chipMessage = 'Leads not saved. Perform some changes first.';
      warnMsg = true;
      disableSave = true;
      return false;
    }
    const response = await submitData('https://test.dev/leads/write', {
      pageNum: openTab,
      content: contents,
    });
    if (response.ok) {
      chipMessage = 'Leads saved succesfully';
      actionSuccess = true;
    } else {
      chipMessage = 'Something went wrong. Please save again!';
      severeError = true;
    }
  };

  const loadLeads = async () => {
    fetchContentsResponse = getData(`https://test.dev/leads/read/${openTab}`);
    [fetchContentsResponse] = await Promise.all([fetchContentsResponse]);
    if (fetchContentsResponse.ok) {
      const contentsData = await fetchContentsResponse.json();
      quill.setContents(JSON.parse(contentsData));
      quill.history.clear(); // this line prevents undo to clear editor
      timesChanged = 0;
      quill.focus();
      return true;
    } else {
      chipMessage = "Something went wrong and we couldn't load your contents.";
      mildError = true;
      return false;
    }
  };

  const destroyCurrentEditorToolbar = (currentTab) => {
    const toolbar = document.querySelector(`#editor${currentTab}`);

    if (!toolbar) return false;

    toolbar.parentNode.removeChild(
      toolbar.parentNode.getElementsByClassName('ql-toolbar ql-snow')[0]
    );
    toolbar.parentNode.getElementsByClassName('ql-editor')[0].remove();
  };

  const toggleTabs = async (tabNumber) => {
    if (quill) {
      if (tabNumber == openTab) return false;

      saveCaretPosition(openTab);
      destroyCurrentEditorToolbar(openTab);
    }

    openTab = tabNumber;
    quill = new Quill(`#editor${tabNumber}`, {
      modules: {
        toolbar: {
          container: toolbarOptions,
          handlers: {
            save: saveLeads,
            load: loadLeads,
          },
        },
      },
      placeholder: 'Type something...',
      theme: 'snow', // or 'bubble'
      scrollingContainer: 'html, body',
    });

    quill.on('text-change', function (delta) {
      change = change.compose(delta);
      if (timesChanged > 1) {
        changes = true;
        disableSave = false;
      }
      timesChanged++;
    });

    quill.keyboard.addBinding({
      key: 'S',
      ctrlKey: true,
      handler: saveLeads,
    });

    const isLoaded = await loadLeads();
    if (isLoaded) {
      loadCaretPosition(tabNumber);
    }
  };

  const truncateText = (event) => {
    event.preventDefault();

    let selectedText = window.getSelection().toString();

    if (event.type == 'cut') {
      const selectedTextRange = quill.getSelection();
      quill.deleteText(selectedTextRange.index, selectedTextRange.length);
    }

    let clipboardData =
      event.clipboardData ||
      window.clipboardData ||
      event.originalEvent.clipboardData;

    clipboardData.setData('Text', selectedText.slice(0, 100));
  };

  const evaluateUndo = (event) => {
    // for some reason it isn't actually preventing undo
    if (
      (event.ctrlKey || event.metaKey) &&
      String.fromCharCode(event.which).toLowerCase() == 'z' &&
      !changes
    ) {
      event.preventDefault();
      disableSave = true;
      console.log('undo was prevented');
      // return false;
    }
  };

  // document.addEventListener("visibilitychange", function () {
  //   if (!document.hidden) {
  //     loadCaretPosition();
  //   } else {
  //     saveCaretPosition();
  //   }
  // });

  onMount(() => {
    const Delta = Quill.import('delta');
    change = new Delta();

    toggleTabs(1);

    // Save periodically
    setInterval(function () {
      if (change.length() > 0) {
        saveLeads();
        change = new Delta();
      }
    }, 5 * 1000);
  });
</script>

<svelte:head>
  <link href="//cdn.quilljs.com/1.3.6/quill.snow.css" rel="stylesheet" />
  <title>{pageTitle}</title>
</svelte:head>


<div class="container mx-auto px-8 py-8">
  <div class="flex flex-wrap">
    <div class="w-full">
      <Tab
        tabsData="{tabsData}"
        toggleTabs="{toggleTabs}"
        openTab="{openTab}" />
      <div
        class="relative flex flex-col min-w-0 break-words bg-white dark:bg-truegray-900 w-full mb-6 shadow-lg dark:shadow-blue-lg rounded">
        <div class="px-4 py-5 flex-auto">
          <div class="tab-content tab-space">
            <div class="{openTab === 1 ? 'block' : 'hidden'}">
              <div
                id="editor1"
                class="editor text-black dark:text-truegray-50"
                on:keydown="{(e) => evaluateUndo(e)}">
              </div>
            </div>
            <div class="{openTab === 2 ? 'block' : 'hidden'}">
              <div
                id="editor2"
                class="editor text-black dark:text-truegray-50"
                on:keydown="{(e) => evaluateUndo(e)}">
              </div>
            </div>
            <div class="{openTab === 3 ? 'block' : 'hidden'}">
              <div
                id="editor3"
                class="editor text-black dark:text-truegray-50"
                on:keydown="{(e) => evaluateUndo(e)}">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  .editor {
    @apply h-screen shadow dark:shadow-blue border-none rounded;
  }
</style>
