<script>
  import { onMount, onDestroy } from "svelte";
  import Spinner from "../Spinner.svelte";
  import { submitData } from "../../services/postData.js";
  import { usernameStore } from "./stores/index";
  import { get } from "svelte/store";

  let reset = () => {
    if (password1 !== password2) return;
    submitData("https://test.dev/user/resetPassword", {
      selector: selector,
      token: token,
      username: get(usernameStore),
      password: password1,
    });
  };
  export let cancel;

  let password2 = "";
  let password1 = "";

  let isPasswordMatching;

  let ispassword2Dirty = false,
    ispassword1Dirty = false;

  let selector = "",
    token = "";

  $: isPasswordMatching = password1 == password2;

  const title = "Password Reset";

  onMount(() => {
    let url = new URL(decodeURIComponent(window.location.href));

    selector = url.searchParams.get("selector");
    token = url.searchParams.get("token");
  });
</script>

<form class="container mx-auto py-48 w-full max-w-lg">
  <div>
    <h2 class="text-2xl dark:text-white my-6 font-semibold leading-tight">
      {title}
    </h2>
  </div>
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <input
        bind:value="{password2}"
        class="appearance-none block w-full text-gray-900 dark:text-truegray-50 border
        border-black-900 dark:border-blue-600 rounded py-3 px-4 leading-tight focus:outline-none
        focus:bg-white dark:focus:bg-truegray-900 dark:bg-truegray-900 focus:border-blue-600"
        type="password"
        placeholder="Password"
        on:focus="{() => (ispassword2Dirty = true)}" />
    </div>
    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <input
        bind:value="{password1}"
        class="appearance-none block w-full text-gray-900 dark:text-truegray-50 border
        border-black-900 dark:border-blue-600 rounded py-3 px-4 leading-tight focus:outline-none
        focus:bg-white dark:focus:bg-truegray-900 dark:bg-truegray-900 focus:border-blue-600"
        type="password"
        placeholder="Retype password"
        on:focus="{() => (ispassword1Dirty = true)}" />
    </div>
    <div class="items-center pl-56 pt-2 justify-center">
      {#if ispassword2Dirty && ispassword1Dirty && !isPasswordMatching}
        <p class="text-red-500 text-xs italic">Passwords do not match!</p>
      {/if}
    </div>
  </div>
  <div
    class="px-5 py-5 bg-white dark:bg-truegray-900 border-t dark:border-truegray-700 flex flex-col xs:flex-row items-center
    xs:justify-between ">
    <div class="mt-2 px-2 xs:mt-0 items-center">
      <button
        class="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold
        py-2 px-4 rounded-l shadow dark:shadow-blue"
        on:click="{reset}"
        type="button">
        Reset
      </button>
      <button
        class="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold
        py-2 px-4 rounded-r shadow dark:shadow-blue"
        on:click="{cancel}"
        type="button">
        Cancel
      </button>
    </div>
  </div>
</form>
