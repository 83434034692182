<script>
  import { onMount, onDestroy } from "svelte";
  import { getData } from "../services/fetchData.js";
  import { submitData } from "../services/postData.js";
  import { Router, Link, Route } from "svelte-routing";
  import Chip from "../components/Chip.svelte";
  import columns from "../components/datatable/column-data/design-verification.js";
  import Datatable from "../components/datatable/DataTable.svelte";
  import Spinner from "../components/Spinner.svelte";
  import { appTheme } from "../stores/index";
  import { getMonthName } from "../helpers/functions";

  let isMounted;
  export let showSpinner;
  $: showSpinner = fetchReport;

 export let chipMessage;
 export let mildError,
    severeError,
    infoMsg,
    warnMsg,
    actionSuccess,
    internalSuccess = false;

  let company;
  let tableTitle;
  let sortable = false;

  let fetchReport;
  let reportData;

  let companyToName = {
    bww: "Buffalo",
    itec: "iTec",
    thread: "Thread",
  };

  $: pageTitle = `Design Verifications: ${companyToName[company]}`;

  const setcompany = () => {
    if (typeof window !== "undefined") {
      let url = window.location.href.toString();
      company = url.substring(url.lastIndexOf("/") + 1);
      const now = new Date();
      if (company in companyToName)
        tableTitle =
          companyToName[company] +
          `'s Design Verification of ${getMonthName(
            now.getDate() <= 5 ? now.getMonth() - 1 : now.getMonth()
          )} ${now.getFullYear()}`;
      else if (company === "all")
        tableTitle = "Total Collection Across Companies";
      else tableTitle = "Pending Collections Across Companies";
    }
  };

  const fetchData = async () => {
    if (isMounted) {
      fetchReport = getData(
        "https://test.dev/email/readDesignEmailVerificationReport/" + company
      );
      [fetchReport] = await Promise.all([fetchReport]);
      reportData = await fetchReport.json();
    }
  };

  const labelUnmatchedEmails = async () => {
    if (isMounted) {
      const response = await getData(
        "https://test.dev/email/labelUnmatchedEmails/" + company
      );

      if (response.ok) {
        chipMessage = "Status updated";
        actionSuccess = true;
        fetchData();
      }
    }
  };

  const handleRowClick = (e) => {
    let elementName = e.detail.target.name;
    if (!elementName) {
      elementName = e.detail.target.getAttribute("name");
    }
    switch (elementName) {
      case "unverifyDesignBtn":
        (async () => {
          const response = await submitData(
            "https://test.dev/email/markDesignVerificationIncorrect",
            {
              id: e.detail.row.id,
            }
          );

          if (response.ok) {
            chipMessage = "Status updated";
            actionSuccess = true;
            fetchData();
          }
        })();

        break;
    }
  };

  onMount(() => {
    isMounted = true;
    setcompany();
    fetchData();
  });

  onDestroy(() => {
    isMounted = false;
  });
</script>

<svelte:head>
  <title>{pageTitle}</title>
</svelte:head>

<div>
  <Router>
    <Link to="bww" />
    <Link to="itec" />
    <Link to="thread" />

    <Route path="bww">
      <p class="hidden">{setcompany()} {fetchData()}</p>
    </Route>
    <Route path="itec">
      <p class="hidden">{setcompany()} {fetchData()}</p>
    </Route>
    <Route path="thread">
      <p class="hidden">{setcompany()} {fetchData()}</p>
    </Route>
  </Router>

  

  

  <div>
    <Datatable
      sortable="{sortable}"
      title="{tableTitle}"
      rows="{reportData}"
      columns="{columns}"
      process="local"
      on:row-click="{handleRowClick}">
      <div slot="action-button">
        <button class="border-none rounded-r" on:click="{labelUnmatchedEmails}">
          <img
            class="h-10 w-10"
            src="/img/icons/{$appTheme}/label.svg"
            alt="" />
        </button>
      </div>
    </Datatable>
  </div>
</div>
