import { appTheme } from "../../../stores/index";
import {get } from "svelte/store";

export default [
    // Array of objects
    {
        label: "Name", // Column name
        field: "name", // Field name from row
        numeric: false, // Affects sorting
        html: false, // Escapes output if false.
    },
    {
        label: "Price",
        field: function(data) {
            return "$" + data.price;
        },
        numeric: false,
        html: true,
    },
    {
        label: "Client",
        field: "clientname",
        numeric: false,
        html: false,
    },
    {
        label: "Vendor",
        field: "vendorname",
        numeric: false,
        html: false,
    },
    {
        label: "Time",
        field: "date",
        numeric: false,
        html: false,
    },
    {
        label: "Status",
        field: function(data) {
            let result = data.statusInfo;
            if (result == "Send") result = 'Approved';
            return (
                '<button name="statusChangeBtn" class="relative border-none">' +
                result +
                "</button>"
            );
        },
        numeric: false,
        html: true,
    },
    {
        label: "Notes",
        field: function(data) {
            const isDisabled = data.ssc != 1;
            let iconName = "note";

            if (data.notes) iconName += "-filled";
            else if (isDisabled) iconName += "-disabled";

            return (
                `<div>
                    <button class="border-none ${isDisabled ? 'cursor-not-allowed' : ''}" disabled=${isDisabled}>
                        <img class="h-10 w-10" name="orderNotesBtn" src="/img/icons/${get(appTheme)}/${iconName}.png"/>
                    </button>
                </div>`
            );
        },
        numeric: false,
        html: true,
    },
];