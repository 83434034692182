export default [
    // Array of objects
    {
        label: "id", // Column name
        field: "id", // Field name from row
        numeric: true, // Affects sorting
        html: false, // Escapes output if false.
    },
    {
        label: "Frequency", // Column name
        field: function (data) {
            return `Every ${data.frequency}`;
        }, // Field name from row
        numeric: false, // Affects sorting
        html: false, // Escapes output if false.
    },
    {
        label: "Time Interval",
        field: function (data) {
            switch (data.time_interval) {
                case "d":
                    return "Day";
                case "w":
                    return "Week";
                case "m":
                    return "Month";
            }
        },
        numeric: false,
        html: false,
    },
    {
        label: "Week Day",
        field: function (data) {
            switch (data.weekday) {
                case "1":
                    return "Monday";
                case "2":
                    return "Tuesday";
                case "3":
                    return "Wednesday";
                case "4":
                    return "Thursday";
                case "5":
                    return "Friday";
                case "6":
                    return "Saturday";
                case "7":
                    return "Sunday";
            }
        },
        numeric: false,
        html: false,
    },
    {
        label: "Part of Day",
        field: function (data) {
            switch (data.part_of_day) {
                case "m":
                    return "Morning";
                case "a":
                    return "Afternoon";
                case "e":
                    return "Evening";
            }
        },
        numeric: false,
        html: false,
    },
    {
        label: "Template",
        field: "title",
        numeric: false,
        html: false,
    },
    
    
];