import {writable} from "svelte/store";

export const employeesStore = writable([]);
export const companiesStore = writable([]);
export const payMethodsStore = writable([]);

export const clientsStore = writable([]);
export const perStitchClientsStore = writable({});
export const vendorsStore = writable([]);
export const pricesStore = writable([]);
export const emailTemplatesStore = writable([]);

export let sendMailonVendorChangeStore = writable(0);

export let emergencyPasswordHashStore = writable(null);