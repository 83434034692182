<script>
  import { linear } from "svelte/easing";
  import { fly } from "svelte/transition";
</script>

<div
  in:fly="{{ x: -300, easing: linear, delay: 100, duration: 300 }}"
  out:fly="{{ x: 1000, duration: 500 }}">
  <slot />
</div>
