<script>
  import {onDestroy, onMount} from 'svelte';
  import Datatable from '../components/datatable/DataTable.svelte';
  import SimpleOrderForm from '../components/forms/OrderForm.svelte';
  import DetailedOrderForm from '../components/forms/DetailedOrderForm.svelte';
  import SendForm from '../components/forms/BackwardSendOrderForm.svelte';
  import BackwardSendOrderForm from '../components/forms/BackwardSendOrderForm.svelte';
  import {getData} from '../services/fetchData.js';
  import {submitData} from '../services/postData.js';
  import {appTheme} from '../stores/index';
  import columns from '../components/datatable/column-data/orders.js';
  import {generateUuid, getApprovalStatusColumnIcons, getOrdersTableStatusColumnIcons,} from '../helpers/functions';

  let showForm = false;
  let Form;

  let allowDrag = false;
  let draggable = true;

  let orderId;
  let orderName;
  let price;
  let pctAmountPaid;
  let selectedClient;
  let selectedVendor;
  let selectedDate;
  let rush, superRush;
  let sendMailToVendor;
  let isDesignFromServer;
  let orderStatus;
  let emailBody;
  let emergencyMode;
  let emailSubjectAddendum = '';
  let setApprovalWarning = false;

  let isPPTPrice;

  let uuid, bwdThreadId, revFwdMsgId;
  let attachedFileNames = [],
    uploadedFileNames = [];

  let isOrderFetchedFromServer = false;

  let updateInterval;

  const now = new Date().getHours();

  export let numberOfOrders;
  
  export let showSpinner;
  $: showSpinner = fetchOrdersResponse && fetchNumberOfOrdersTodayResponse;

  
  let fetchOrdersResponse, fetchNumberOfOrdersTodayResponse;
  let ordersData;
  let isMounted;
  
  let formTitle = 'Add Order';
  let postURL;
  
  let isFormInvalid;
  let disableActionButton = false;
  
  let body;
  
  let doUpdate;
  let lastFetchedOrdersDate;
  
  let hasPreviousAttachmentBeenUploaded = true;
  
  $: isFormReady =
  Form !== DetailedOrderForm ||
  (Form === DetailedOrderForm && hasPreviousAttachmentBeenUploaded);
  
  

  export let chipMessage;
  export let chipDuration = 200;
  export let mildError,
  severeError,
    infoMsg,
    warnMsg,
    actionSuccess,
    internalSuccess = false;

  let formDataOnHold = [];

  let bwdEmailData;
  let bwdEmailSubject,
    clientName,
    clientEmail = [],
    isClientDefaulter = false,
    bwdOrderAttachmentNames = {};

  let selectedCompany;

  $: if (formDataOnHold.length > 0) {
    for (let i = 0; i < formDataOnHold.length; i++) {
      const data = formDataOnHold[i];
      console.log('data is now being sent');
      hasPreviousAttachmentBeenUploaded = true;
      (async function () {
        const response = await submitData(data.postURL, data.body);
        if (response.ok) formDataOnHold.splice(i, 1);
        handleResponse(response, 'Upload finished and design sent');
      })();
    }
  }

  $: if (showForm && !isFormReady) {
    chipMessage =
      'Previous order is currently uploading. Please wait a few seconds.';
    warnMsg = true;
  }
  $: if (uuid && uuid.split('-').length - 1 == 4) {
    isOrderFetchedFromServer = false;
  } else {
    isOrderFetchedFromServer = true;
  }

  const clearFormInputs = () => {
    if (!showForm) {
      orderName = '';
      price = '';
      orderId = null;
      rush = false;
      superRush = false;
      emailBody = 'Loading';
      bwdEmailSubject = 'Loading';
      clientName = 'Loading';
      clientEmail = [];
      bwdOrderAttachmentNames = {};
      selectedVendor = null;
      isClientDefaulter = false;
    }
    orderId = null;
    emailSubjectAddendum = '';
    uuid = '';
    bwdThreadId = '';
    selectedCompany = null;
    uploadedFileNames = [];
    isOrderFetchedFromServer = false;
  };

  const prepareBody = () => {
    body = {
      name: orderName,
      price: price,
      clientid:
        Form === BackwardSendOrderForm ? bwdEmailData.clientid : selectedClient,
      vendorid: selectedVendor,
      date:
        typeof selectedDate !== 'undefined'
          ? selectedDate.toLocaleString('en-US', { timeZone: 'Asia/Karachi' })
          : null,
      pctAmountPaid: pctAmountPaid ? pctAmountPaid : null,
      rush: rush,
      superRush: superRush,
      emailBody: emailBody === 'Loading' ? '' : emailBody,
      emailSubjectAddendum: rush
        ? `${emailSubjectAddendum} - RUSH `
        : emailSubjectAddendum,
      id: orderId,
      uuid: uuid,
      bwd_threadid: bwdThreadId,
      attachmentNames: attachedFileNames,
      bwdAttachmentNames: bwdOrderAttachmentNames,
      emailSubject: bwdEmailSubject,
      clientEmails: clientEmail,
      isOrderFetchedFromServer: isOrderFetchedFromServer,
      companyid: selectedCompany,
      isPPTPrice: isPPTPrice,
      sendMailToVendor: sendMailToVendor,
    };
  };

  const updateLastFetchedOrdersDate = () => {
    const now = new Date();
    lastFetchedOrdersDate = `${now.getFullYear()}-${
      now.getMonth() + 1
    }-${now.getDate()} ${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`;
  };

  const fetchData = async () => {
    if (isMounted && !allowDrag && !showForm) {
      fetchOrdersResponse = await submitData('https://test.dev/order/read', {
        date: lastFetchedOrdersDate,
        doUpdate: doUpdate,
      });

      if (fetchOrdersResponse.status != 204) {
        fetchNumberOfOrdersTodayResponse = getData(
          'https://test.dev/statistics/getTotalDesignsReceivedToday'
        );
        doUpdate = false;
        updateLastFetchedOrdersDate();
        [fetchNumberOfOrdersTodayResponse, fetchOrdersResponse] =
          await Promise.all([
            fetchNumberOfOrdersTodayResponse,
            fetchOrdersResponse,
          ]);
        numberOfOrders = await fetchNumberOfOrdersTodayResponse.json();
        ordersData = await fetchOrdersResponse.json();
      }
    }
  };

  const handleKeydown = (e) => {
    if (e.key === 'Escape') {
      cancel();
    } else if ((e.key === 'a' || e.key === 'A' || e.key === '.') && e.altKey) {
      handleNewOrder(DetailedOrderForm);
    } else if (e.key === 'r' && e.altKey) {
      doUpdate = true;
      fetchData();
    }
  };

  function handleResponse(response, msg = 'Successfully added/edited') {
    disableActionButton = false;
    if (response.ok) {
      fetchData();
      chipMessage = msg;
      actionSuccess = true;
      showForm = false;
      clearFormInputs();
    } else {
      showForm = true;
      chipMessage = 'Something went wrong. Please submit order again';
      mildError = true;
    }
  }

  const submit = async () => {
    if ((isFormInvalid || disableActionButton) && Form != SendForm || emergencyMode) return;
    if (Form == BackwardSendOrderForm && isClientDefaulter) {
      const shouldSend = confirm(
        `This client has previously pending invoices. Are you sure you'd like to send them their design?`
      );
      if (!shouldSend) {
        cancel();
        return false;
      }
    }
    disableActionButton = true;
    prepareBody();
    if (isMounted) {
      const response = await submitData(postURL, body);
      if (Form === BackwardSendOrderForm) {
        handleResponse(response, 'Design sent successfully');
        return;
      }
      let errMsg = 'Successfully added/edited';
      if (
        Form == DetailedOrderForm &&
        (!bwdThreadId || !uuid || !isOrderFetchedFromServer)
      )
        errMsg = 'Design not available for backward send';
      handleResponse(response, errMsg);
    }
  };

  const markDesignCompleted = () => {
    postURL = `https://test.dev/order/markDesignComplete`;
    doUpdate = true;
    (async () => {
      const response = await submitData(postURL, {
        id: orderId,
      });
      if (response.ok) {
        showForm = false;
        clearFormInputs();
        chipMessage = 'Status updated';
        actionSuccess = true;
        fetchData();
      }
    })();
  };

  function cancel() {
    showForm = false;
    if (formTitle === 'Edit Order') {
      clearFormInputs();
    }
    uploadedFileNames = [];
    selectedCompany = null;
  }

  const handleRowClick = (e) => {
    let elementName = e.detail.target.name;
    if (!elementName) {
      elementName = e.detail.target.getAttribute('name');
    }
    switch (elementName) {
      case 'editOrderBtn':
        disableActionButton = false;
        orderName = e.detail.row.name;
        orderId = e.detail.row.designid;
        price = e.detail.row.price;
        selectedClient = e.detail.row.clientid;
        selectedVendor = e.detail.row.vendorid;
        selectedDate = e.detail.row.date;
        pctAmountPaid = e.detail.row.pctpaid;
        superRush = e.detail.row.statusInfo === 'Super Rush';
        rush = e.detail.row.statusInfo === 'Rush' || superRush;
        formTitle = 'Edit Order';
        showForm = true;
        Form = SimpleOrderForm;
        isDesignFromServer = e.detail.row.fwdsent != -1;
        orderStatus = e.detail.row.statusDesc;
        postURL = 'https://test.dev/order/update';
        doUpdate = true;
        break;

      case 'statusChangeBtn':
        if (
          ['Send', 'Approval Pending', 'Revision Approval'].includes(
            e.detail.row.statusInfo
          )
        ) {
          setApprovalWarning = [
            'Approval Pending',
            'Revision Approval',
          ].includes(e.detail.row.statusInfo);
          Form = SendForm;
          clearFormInputs();
          showForm = true;
          postURL = 'https://test.dev/order/getDesignBackwardEmailContent';
          orderId = e.detail.row.designid;
          (async () => {
            const response = await getData(`${postURL}/${orderId}`);
            if (response.ok) {
              bwdEmailData = await response.json();

              emailBody = bwdEmailData.emailBody;
              bwdEmailSubject = bwdEmailData.emailSubject;
              clientName = bwdEmailData.clientname;
              clientEmail = bwdEmailData.email;
              isClientDefaulter = bwdEmailData.isClientDefaulter;
              bwdOrderAttachmentNames = bwdEmailData?.attachmentNames ?? {};
              uuid = bwdEmailData.fwd_threadid;
              bwdThreadId = bwdEmailData.bwd_threadId;
              revFwdMsgId = bwdEmailData.revFwdMsgId;
              orderName = bwdEmailData.name;
              emergencyMode = bwdEmailData.emergencymode == 1 ? true : false;
              postURL = 'https://test.dev/order/markCompleteAndEmail';
            } else {
              chipMessage =
                'Something went wrong. Try again after cache downloads the attachments';
              mildError = true;
              return;
            }
          })();
          break;
        }
        const statusType =
          e.detail.row.revisionCount > 0 ? 'revision' : 'design';
        postURL = `https://test.dev/order/toggleStatus/${statusType}`;
        doUpdate = true;
        (async () => {
          const response = await submitData(postURL, {
            id: e.detail.row.designid,
          });
          if (response.ok) {
            chipMessage = 'Status updated';
            actionSuccess = true;
            fetchData();
          }
        })();

        break;

      case 'deleteOrderBtn':
        const shouldDelete = confirm(
          `Are you sure you want to delete: ${e.detail.row.name}`
        );
        if (!shouldDelete) {
          return false;
        }
        postURL = 'https://test.dev/order/delete';
        doUpdate = true;
        (async () => {
          const response = await submitData(postURL, {
            id: e.detail.row.designid,
          });
          if (response.ok) {
            chipMessage = 'Successfully deleted';
            warnMsg = true;
            fetchData();
          }
        })();

        break;

      default:
        break;
    }
  };

  const handleNewOrder = (selectedForm) => {
    formTitle = 'Add Order';
    Form = selectedForm;
    showForm = true;
    postURL =
      Form === SimpleOrderForm
        ? 'https://test.dev/order/create'
        : 'https://test.dev/order/createAndEmail';
    disableActionButton = false;
    uuid = generateUuid();
  };

  onMount(() => {
    isMounted = true;
    doUpdate = true;
    updateLastFetchedOrdersDate();
    fetchData();
    clearFormInputs();
    if ('process.env' != 'dev') updateInterval = setInterval(fetchData, 5000);
  });

  onDestroy(() => {
    isMounted = false;
  });

  document.addEventListener('visibilitychange', function () {
    if ('process.env' == 'dev') return false;
    if (!document.hidden) {
      fetchData();
      updateInterval = setInterval(fetchData, 5000);
    } else {
      clearInterval(updateInterval);
    }
  });

  $: if (!allowDrag) {
    fetchData();
  }
</script>

<svelte:head>
  <title>Orders</title>
</svelte:head>

<div>



  <div
    class:show="{!(showForm && isFormReady)}"
    class:hide="{showForm && isFormReady}">
    <Datatable
      bind:allowDrag
      draggable="{draggable}"
      title="Orders"
      rows="{ordersData}"
      columns="{columns}"
      process="local"
      hidden="{showForm}"
      on:row-click="{handleRowClick}"
      columnToCustomize="status"
      customizedTargetColor="{{
        ...getOrdersTableStatusColumnIcons(),
        ...getApprovalStatusColumnIcons(),
      }}">
      <p slot="info" class="dark:text-truegray-50">
        We received
        <span
          class="text-green-500"
          class:loading="{'calculating' === numberOfOrders}"
          >{numberOfOrders}</span>
        orders today.
      </p>
      <div slot="action-button">
        <a href="/all-orders" title="All Orders">
          <button class="border-none rounded-l">
            <img
              class="h-10 w-10"
              src="/img/icons/{$appTheme}/all-orders.svg"
              alt="" />
          </button>
        </a>
        <button
          class="border-none rounded-l"
          title="Refresh"
          on:click="{() => {
            doUpdate = true;
            fetchData();
          }}">
          <img
            class="h-10 w-10"
            src="/img/icons/{$appTheme}/refresh.svg"
            alt="" />
        </button>
        <button
          class="border-none rounded-l"
          title="Reorder"
          on:click="{() => {
            allowDrag = !allowDrag;
          }}">
          <img
            class="h-10 w-10"
            src="/img/icons/{$appTheme}/vertical{allowDrag ? '-red' : ''}.svg"
            alt="" />
        </button>
        {#if now > 7 && now < 18}
          <button
            class="border-none rounded-l"
            title="Sync with Gmail"
            on:click="{() =>
              getData('https://test.dev/email/checkCompletedForwardDesigns')}">
            <img
              class="h-10 w-10"
              src="/img/icons/{$appTheme}/email-download.png"
              alt="" />
          </button>
        {/if}
        <button
          class="border-none rounded-l"
          title="Add new order"
          on:click="{() => handleNewOrder(SimpleOrderForm)}">
          <img class="h-10 w-10" src="/img/icons/{$appTheme}/plus.svg" alt="" />
        </button>
        <button
          class="border-none rounded-r"
          title="Add & send order"
          on:click="{() => handleNewOrder(DetailedOrderForm)}">
          <img
            class="h-10 w-10"
            src="/img/icons/{$appTheme}/attach.png"
            alt="" />
        </button>
      </div>
    </Datatable>
  </div>
  {#if showForm && isFormReady}
    <div class="form">
      <Form
        bind:orderName
        bind:suggestedPrice="{price}"
        bind:selectedCompany
        bind:selectedClient
        bind:selectedVendor
        bind:rush
        bind:sendMailToVendor
        bind:superRush
        bind:isFormInvalid
        bind:selectedDate
        bind:pctAmountPaid
        bind:disableActionButton
        bind:emailBody
        bind:emailSubjectAddendum
        bind:emergencyMode
        setApprovalWarning="{setApprovalWarning}"
        bind:revFwdMsgId
        bind:uuid
        bind:bwdThreadId
        bind:clientName
        bind:clientEmail
        bind:orderAttachmentNames="{bwdOrderAttachmentNames}"
        bind:attachedFileNames
        bind:uploadedFileNames
        bind:bwdEmailSubject
        bind:isClientDefaulter
        bind:isOrderFetchedFromServer
        bind:isPPTPrice
        bind:mildError
        bind:severeError
        bind:infoMsg
        bind:warnMsg
        bind:actionSuccess
        bind:internalSuccess
        bind:chipMessage
        isDesignFromServer="{isDesignFromServer}"
        orderStatus="{orderStatus}"
        markDesignCompleted="{markDesignCompleted}"
        orderId="{orderId}"
        submit="{submit}"
        cancel="{cancel}"
        bind:showForm
        title="{formTitle}" />
    </div>
  {/if}
</div>
<svelte:window on:keydown="{handleKeydown}" />

<style>
  .hide {
    position: absolute;
    opacity: 0;
    display: none;
  }

  .show {
    position: static;
    opacity: 1;
    display: block;
  }

  .form {
    opacity: 0;
    animation: fadeInUp 0.5s ease 0s forwards;
  }

  /* defines the animation */
  @keyframes fadeInUp {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 50%, 0);
      transform: translate3d(0, 50%, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
</style>
