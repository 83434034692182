<script>
    import Textbox from "./components/Textbox.svelte";
    import {onMount} from "svelte";
    import {emailTemplatesStore} from "./stores/index2";
    import {getData} from "../../services/fetchData";
    import Select from "../select/Select.svelte";
    import {submitData} from "../../services/postData";

    export let title = "Send Email Template";
    export let email = "";
    export let isFormInvalid;

    let emailTemplates = [];
    let isEmailDirty = false;
    let selectedEmailTemplate;
    let variables = [];
    export let response;

    const setVariables = () => {
        if (variables.length) return;

        const templateVariables = emailTemplates.find(temp => temp.value === selectedEmailTemplate)?.variables ?? {};
        for (const variablesKey in templateVariables) {
            variables.push({
                raw: variablesKey,
                key: templateVariables[variablesKey].key,
                value: templateVariables[variablesKey].value
            });
        }
        variables = [...variables];

        console.log(variables);
    }

    $: if (selectedEmailTemplate) setVariables();

    $: isEmailValid = email.length > 0;

    $: isFormInvalid = !isEmailValid || variables.some(v => v.value.length === 0);

    const fetchTemplatesData = async () => {
        const fetchEmailTemplate = await getData('https://test.dev/email/read');
        const templateData = await fetchEmailTemplate.json();

        templateData.map((x) =>
            emailTemplates.push({
                label: x.title,
                value: x.id,
                variables: x?.variables
            })
        );

        emailTemplates = [...emailTemplates];
        $emailTemplatesStore = emailTemplates;
    };

    const isEven = i => i % 2 === 0;

    export let submit = async () => {
        if (isFormInvalid) return;

        response = await submitData("https://test.dev/email/send", {
            templateId: selectedEmailTemplate,
            email,
            variables: variables
        });
    };
    export let cancel = () => {
        console.log('cancel');
    };

    onMount(() => {
        if ($emailTemplatesStore.length === 0)
            fetchTemplatesData();
        else
            emailTemplates = $emailTemplatesStore;
    });
</script>

<form class="px-10 w-3/4">
    <div>
        <h2
                class="text-2xl dark:text-white my-6 font-semibold leading-tight">
            {title}
        </h2>
    </div>
    <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <Textbox
                    bind:isTextboxDirty="{isEmailDirty}"
                    bind:text="{email}"
                    isAutoFocus="{true}"
                    placeholder="Email Address"
                    showError="{!isEmailValid}"/>
        </div>
        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <Select
                    bind:selectedItemId="{selectedEmailTemplate}"
                    items="{emailTemplates}"
                    placeholder="Email Template"/>
        </div>
    </div>
    <div class="flex flex-wrap pt-6 border-t dark:border-truegray-700">
        {#if variables}
            {#each {length: variables.length} as _, i}
                <div class="w-1/2 my-2" class:pr-2={isEven(i)} class:pl-2={!isEven(i)}>
                    <input class="appearance-none block w-full text-gray-700 dark:text-truegray-300 border border-black-900 shadow dark:shadow-blue rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-truegray-800 dark:bg-truegray-900 focus:shadow-lg border-none dark:focus:text-white focus:text-black"
                           bind:value="{variables[i].value}" placeholder="{variables[i].key}"/>
                    <p class="text-truegray-700 px-4 dark:text-truegray-500 text-xs italic">{variables[i].key}</p>
                    {#if variables[i].value.length === 0}
                        <p class="text-red-500 text-xs italic">Please fill out this field.</p>
                    {/if}
                </div>
            {/each}
        {/if}
    </div>

    <div class="mt-3 mx-auto px-2 items-center">
        <button
                class="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold
          py-2 px-4 rounded-l shadow dark:shadow-blue"
                on:click={() => {
                  submit();
                  isEmailDirty = true;
                }}
                type="button">
            Send
        </button>
        <button
                class="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold
          py-2 px-4 rounded-r shadow dark:shadow-blue"
                on:click="{cancel}"
                type="button">
            Clear
        </button>
    </div>
</form>
