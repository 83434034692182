<script>
  import {linear} from 'svelte/easing';
  import {fly} from 'svelte/transition';

  export let showCustomBtn = false,
          showSaveBtn = false;
  export let customBtnTitle = '';
  export let placeholder = 'Document Title';
  export let articleHeading;
  export let articleBody;

  export let hideHeadingInput = false;

  export let customBtnAction = () => {
    console.log('custom button was pressed');
  };

  export let handleSave = () => {
    console.log('fetch data was called');
  };

  // $: isWriteAllowed = $isUserAdminStore;
  let isWriteAllowed = true;
  let articleHeadingInputRef, articleBodyInputRef;

  $: if (articleBodyInputRef)
    articleBodyInputRef.contentEditable = isWriteAllowed;
  $: if (articleHeadingInputRef)
    articleHeadingInputRef.contentEditable = isWriteAllowed;
</script>

<div
  in:fly="{{ x: -300, easing: linear, delay: 100, duration: 300 }}"
  out:fly="{{ x: 1000, duration: 500 }}">
  <div
    class="container mx-auto max-w-screen-lg border-t dark:border-truegray-700">
    {#if isWriteAllowed}
      {#if showCustomBtn}
        <p
          class="leading-5 font-medium cursor-pointer text-center text-blue-100 transition-colors duration-150 bg-blue-600 focus:shadow-outline shadow dark:shadow-blue hover:bg-blue-700 rounded-full my-2 py-1 px-3 float-right"
          on:click="{customBtnAction}">
          {customBtnTitle}
        </p>
      {/if}
      {#if (showSaveBtn && articleHeading) || (articleHeading && articleBody)}
        <p
          class="leading-5 font-medium cursor-pointer text-center text-blue-100 transition-colors duration-150 bg-green-600 focus:shadow-outline shadow dark:shadow-blue hover:bg-green-700 rounded-full my-2 mx-2 py-1 px-3 float-right"
          on:click="{handleSave}">
          Save
        </p>
      {/if}
    {/if}
    {#if !hideHeadingInput}
      <input
        bind:value="{articleHeading}"
        bind:this="{articleHeadingInputRef}"
        class="appearance-none block w-full border
            border-black-900 shadow dark:shadow-blue rounded py-3 px-4 leading-tight focus:outline-none my-2
            focus:shadow-lg border-none"
        type="text"
        placeholder="{placeholder}" />
    {/if}
    <div
      bind:this="{articleBodyInputRef}"
      bind:innerHTML="{articleBody}"
      class="overflow-auto appearance-none block w-full bg-white text-truegray-900 border border-black-900 shadow dark:shadow-blue rounded py-3 px-4 leading-tight focus:outline-none focus:shadow-lg border-none h-screen"
      contenteditable="true"
      placeholder="Script Content">
    </div>
  </div>
</div>
