<script>
    import EmailScheduleSendForm from '../components/forms/EmailScheduleSendForm.svelte';
    import DataTable from '../components/datatable/DataTable.svelte';
    import columns from '../components/datatable/column-data/email-schedule';
    import {submitData} from '../services/postData';
    import {getData} from '../services/fetchData';
    import {onMount} from 'svelte';
    import {linear} from "svelte/easing";
    import {fly} from 'svelte/transition';
    import EmailTemplateSendForm from "../components/forms/EmailTemplateSendForm.svelte";

    export let showSpinner,
        mildError,
        severeError,
        internalSuccess,
        actionSuccess,
        chipMessage;

    $: showSpinner = fetchContactLogs && fetchFollowUpSchedules;

    let interval,
        repeatSchedule,
        partOfDay,
        repeatOnDay,
        selectedEmailTemplate,
        email,
        clientid;

    $: trackedClients = clients.filter(client => client.is_active).map(client => client.id);

    let sendResponse;
    let fetchFollowUpSchedules, fetchContactLogs;
    let schedules = [],
        clients = [];
    let logs = {callLogs: [], emailLogs: []};

    $: if (sendResponse) {
        if (sendResponse.status === 200) {
            internalSuccess = true;
            chipMessage = 'Email sent successfully';
        } else {
            severeError = true;
            chipMessage = 'Error sending email';
        }
    }


    const syncTrackedClients = async () => {
        if (!trackedClients) return;

        const saveTrackedClients = await submitData('https://test.dev/followup/writeClients', {
            clients: trackedClients
        });
        if (saveTrackedClients.status === 200) {
            chipMessage = 'Clients saved successfully';
            internalSuccess = true;
        } else {
            chipMessage = 'Error saving clients';
            severeError = true;
        }
    }

    const AddNewScheduledEmail = async () => {
        const response = await submitData('https://test.dev/followup/write', {
            frequency: interval,
            interval: repeatSchedule,
            partOfDay,
            weekday: repeatOnDay,
            templateid: selectedEmailTemplate,
            email,
            clientid,
        });
        if (!response.ok) chipMessage = 'Something went wrong';
        else chipMessage = 'Email scheduled successfully';
    };

    const fetchClients = async () => {
        const response = await getData('https://test.dev/followup/ReadClients');
        if (!response.ok) chipMessage = 'Something went wrong';
        else {
            clients = await response.json();
        }
    };

    const getClient = id => {
        const client = clients.find(x => x.id === id);
        return client;
    }

    const fetchData = async (id) => {
        if (!id) return;

        const client = getClient(id);
        if (!client?.is_active) {
            client.is_active = true;
            clients = [...clients];
        }

        fetchFollowUpSchedules = getData(`https://test.dev/followup/read/${id}`);
        fetchContactLogs = getData(
            `https://test.dev/followup/readClientContactLogs/${id}`
        );

        [fetchFollowUpSchedules, fetchContactLogs] = await Promise.all([
            fetchFollowUpSchedules,
            fetchContactLogs,
        ]);

        schedules = await fetchFollowUpSchedules.json();
        clientid = id;
        logs = await fetchContactLogs.json();
    };

    onMount(() => {
        fetchClients();
    });
</script>

<div class="container">
    <div class="py-8">
        <div class="flex">
            <div class="flex-auto w-96" in:fly="{{ x: -300, easing: linear, delay: 100, duration: 300 }}"
                 out:fly="{{ x: 1000, duration: 500 }}">
                <div
                        class="bg-white h-screen overflow-auto dark:bg-truegray-900 rounded-md shadow dark:shadow-blue">
                    <div class="px-5 py-3 border-b-2 border-gray-200 dark:border-truegray-700 bg-gray-100 dark:bg-truegray-800 flow-root font-medium rounded-md leading-tight text-gray-500 uppercase">
                        <h5 class="uppercase text-gray-600 float-left">Track Clients</h5>
                        <button class="float-right text-xl -my-1 cursor-pointer" on:click={() => clientid = null}>❌
                        </button>
                        <button class="float-right text-xl -my-1 cursor-pointer" on:click={syncTrackedClients}>💾
                        </button>
                    </div>
                    <div class="px-1">
                        <ul
                                class="text-left list-none text-lg dark:text-white py-2 font-semibold px-2">
                            {#each clients as client}
                                <li class="flex flex-wrap text-base font-normal">
                                    <p class="text-left w-11/12 truncateTextWider">
                                        <label for="track client">
                                            <input
                                                    bind:group="{trackedClients}"
                                                    type="checkbox"
                                                    class="checkboxComponent"
                                                    value="{client.id}"
                                                    checked="{client.is_active}"
                                                    on:click={client.is_active = +!client.is_active}/>
                                            <span class="ml-2">{client.name}</span>
                                        </label>
                                    </p>
                                    <p class="text-center w-1/12 text-base cursor-pointer"
                                       on:click="{fetchData(client.id)}">
                                        ✏
                                    </p>
                                </li>
                            {/each}
                        </ul>
                    </div>
                </div>
            </div>
            {#if clientid}
                <div class="flex-auto w-full pl-4" in:fly="{{ x: 300, easing: linear, delay: 100, duration: 300 }}">
                    <EmailScheduleSendForm
                            client="{getClient(clientid)}"
                            bind:email
                            bind:timeInterval="{interval}"
                            bind:repeatSchedule
                            bind:partOfDay
                            bind:repeatOnDay
                            bind:selectedEmailTemplate
                            submit="{AddNewScheduledEmail}"/>

                    <div class="w-full">
                        <DataTable
                                process="local"
                                columns="{columns}"
                                exportable="{false}"
                                printable="{false}"
                                searchable="{false}"
                                rows="{schedules}"
                                showPageSizeOptions="{false}"/>
                    </div>
                    <div
                            class="border-t dark:border-truegray-700 w-full flex flex-wrap pt-6">
                        <div class="w-1/2 px-3">
                            <div
                                    class="bg-white h-64 overflow-auto fixed-size-card dark:bg-truegray-900 rounded-md shadow dark:shadow-blue">
                                <div
                                        class="px-5 py-3 border-b-2 border-gray-200 dark:border-truegray-700 bg-gray-100 dark:bg-truegray-800 font-medium rounded-md leading-tight text-gray-500 uppercase">
                                    <h5 class="uppercase text-gray-600">calls logs</h5>
                                </div>
                                <div class="px-1">
                                    {#if logs.callLogs.length}
                                        <ul
                                                class="text-left list-none text-lg dark:text-white py-2 font-semibold px-2">
                                            {#each logs.callLogs as call}
                                                <li class="flex flex-wrap text-base font-normal">
                                                    <p class="text-left w-2/3">
                                                        {new Date(call.datetime).toLocaleString('en-US', {
                                                            timeZone: 'Asia/Karachi',
                                                            year: 'numeric',
                                                            month: 'short',
                                                            day: 'numeric'
                                                        })}
                                                    </p>
                                                    <p class="text-center w-1/3">
                                                        {call.duration}
                                                    </p>
                                                </li>
                                            {/each}
                                        </ul>
                                    {:else}
                                        <div class="text-center text-gray-500 py-4">
                                            No call logs
                                        </div>
                                    {/if}
                                </div>
                            </div>
                        </div>
                        <div class="w-1/2 px-3">
                            <div
                                    class="bg-white h-64 overflow-auto fixed-size-card dark:bg-truegray-900 rounded-md shadow dark:shadow-blue">
                                <div
                                        class="px-5 py-3 border-b-2 border-gray-200 dark:border-truegray-700 bg-gray-100 dark:bg-truegray-800 font-medium rounded-md leading-tight text-gray-500 uppercase">
                                    <h5 class="uppercase text-gray-600">emails logs</h5>
                                </div>
                                <div class="px-1">
                                    {#if logs.emailLogs.length}
                                        <ul
                                                class="text-left list-none text-lg dark:text-white py-2 font-semibold px-2">
                                            {#each logs.emailLogs as email}
                                                <li class="flex flex-wrap text-base font-normal">
                                                    <p class="text-left w-2/3 truncateTextWider">
                                                        {email.subject}
                                                    </p>
                                                    <p class="text-center w-1/3">
                                                        {new Date(email.datetime).toLocaleString('en-US', {
                                                            timeZone: 'Asia/Karachi',
                                                            month: 'short',
                                                            day: 'numeric',
                                                            hour: 'numeric',
                                                            minute: 'numeric'
                                                        })}
                                                    </p>
                                                </li>
                                            {/each}
                                        </ul>
                                    {:else}
                                        <div class="text-center text-gray-500 py-4">
                                            No email logs
                                        </div>
                                    {/if}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {:else }
                <EmailTemplateSendForm response={sendResponse}/>
            {/if}
        </div>
    </div>
</div>
