<script>
  import Chip from "@fouita/chip";
  import { appTheme } from "../stores/index";

  export let mildError = false,
    severeError = false,
    infoMsg = false,
    warnMsg = false,
    actionSuccess = false,
    internalSuccess = false,
    duration = 200;

  $: if (
    mildError ||
    severeError ||
    infoMsg ||
    actionSuccess ||
    internalSuccess ||
    warnMsg
  ) {
    setTimeout(() => (mildError = false), 2000);
    setTimeout(() => (severeError = false), 2000);
    setTimeout(() => (infoMsg = false), 2000);
    setTimeout(() => (actionSuccess = false), 2000);
    setTimeout(() => (internalSuccess = false), 2000);
    setTimeout(() => (warnMsg = false), 2000);
  }
</script>

<div class="absolute left-3/4">
  {#if mildError}
    <div>
      <Chip
        outline
        rounded="md"
        tr_duration="{duration}"
        color="red"
        size="l"
        onDelete="{() => (mildError = false)}">
        <div slot="avatar">
          <img
            class="w-5 h-5 mx-2"
            alt="close chip"
            src="/img/icons/{$appTheme}/crossed.svg" />
        </div>
        <p class="text-sm font-base">
          <slot>The initiated action did not complete succesfully</slot>
        </p>
      </Chip>
    </div>
  {/if}

  {#if severeError}
    <Chip
      rounded="md"
      tr_duration="{duration}"
      color="red"
      size="xl"
      onDelete="{() => (severeError = false)}">
      <div slot="avatar">
        <img
          class="w-5 h-5 mx-2"
          alt="close chip"
          src="/img/icons/{$appTheme}/crossed.svg" />
      </div>
      <p class="text-sm font-base">
        <slot>The initiated action did not complete succesfully</slot>
      </p>
    </Chip>
  {/if}

  <div class="mt-4"></div>
  {#if infoMsg}
    <Chip
      outline
      rounded="md"
      tr_duration="{duration}"
      color="blue"
      size="xl"
      onDelete="{() => (infoMsg = false)}">
      <div slot="avatar">
        <img
          class="w-5 h-5 mx-2"
          alt="close chip"
          src="/img/icons/{$appTheme}/warning.svg" />
      </div>
      <p class="text-sm font-base"><slot>This is an info messsage</slot></p>
    </Chip>
  {/if}

  {#if warnMsg}
    <Chip
      rounded="md"
      tr_duration="{duration}"
      color="orange"
      size="xl"
      onDelete="{() => (warnMsg = false)}">
      <div slot="avatar">
        <img
          class="w-5 h-5 mx-2"
          alt="close chip"
          src="/img/icons/{$appTheme}/warning.svg" />
      </div>
      <p class="text-sm font-base"><slot>This is an info message</slot></p>
    </Chip>
  {/if}

  {#if actionSuccess}
    <Chip
      outline
      rounded="md"
      tr_duration="{duration}"
      color="green"
      size="xl"
      onDelete="{() => (actionSuccess = false)}">
      <div slot="avatar">
        <img
          class="w-5 h-5 mx-2"
          alt="close chip"
          src="/img/icons/{$appTheme}/check.svg" />
      </div>
      <p class="text-sm font-base"><slot>This is a success messsage</slot></p>
    </Chip>
  {/if}

  {#if internalSuccess}
    <Chip
      rounded="md"
      tr_duration="{duration}"
      color="green"
      size="xl"
      onDelete="{() => (internalSuccess = false)}">
      <div slot="avatar">
        <img
          class="w-5 h-5 mx-2"
          alt="close chip"
          src="/img/icons/{$appTheme}/check.svg" />
      </div>
      <p class="text-sm font-base"><slot>This is a success messsage</slot></p>
    </Chip>
  {/if}
</div>
