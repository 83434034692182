<script>
  import {Route, Router} from 'svelte-routing';
  import {onMount} from 'svelte';
  import Tailwindcss from './components/Tailwindcss.svelte';
  import NavBar from './components/nav/NavBar.svelte';
  import OfflineOverlay from './components/OfflineOverlay.svelte';
  import Page from './Page.svelte';
  import Home from './pages/Home.svelte';
  import Clients from './pages/Clients.svelte';
  import Collections from './pages/Collections.svelte';
  import Orders from './pages/Orders.svelte';
  import AllOrders from './pages/AllOrders.svelte';
  import CRM from './pages/Emails.svelte';
  import Leads from './pages/Leads.svelte';
  import Stats from './pages/Stats.svelte';
  import DesignVerification from './pages/DesignVerification.svelte';
  import VendorVerification from './pages/VendorVerification.svelte';
  import Form from './components/forms/LoginForm.svelte';
  import Chip from './components/Chip.svelte';
  import ResetForm from './components/forms/ResetPasswordForm.svelte';
  import {getData} from './services/fetchData';
  import {submitData} from './services/postData';
  import {
    isUserAdminStore,
    lastLoginStatusCheckStore,
    loginStatusStore,
    usernameStore,
  } from './components/forms/stores/index';
  import {getTimeDifferenceFromNow} from './helpers/functions';
  import {encryptedBits, passwordBits} from './stores/index';
  import {AES} from './helpers/aes';
  import Spinner from './components/Spinner.svelte';
  import Scripts from "./pages/Scripts.svelte";

  let showSpinner;

  let chipMessage;
  let chipDuration = 200;
  let mildError,
          severeError,
          infoMsg,
          warnMsg,
    actionSuccess,
    internalSuccess = false;

  let isLoggedIn = false;
  let showLoggedOutScreen = false;
  let isFirstLoad = true;
  let lastFetchedBackgroundData,
    approvalPendingCount = 0;

  let notificationPing;

  const signInTypeEnum = { master: 1, client: 0 };
  Object.freeze(signInTypeEnum);
  let signInType = signInTypeEnum.client;

  let signInAttempts = 0;

  let isFormInvalid = false;
  let isUsernameEmpty;

  let username = $usernameStore,
    password = '';

  let lastLoginStatusCheck =
    $lastLoginStatusCheckStore == 0
      ? new Date('2022-01-02')
      : $lastLoginStatusCheckStore;

  $: title = signInType === signInTypeEnum.client ? 'Login' : 'Master Sign In';

  let currentURL = new URL(decodeURIComponent(window.location.href));
  let showResetForm = currentURL.pathname === '/resetPassword';

  const login = async () => {
    if (isFormInvalid) return false;

    const response = await submitData('https://test.dev/user/login', {
      username: username,
      password: password,
    });

    if (response.ok) {
      console.log('response was 200');
      chipMessage = 'Successfully logged in';
      internalSuccess = true;
    } else if (response.status == 401) {
      console.log('response was 401');
      chipMessage = 'Wrong username or password';
      mildError = true;
    } else {
      chipMessage = 'Something went wrong';
      severeError = true;
    }
    const isDataSubmitted = await response.json();
    if (
      !isUsernameEmpty &&
      typeof username === 'string' &&
      signInType !== signInTypeEnum.master &&
      isDataSubmitted === true
    ) {
      usernameStore.set(username);
    }

    if (
      signInType === signInTypeEnum.master &&
      $encryptedBits === 0 &&
      $passwordBits === 0 &&
      isDataSubmitted === 1
    )
      encryptCredentials();

    evaluateLogin();
  };

  const evaluateLogin = async () => {
    let currentStatus = await loginStatus();

    if (currentStatus === -1) var credentials = await decryptCredentials();

    while (currentStatus === -1) {
      if (signInAttempts > 3 || !$encryptedBits) {
        isLoggedIn = false;
        signInType = signInTypeEnum.master;
        return;
      }

      [username, password] = [credentials[0], credentials[1]];
      signInAttempts += 1;
      login();
      currentStatus = await loginStatus();
    }

    if (currentStatus === 1 || currentStatus === false) showLoginForm();
    else if (currentStatus === true) isLoggedIn = true;
  };

  const encryptCredentials = async () => {
    if (isFormInvalid) return;

    const randompassCode = crypto.getRandomValues(new Uint8Array(32));
    const encrypted = await AES().encrypt(
      `${username}:${password}`,
      null,
      randompassCode
    );
    encryptedBits.set(encrypted);
    passwordBits.set([randompassCode]);
  };

  const decryptCredentials = async () => {
    const encrypted = $encryptedBits;
    const randompassCode = $passwordBits;

    try {
      const decrypted = await AES().decrypt(
        encrypted,
        null,
        Object.values(randompassCode[0])
      );
      const credentials = decrypted.split(':');
      return credentials;
    } catch (error) {
      console.log(error);
    }
  };

  const loginStatus = async () => {
    const diff = getTimeDifferenceFromNow(new Date(lastLoginStatusCheck));
    if (!isNaN(diff['hours']) && diff['hours'] < 2 && $loginStatusStore) return $loginStatusStore;
    let response = await getData('https://test.dev/user/status');
    response = await response.json();
    lastLoginStatusCheckStore.set(new Date().toISOString());
    if (response)
      $loginStatusStore = response;
    return response;
  };

  const showLoginForm = () => {
    signInType = signInTypeEnum.client;
    isLoggedIn = false;
    username = $usernameStore;
    password = '';
  };

  const forgotPassword = async () => {
    if (isUsernameEmpty) return;
    usernameStore.set(username);
    const response = await submitData('https://test.dev/user/forgotPassword', {
      username: username,
      password: password,
    });
    if (response.ok) {
      chipMessage = 'Password reset email sent succesfully';
      internalSuccess = true;
    }
  };

  const clearUserStore = () => {
    $usernameStore = 0;
    lastLoginStatusCheckStore.set(new Date('2022-01-02').toISOString());
  };

  const updateLastFetchedBackgroundData = () => {
    let now;
    if (isFirstLoad) now = new Date('2022-01-02');
    else now = new Date();
    lastFetchedBackgroundData = `${now.getFullYear()}-${
      now.getMonth() + 1
    }-${now.getDate()} ${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`;
  };

  const handleBackgroundSync = async () => {
    if (!isLoggedIn || 'process.env' == 'dev') return false;

    let fetchOrdersResponse = submitData(
      'https://test.dev/order/readApprovalPendingCount',
      {
        date: lastFetchedBackgroundData,
      }
    );
    let fetchLoginStatusResponse = getData('https://test.dev/user/status');

    [fetchOrdersResponse, fetchLoginStatusResponse] = await Promise.all([
      fetchOrdersResponse,
      fetchLoginStatusResponse,
    ]);

    const loginResponse = await fetchLoginStatusResponse.json();
    $loginStatusStore = loginResponse;
    $lastLoginStatusCheckStore = new Date().toISOString();

    if (loginResponse == -1 || loginResponse === false) {
      showLoggedOutScreen = true;
      lastLoginStatusCheckStore.set(new Date('2022-01-02').toISOString());
    }

    if (fetchOrdersResponse.status != 204) {
      isFirstLoad = false;
      updateLastFetchedBackgroundData();
      approvalPendingCount = await fetchOrdersResponse.json();
      if (approvalPendingCount > 0) {
        let timesPlayed = 0;
        let timeOut = 2000;
        function wait() {
          if (timesPlayed < 4) {
            notificationPing.play();
            timesPlayed++;
            timeOut -= 100;
            setTimeout(wait, timeOut);
          }
        }
        wait();
      }
    }
  };

  // Used for SSR. A falsy value is ignored by the Router.
  export let url = '';

  onMount(() => {
    isFirstLoad = true;
    evaluateLogin();
    updateLastFetchedBackgroundData();
    handleBackgroundSync();
    setInterval(handleBackgroundSync, 20000);
  });
</script>

<Tailwindcss />

<audio src="/sounds/soft-ping.wav" bind:this="{notificationPing}"></audio>

<OfflineOverlay showOverlay="{showLoggedOutScreen}">
  <h2
    class="text-6xl dark:text-white font-semibold text-center mt-96 leading-tight">
    You are currently logged out. <br />
    <a href="." class="border-dotted border-b-2">Please Refresh 🔃</a>
  </h2>
</OfflineOverlay>

{#await showSpinner}
  <Spinner />
{/await}

<Router url="{url}">
  {#if isLoggedIn}
    <NavBar approvalPendingCount="{approvalPendingCount}" />
  {/if}

  <Chip

    bind:mildError
    bind:severeError
    bind:internalSuccess
    bind:actionSuccess
    bind:warnMsg
    bind:infoMsg
    bind:duration="{chipDuration}">{chipMessage}</Chip>

  {#if isLoggedIn}
    <div>
      <Route path="clients">
        <Page>
          <Clients
            bind:showSpinner
            bind:mildError
            bind:severeError
            bind:internalSuccess
            bind:actionSuccess
            bind:warnMsg
            bind:infoMsg
            bind:chipDuration
            bind:chipMessage />
        </Page>
      </Route>

      <Route path="leads">
          <Page>
            <Leads
              bind:showSpinner
              bind:mildError
              bind:severeError
              bind:internalSuccess
              bind:actionSuccess
              bind:warnMsg
              bind:infoMsg
              bind:chipDuration
              bind:chipMessage />
          </Page>
        </Route>

      <Route path="collections/*">
        <Page>
          <Collections
            bind:showSpinner
            bind:mildError
            bind:severeError
            bind:internalSuccess
            bind:actionSuccess
            bind:warnMsg
            bind:infoMsg
            bind:chipDuration
            bind:chipMessage />
        </Page>
      </Route>

      {#if typeof username === 'string' && username.toLowerCase() == 'mhzr'}
        <Route path="/">
          <Page>
            <Orders
              bind:showSpinner
              bind:mildError
              bind:severeError
              bind:internalSuccess
              bind:actionSuccess
              bind:warnMsg
              bind:infoMsg
              bind:chipDuration
              bind:chipMessage />
          </Page>
        </Route>
      {:else}
        <Route path="/">
          <Page>
            <Home
              bind:showSpinner
              bind:mildError
              bind:severeError
              bind:internalSuccess
              bind:actionSuccess
              bind:warnMsg
              bind:infoMsg
              bind:chipDuration
              bind:chipMessage />
          </Page>
        </Route>
      {/if}
      <Route path="orders">
        <Page>
          <Orders
                  bind:showSpinner
                  bind:mildError
                  bind:severeError
                  bind:internalSuccess
                  bind:actionSuccess
                  bind:warnMsg
                  bind:infoMsg
                  bind:chipDuration
                  bind:chipMessage/>
        </Page>
      </Route>
      {#if $isUserAdminStore}
        <Route path="stats">
          <Page>
            <Stats />
          </Page>
        </Route>

        <Route path="dv/*">
          <Page>
            <DesignVerification
              bind:showSpinner
              bind:mildError
              bind:severeError
              bind:internalSuccess
              bind:actionSuccess
              bind:warnMsg
              bind:infoMsg
              bind:chipDuration
              bind:chipMessage />
          </Page>
        </Route>

        <Route path="vv">
          <Page>
            <VendorVerification
              bind:showSpinner
              bind:mildError
              bind:severeError
              bind:internalSuccess
              bind:actionSuccess
              bind:warnMsg
              bind:infoMsg
              bind:chipDuration
              bind:chipMessage />
          </Page>
        </Route>
      {/if}
      <Route path="scripts/*">
        <Page>
          <Scripts
                  bind:showSpinner
                  bind:mildError
                  bind:severeError
                  bind:internalSuccess
                  bind:actionSuccess
                  bind:warnMsg
                  bind:infoMsg
                  bind:chipDuration
                  bind:chipMessage/>
        </Page>
      </Route>

      <Route path="crm">
        <Page>
          <CRM bind:showSpinner
               bind:mildError
               bind:severeError
               bind:internalSuccess
               bind:actionSuccess
               bind:warnMsg
               bind:infoMsg
               bind:chipDuration
               bind:chipMessage/>
        </Page>
      </Route>

      <Route path="all-orders">
        <Page>
          <AllOrders
                  bind:showSpinner
                  bind:mildError
                  bind:severeError
                  bind:internalSuccess
                  bind:actionSuccess
                  bind:warnMsg
                  bind:infoMsg
                  bind:chipDuration
                  bind:chipMessage/>
        </Page>
      </Route>
    </div>
  {/if}
</Router>
{#if !isLoggedIn}
  {#if showResetForm}
    <ResetForm username="{username}" cancel="{() => (showResetForm = false)}" />
  {:else}
    <Form
      title="{title}"
      login="{login}"
      signInAttempts="{signInAttempts}"
      forgotPassword="{forgotPassword}"
      clearUserStore="{clearUserStore}"
      bind:username
      bind:password
      isFormInvalid="{isFormInvalid}"
      bind:isUsernameEmpty />
  {/if}
{/if}
