<script>
  export let showOverlay = false;
  $: displayMode = showOverlay ? "block" : "none";
</script>

<div id="overlay" class="hidden" style="--display-mode: {displayMode}">
  <slot />
</div>

<style>
  #overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 50;
    overflow: scroll;
    display: var(--display-mode);
    @apply backdrop-filter backdrop-blur-sm backdrop-sepia backdrop-brightness-50;
  }
</style>
