<script>
  import {onDestroy, onMount} from 'svelte';
  import Switch from './Switch.svelte';
  import {getData} from '../services/fetchData';

  export let showSlimView = false;
  export let iconOnly = false;
  export let uuid;
  export let attachedFileNames = [];
  export let uploadedFileNames = [];
  export let attachedFileNamesFromServer = [];
  export let fileUploadUrl = 'https://test.dev/order/upload';
  export let previewUrl = 'https://test.dev/order/getAttachmentPreview';

  // use to store pre selected files
  let attachments = {};

  let isMounted = false;
  let showAttachments = true;

  const chunkSize = 1000000; // ~1MB

  let isAttachmentDataServerFetched = false;

  $: attachedFileNames = Object.values(attachments).map((file) => file.name);

  $: if (attachedFileNamesFromServer.length > 0) {
    isAttachmentDataServerFetched = true;
    if (isMounted && gallery) {
      const count = attachedFileNamesFromServer.length;
      for (let index = 0; index < count; index++) {
        const fileFromServer = attachedFileNamesFromServer.pop();
        addFileName(gallery, fileFromServer);
        uploadedFileNames.push(fileFromServer);
      }
    }
  }

  $: if (showAttachments && isAttachmentDataServerFetched) {
    (async () => {
      console.log(`${previewUrl}/${uuid}`);
      const response = await getData(`${previewUrl}/${uuid}`);
      if (response.ok) {
        const previews = await response.json();
        for (const preview of previews) {
          const attachmentName = preview.name.replace('_thumb_', '');
          document.getElementById(attachmentName).innerHTML = '';
          document.getElementById(attachmentName).innerHTML = `
          <article
            tabindex="0"
            class="group hasImage w-full h-full rounded-md focus:outline-none focus:shadow-outline bg-gray-100 cursor-pointer relative text-transparent hover:text-white shadow-sm">
            <img
              alt="upload preview"
              class="img-preview w-full h-full sticky object-cover rounded-md bg-fixed" src="data:${preview.type};base64,${preview.content}" />

              <section
                class="flex flex-col rounded-md text-xs break-words w-full h-full z-20 absolute top-0 py-2 px-3">
                <h1 class="flex-1">${attachmentName}</h1>
                <div class="flex">
                  <span class="p-1">
                    <i>
                      <svg
                        class="fill-current w-4 h-4 ml-auto pt-"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24">
                        <path
                          d="M5 8.5c0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5c0 .829-.672 1.5-1.5 1.5s-1.5-.671-1.5-1.5zm9 .5l-2.519 4-2.481-1.96-4 5.96h14l-5-8zm8-4v14h-20v-14h20zm2-2h-24v18h24v-18z"
                        ></path>
                      </svg>
                    </i>
                  </span>

                  <p class="p-1 size text-xs"></p>
                  <button
                    class="delete ml-auto focus:outline-none hover:bg-gray-300 p-1 rounded-md" data-target = "${attachmentName}">
                    <svg
                      class="pointer-events-none fill-current w-4 h-4 ml-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24">
                      <path
                        class="pointer-events-none"
                        d="M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z"
                      ></path>
                    </svg>
                  </button>
                </div>
              </section>
          </article>
          `;
        }
      }
    })();
  }

  const maxUpload = 10;

  const progressBarColors = [
    'blue',
    'coffee',
    'ectoplasm',
    'light',
    'midnight',
    'ocean',
    'sunrise',
  ];

  const handleFileUpload = (file) => {
    let [start, chunkCounter] = [0, 0];
    if (iconOnly) {
      uploadIcon.classList.add('text-truegray-400');
      uploadIcon.classList.remove('text-green-400');
      uploadIcon.classList.remove('animate-bounce');
    }
    createChunk(file, start, chunkCounter);
  };

  function createChunk(file, start, chunkCounter) {
    chunkCounter++;
    const numberofChunks = Math.ceil(file.size / chunkSize);
    const chunkEnd = Math.min(start + chunkSize, file.size);
    const chunk = file.slice(start, chunkEnd);

    const chunkForm = new FormData();
    chunkForm.append('guid', uuid);
    chunkForm.append('file', chunk, file.name);
    chunkForm.append('totalParts', numberofChunks);
    chunkForm.append('fileSize', file.size);
    chunkForm.append('partIndex', chunkCounter);

    uploadChunk(chunkForm, start, chunkEnd, file, chunkCounter, numberofChunks);
  }

  function uploadChunk(
    chunkForm,
    start,
    chunkEnd,
    file,
    chunkCounter,
    numberofChunks
  ) {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.upload.addEventListener('progress', function (e) {
      updateProgress(e, file, chunkCounter, numberofChunks);
    });
    xhr.open('POST', fileUploadUrl, true);

    const blobEnd = chunkEnd - 1;
    const contentRange = `bytes ${start}-${blobEnd}/${file.size}`;
    xhr.setRequestHeader('Content-Range', contentRange);

    xhr.onload = (e) => {
      const resp = JSON.parse(xhr.response);

      start += chunkSize;
      if (start < file.size) {
        createChunk(file, start, chunkCounter);
      } else {
        if (resp.uploadName) {
          uploadedFileNames.push(file.name);
          uploadedFileNames = uploadedFileNames;
          if (iconOnly) {
            uploadIcon.classList.remove('text-truegray-400');
            uploadIcon.classList.add('text-green-400');
            uploadIcon.classList.add('animate-bounce');
          }
          const progressBar = document.getElementById(`${file.name}-progress`);
          if (progressBar) {
            progressBar.classList.add('hidden');
          }
        }
      }
    };
    xhr.send(chunkForm);
  }

  function updateProgress(e, file, chunkCounter, numberofChunks) {
    if (e.lengthComputable) {
      const percentComplete = Math.round((e.loaded / e.total) * 100);

      const totalPercentComplete = Math.round(
        ((chunkCounter - 1) / numberofChunks) * 100 +
          percentComplete / numberofChunks
      );
      const progressBar = document.getElementById(`${file.name}-progress`);
      if (progressBar) {
        progressBar.value += totalPercentComplete;
      }
    } else {
      console.log('not computable');
    }
  }

  let fileTempl,
    imageTempl,
    empty,
    gallery,
    overlay,
    hidden,
    uploadButton,
    uploadIcon;

  // check if file is of type image and prepend the initialied
  // template to the target element
  function addFile(target, file) {
    const numberOfFiles = Object.keys(attachments).length;

    if (numberOfFiles > maxUpload) {
      console.log("can't upload any more files against this order");
      return;
    }

    handleFileUpload(file);

    if (showSlimView || iconOnly) return false;

    const isImage = file.type.match('image.*'),
      objectURL = URL.createObjectURL(file);

    const clone = isImage
      ? imageTempl.content.cloneNode(true)
      : fileTempl.content.cloneNode(true);

    clone.querySelector('h1').textContent = file.name;
    clone.querySelector('li').id = objectURL;
    clone.querySelector('.delete').dataset.target = objectURL;
    clone
      .querySelector('progress')
      .classList.add(
        progressBarColors[
          Object.keys(attachments).length % (progressBarColors.length + 1)
        ]
      );
    clone.querySelector('progress').setAttribute('id', `${file.name}-progress`);
    clone.querySelector('.size').textContent =
      file.size > 1024
        ? file.size > 1048576
          ? Math.round(file.size / 1048576) + 'mb'
          : Math.round(file.size / 1024) + 'kb'
        : file.size + 'b';

    isImage &&
      Object.assign(clone.querySelector('img'), {
        src: objectURL,
        alt: file.name,
      });

    empty.classList.add('hidden');
    target.prepend(clone);

    attachments[objectURL] = file;
  }

  function addFileName(target, fileName) {
    const clone = fileTempl.content.cloneNode(true);

    clone.querySelector('h1').textContent = fileName.slice(0, 25) + '...';
    clone.querySelector('li').id = fileName;
    clone.querySelector('.delete').dataset.target = fileName;
    clone.querySelector('.size').textContent = '';
    clone.querySelector('progress').classList.add('hidden');
    clone.querySelector('img').id = 'img_' + fileName;

    empty.classList.add('hidden');
    target.prepend(clone);

    attachments[fileName] = { name: fileName };
    attachments = attachments;
    attachedFileNames.push(fileName);
    attachedFileNames = [...attachedFileNames];
  }

  function deleteAll() {
    while (gallery.children.length > 0) {
      gallery.lastChild.remove();
    }
    attachments = {};
    empty.classList.remove('hidden');
    gallery.append(empty);
  }

  // use to check if a file is being dragged
  const hasFiles = ({ dataTransfer: { types = [] } }) =>
    types.indexOf('Files') > -1;

  // use to drag dragenter and dragleave events.
  // this is to know if the outermost parent is dragged over
  // without issues due to drag events on its children
  let counter = 0;

  // reset counter and append file to gallery when file is dropped
  function dropHandler(ev) {
    ev.preventDefault();
    for (const file of ev.dataTransfer.files) {
      addFile(gallery, file);
      overlay.classList.remove('draggedover');
      counter = 0;
    }
  }

  // only react to actual files being dragged
  function dragEnterHandler(e) {
    e.preventDefault();
    if (!hasFiles(e)) {
      return;
    }
    ++counter && overlay.classList.add('draggedover');
  }

  function dragLeaveHandler(e) {
    1 > --counter && overlay.classList.remove('draggedover');
  }

  function dragOverHandler(e) {
    if (hasFiles(e)) {
      e.preventDefault();
    }
  }

  onMount(() => {
    isMounted = true;

    // click the hidden input of type file if the visible button is clicked
    // and capture the selected files
    if (uploadButton) {
      uploadButton.onclick = () => hidden.click();
      uploadButton.disabled = false;
    }
    if (hidden) {
      hidden.onchange = (e) => {
        console.log('I was clicked');
        for (const file of e.target.files) {
          addFile(gallery, file);
        }
      };
    }

    // event delegation to caputre delete events
    // fron the waste buckets in the file preview cards
    if (gallery) {
      gallery.onclick = ({ target }) => {
        if (target.classList.contains('delete')) {
          const ou = target.dataset.target;
          document.getElementById(ou).remove(ou);
          gallery.children.length === 1 && empty.classList.remove('hidden');
          delete attachments[ou];
          attachments = { ...attachments };
        }
      };
    }
  });

  onDestroy(() => {
    isMounted = false;
  });
</script>

{#if showSlimView}
  <div
    class="mb-3 w-96"
    on:drop="{dropHandler}"
    on:dragover="{dragOverHandler}"
    on:dragleave="{dragLeaveHandler}"
    on:dragenter="{dragEnterHandler}">
    <input
      class="form-control
      block
      w-full
      px-2
      py-1.5
      text-xl
      font-normal
      text-truegray-800 dark:text-gray-200
      bg-truegray-200 dark:bg-truegray-800 bg-clip-padding
      shadow dark:shadow-blue
      rounded
      transition
      ease-in-out
      m-0"
      bind:this="{hidden}"
      type="file" />
  </div>
{:else if iconOnly}
  <div
    bind:this="{uploadIcon}"
    class="h-6 w-6 text-truegray-400 cursor-pointer"
    on:drop="{dropHandler}"
    on:dragover="{dragOverHandler}"
    on:dragleave="{dragLeaveHandler}"
    on:dragenter="{dragEnterHandler}">
    <label>
      <input bind:this="{hidden}" class="hidden" type="file" />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
        ></path>
      </svg>
    </label>
  </div>
{:else}
  <!-- file upload modal -->
  <article
    aria-label="File Upload Modal"
    class="relative h-full flex flex-col bg-white dark:bg-truegray-900 shadow-xl dark:shadow-blue rounded-md"
    on:drop="{dropHandler}"
    on:dragover="{dragOverHandler}"
    on:dragleave="{dragLeaveHandler}"
    on:dragenter="{dragEnterHandler}">
    <!-- overlay -->
    <div
            bind:this="{overlay}"
            class="overlay w-full h-full absolute top-0 left-0 pointer-events-none z-50 flex flex-col items-center justify-center rounded-md">
      <i>
        <svg
                class="fill-current w-12 h-12 mb-3 text-blue-700"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24">
          <path
                  d="M19.479 10.092c-.212-3.951-3.473-7.092-7.479-7.092-4.005 0-7.267 3.141-7.479 7.092-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h13c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.521-5.408zm-7.479-1.092l4 4h-3v4h-2v-4h-3l4-4z"
          ></path>
        </svg>
      </i>
      <p class="text-lg text-blue-700">Drop files to upload</p>
    </div>

    <!-- scroll area -->
    <section class="h-full overflow-auto p-8 w-full flex flex-col">
      <header
        class="border-dashed border-2 border-gray-400 py-12 flex flex-col justify-center items-center">
        <p
          class="mb-3 font-semibold dark:text-truegray-50 text-gray-900 flex flex-wrap justify-center">
          <span>Drag and drop your</span>&nbsp;<span>files anywhere or</span>
        </p>
        <input bind:this="{hidden}" type="file" multiple class="hidden" />
        <button
          bind:this="{uploadButton}"
          type="button"
          class="mt-2 rounded-sm px-3 py-1 bg-gray-200 hover:bg-gray-300 focus:shadow-outline focus:outline-none">
          Upload a file
        </button>
      </header>

      <div class="relative pt-8 pb-3">
        <h1
          class="font-semibold sm:text-lg text-gray-900 dark:text-truegray-50 absolute left-0">
          To Upload
        </h1>

        <h1
          class="font-semibold sm:text-lg cursor-pointer text-gray-900 dark:text-truegray-50 absolute right-0"
          on:click="{deleteAll}">
          🗑️
        </h1>
      </div>

      <ul bind:this="{gallery}" class="flex flex-1 flex-wrap -m-1 mt-8">
        <li
          bind:this="{empty}"
          class="h-full w-full text-center flex flex-col justify-center items-center">
          <img
            class="mx-auto w-32"
            src="https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png"
            alt="no data" />
          <span class="text-small text-gray-500">No files selected</span>
        </li>
      </ul>
      {#if isAttachmentDataServerFetched}
        <Switch
          id="{`attachmentPreviewSwitch${uuid}`}"
          text="Show preview"
          bind:checked="{showAttachments}" />
      {/if}
    </section>
  </article>
{/if}

<!-- using two similar templates for simplicity in js code -->
<template bind:this="{fileTempl}">
  <li class="block p-1 w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/6 xl:w-1/8 h-24 mb-2">
    <article
      tabindex="0"
      class="group w-full h-full rounded-md focus:outline-none focus:shadow-outline elative bg-gray-100 relative shadow-sm">
      <img
        alt="upload preview"
        class="img-preview invisible w-full h-full sticky object-cover rounded-md bg-fixed" />

      <section
        class="flex flex-col rounded-md text-xs break-words w-full h-full z-20 absolute top-0 py-2 px-3">
        <h1
          class="flex-1 line-clamp group-hover:text-blue-800"
          contenteditable="false">
        </h1>
        <div class="flex -ml-2">
          <span class="p-1 text-blue-800">
            <i>
              <svg
                class="fill-current w-4 h-4 ml-auto pt-1"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24">
                <path d="M15 2v5h5v15h-16v-20h11zm1-2h-14v24h20v-18l-6-6z"
                ></path>
              </svg>
            </i>
          </span>
          <p class="p-1 size text-xs text-gray-700"></p>
          <button
            class="delete ml-auto focus:outline-none hover:bg-gray-300 p-1 rounded-md text-gray-800">
            <svg
              class="pointer-events-none fill-current w-4 h-4 ml-auto"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24">
              <path
                class="pointer-events-none"
                d="M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z"
              ></path>
            </svg>
          </button>
        </div>
      </section>
      <progress class="w-full" value="0" max="100"></progress>
    </article>
  </li>
</template>

<template bind:this="{imageTempl}">
  <li class="block p-1 w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/6 xl:w-1/8 h-24">
    <article
      tabindex="0"
      class="group hasImage w-full h-full rounded-md focus:outline-none focus:shadow-outline bg-gray-100 cursor-pointer relative text-transparent hover:text-white shadow-sm">
      <img
        alt="upload preview"
        class="img-preview w-full h-full sticky object-cover rounded-md bg-fixed" />
      <progress class="w-full" value="0" max="100"></progress>

      <section
        class="flex flex-col rounded-md text-xs break-words w-full h-full z-20 absolute top-0 py-2 px-3">
        <h1 class="flex-1 line-clamp"></h1>
        <div class="flex">
          <span class="p-1">
            <i>
              <svg
                class="fill-current w-4 h-4 ml-auto pt-"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24">
                <path
                  d="M5 8.5c0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5c0 .829-.672 1.5-1.5 1.5s-1.5-.671-1.5-1.5zm9 .5l-2.519 4-2.481-1.96-4 5.96h14l-5-8zm8-4v14h-20v-14h20zm2-2h-24v18h24v-18z"
                ></path>
              </svg>
            </i>
          </span>

          <p class="p-1 size text-xs"></p>
          <button
            class="delete ml-auto focus:outline-none hover:bg-gray-300 p-1 rounded-md">
            <svg
              class="pointer-events-none fill-current w-4 h-4 ml-auto"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24">
              <path
                class="pointer-events-none"
                d="M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z"
              ></path>
            </svg>
          </button>
        </div>
      </section>
    </article>
  </li>
</template>

<progress
  class="{progressBarColors.join(' ')}"
  style="display: none"
  value="0"
  max="100"></progress>

<style>
  button:disabled,
  button[disabled] {
    background-color: #cccccc;
    color: #666666;
  }

  .hasImage:hover section {
    background-color: rgba(5, 5, 5, 0.4);
  }
  .hasImage:hover button:hover {
    background: rgba(5, 5, 5, 0.45);
  }

  .overlay p,
  i {
    opacity: 0;
  }

  .overlay.draggedover {
    background-color: rgba(255, 255, 255, 0.7);
  }

  .overlay.draggedover p,
  .overlay.draggedover i {
    opacity: 1;
  }

  .group:hover .group-hover\:text-blue-800 {
    color: #2b6cb0;
  }

  .editor {
    @apply h-auto shadow dark:shadow-blue rounded border-dashed;
  }

  .line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  progress {
    -webkit-appearance: none;
    -moz-appearane: none;
    appearance: none;
    height: 0;
    height: 15px;
    border: none;
    background-color: #000000;
    border-radius: 20px;
    box-shadow: 0 1px 0px 0 rgba(255, 255, 255, 0.2);
    padding: 2px;
    margin-top: 2px;
  }

  progress::-webkit-progress-bar {
    background: #000000;
    border-radius: 20px;
  }

  progress::-webkit-progress-value {
    border-radius: 20px;
    box-shadow: inset 0 1px 1px 0 rgba(255, 255, 255, 0.4);
  }

  .blue::-webkit-progress-value {
    background: -webkit-linear-gradient(
        45deg,
        transparent,
        transparent 33%,
        rgba(0, 0, 0, 0.1) 33%,
        rgba(0, 0, 0, 0.1) 66%,
        transparent 66%
      ),
      -webkit-linear-gradient(top, rgba(255, 255, 255, 0.25), rgba(0, 0, 0, 0.2)),
      -webkit-linear-gradient(left, #0073aa, #0073aa);
    background-size: 25px 14px, 100% 100%, 100% 100%;
  }

  .blue::-ms-fill {
    background: repeating-linear-gradient(
      45deg,
      #0073aa,
      #0073aa 10px,
      #17688f 10px,
      #17688f 20px
    );
    border-radius: 20px;
    box-shadow: inset 0 1px 1px 0 rgba(255, 255, 255, 0.4);
  }

  .coffee::-webkit-progress-value {
    background: -webkit-linear-gradient(
        45deg,
        transparent,
        transparent 33%,
        rgba(0, 0, 0, 0.1) 33%,
        rgba(0, 0, 0, 0.1) 66%,
        transparent 66%
      ),
      -webkit-linear-gradient(top, rgba(255, 255, 255, 0.25), rgba(0, 0, 0, 0.2)),
      -webkit-linear-gradient(left, #c7a589, #c7a589);
    background-size: 25px 14px, 100% 100%, 100% 100%;
  }

  .coffee::-ms-fill {
    background: repeating-linear-gradient(
      45deg,
      #c7a589,
      #c7a589 10px,
      #9e8773 10px,
      #9e8773 20px
    );
    border-radius: 20px;
    box-shadow: inset 0 1px 1px 0 rgba(255, 255, 255, 0.4);
  }

  .ectoplasm::-webkit-progress-value {
    background: -webkit-linear-gradient(
        45deg,
        transparent,
        transparent 33%,
        rgba(0, 0, 0, 0.1) 33%,
        rgba(0, 0, 0, 0.1) 66%,
        transparent 66%
      ),
      -webkit-linear-gradient(top, rgba(255, 255, 255, 0.25), rgba(0, 0, 0, 0.2)),
      -webkit-linear-gradient(left, #a3b745, #a3b745);
    background-size: 25px 14px, 100% 100%, 100% 100%;
  }

  .ectoplasm::-ms-fill {
    background: repeating-linear-gradient(
      45deg,
      #a3b745,
      #a3b745 10px,
      #8e9c4c 10px,
      #8e9c4c 20px
    );
    border-radius: 20px;
    box-shadow: inset 0 1px 1px 0 rgba(255, 255, 255, 0.4);
  }

  .light::-webkit-progress-value {
    background: -webkit-linear-gradient(
        45deg,
        transparent,
        transparent 33%,
        rgba(0, 0, 0, 0.1) 33%,
        rgba(0, 0, 0, 0.1) 66%,
        transparent 66%
      ),
      -webkit-linear-gradient(top, rgba(255, 255, 255, 0.25), rgba(0, 0, 0, 0.2)),
      -webkit-linear-gradient(left, #04a4cc, #04a4cc);
    background-size: 25px 14px, 100% 100%, 100% 100%;
  }

  .light::-ms-fill {
    background: repeating-linear-gradient(
      45deg,
      #04a4cc,
      #04a4cc 10px,
      #1a8aa6 10px,
      #1a8aa6 20px
    );
    border-radius: 20px;
    box-shadow: inset 0 1px 1px 0 rgba(255, 255, 255, 0.4);
  }

  .midnight::-webkit-progress-value {
    background: -webkit-linear-gradient(
        45deg,
        transparent,
        transparent 33%,
        rgba(0, 0, 0, 0.1) 33%,
        rgba(0, 0, 0, 0.1) 66%,
        transparent 66%
      ),
      -webkit-linear-gradient(top, rgba(255, 255, 255, 0.25), rgba(0, 0, 0, 0.2)),
      -webkit-linear-gradient(left, #e14d43, #e14d43);
    background-size: 25px 14px, 100% 100%, 100% 100%;
  }

  .midnight::-ms-fill {
    background: repeating-linear-gradient(
      45deg,
      #e14d43,
      #e14d43 10px,
      #b54d46 10px,
      #b54d46 20px
    );
    border-radius: 20px;
    box-shadow: inset 0 1px 1px 0 rgba(255, 255, 255, 0.4);
  }

  .ocean::-webkit-progress-value {
    background: -webkit-linear-gradient(
        45deg,
        transparent,
        transparent 33%,
        rgba(0, 0, 0, 0.1) 33%,
        rgba(0, 0, 0, 0.1) 66%,
        transparent 66%
      ),
      -webkit-linear-gradient(top, rgba(255, 255, 255, 0.25), rgba(0, 0, 0, 0.2)),
      -webkit-linear-gradient(left, #9ebaa0, #9ebaa0);
    background-size: 25px 14px, 100% 100%, 100% 100%;
  }

  .ocean::-ms-fill {
    background: repeating-linear-gradient(
      45deg,
      #9ebaa0,
      #9ebaa0 10px,
      #819583 10px,
      #819583 20px
    );
    border-radius: 20px;
    box-shadow: inset 0 1px 1px 0 rgba(255, 255, 255, 0.4);
  }

  .sunrise::-webkit-progress-value {
    background: -webkit-linear-gradient(
        45deg,
        transparent,
        transparent 33%,
        rgba(0, 0, 0, 0.1) 33%,
        rgba(0, 0, 0, 0.1) 66%,
        transparent 66%
      ),
      -webkit-linear-gradient(top, rgba(255, 255, 255, 0.25), rgba(0, 0, 0, 0.2)),
      -webkit-linear-gradient(left, #dd823b, #c4672d);
    background-size: 25px 14px, 100% 100%, 100% 100%;
  }

  .sunrise::-ms-fill {
    background: repeating-linear-gradient(
      45deg,
      #dd823b,
      #dd823b 10px,
      #ab6a3c 10px,
      #ab6a3c 20px
    );
    border-radius: 20px;
    box-shadow: inset 0 1px 1px 0 rgba(255, 255, 255, 0.4);
  }
</style>
