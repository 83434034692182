<script>
  export let toggleTabs = (tab) => {
    openTab = tab;
    console.log('tab selected ' + openTab);
  };

  export let openTab;
  export let tabsData = [{ title: 'Page 1', id: 1 }];
</script>

<ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
  {#each tabsData as tab}
    <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
      <button
        class="px-5 py-3 rounded-md text-base font-medium leading-5 text-gray-900 dark:text-truegray-50 hover:bg-gray-200 focus:outline-none focus:bg-gray-400 transition duration-150 ease-in-out 
        {openTab === tab.id
          ? 'bg-gray-300 focus:outline-orange text-gray-50 dark:text-truegray-900'
          : ''}"
        on:click="{() => toggleTabs(tab.id)}">
        <span class="hover:text-truegray-900">{tab.title}</span>
      </button>
    </li>
  {/each}
</ul>
