<script>
  import Uploader from '../components/ChunkedUploader.svelte';

  import { submitData } from '../services/postData.js';
  import { getRelativeTime } from '../helpers/relativeTime';

  export let revisionData = [];
  export let orderId;

  let editable = false;

  let desc = 'Enter revision notes';

  let statusEmoji = '';

  let postUrl;

  $: if (desc && desc !== 'Enter revision notes') {
    statusEmoji = '✏';
  }

  $: uuid = `${orderId}-r${revisionData.length + 1}`;

  const handleNewRevision = async (index) => {
    const node = document.getElementById(`revField${index}`);
    let revId = null;
    let description = desc;
    if (node) {
      description = node.textContent || node.innerText;
      revId = revisionData[index].id;
      if (description == revisionData[index].desc) return;
    }
    postUrl = revId
      ? 'https://test.dev/revision/update'
      : 'https://test.dev/revision/create';

    if (!revId && description == 'Enter revision notes') {
      return;
    }

    const response = await submitData(postUrl, {
      desc: description,
      designid: orderId,
      id: revId,
      uuid: uuid,
    });

    if (response.ok) {
      statusEmoji = '✔';
    }
  };
</script>

<!-- component -->
<div class="relative m-8">
  <div
    class="border-r-2 border-gray-500 absolute h-full top-0"
    style="left: 15px">
  </div>
  <ul class="list-none m-0 p-0">
    <li class="mb-2">
      <div class="flex items-center mb-1">
        <div
          class="bg-red-200 rounded-full h-8 w-8 items-center justify-center flex z-10">
          ➕
        </div>
        <div class="flex-1 ml-4 text-xl dark:text-truegray-50">
          Add new revision {statusEmoji}
        </div>
        <div class="float-right">
          <Uploader
            fileUploadUrl="https://test.dev/revision/upload"
            iconOnly="{true}"
            uuid="{uuid}" />
        </div>
      </div>
      <div
        class="ml-12 dark:text-truegray-50"
        contenteditable="true"
        on:blur="{handleNewRevision}"
        bind:innerHTML="{desc}">
        {desc}
      </div>
    </li>
    {#if Array.isArray(revisionData)}
      {#each revisionData as revision, i}
        <li class="mb-2">
          <div class="flex items-center mb-1">
            <div
              class="bg-red-{i +
                2}00 rounded-full h-8 w-8 items-center justify-center flex z-10">
              {i + 1}
            </div>
            <div class="flex-1 ml-4 text-lg text-gray-500">
              {getRelativeTime(new Date(revision.date))}
            </div>
          </div>
          <div
            class="px-5 bg-white dark:bg-truegray-900 border-t dark:border-truegray-700 flex flex-col xs:flex-row
            xs:justify-between ml-12 dark:text-truegray-50"
            id="revField{i}"
            contenteditable="{editable}"
            on:dblclick="{() => (editable = true)}"
            on:blur="{() => handleNewRevision(i)}">
            {revision.desc}
          </div>
        </li>
      {/each}
    {/if}
  </ul>
</div>
