import fuzzysort from "fuzzysort";

export const fuzzy = function (items, key, itemsString) {
  // Returns a method that you can use to create your own reusable fuzzy search.
  return function (query) {
    const words = query.toLowerCase().split(' ');
    const correctedWords = [];

    words.forEach(word => {
      if (word.length <= 3) {
        correctedWords.push(word);
        return;
      }
      const result = fuzzysort.go(word, itemsString, {
        limit: 1,
        allowTypo: true
      });

      let finalWord;

      if (result.total == 0) {
        finalWord = word;
      }
      else if (result.total == 1) {
        finalWord = result[0].target.toLowerCase();
      }
      else {
        finalWord = result[0].target.toLowerCase().substring(0, word.length);
      }

      correctedWords.push(finalWord);
    });
    return items.filter(function (item) {
      const normalizedTerm = item[key].toLowerCase();
      return correctedWords.every(function (word) {
        return (normalizedTerm.indexOf(word) > -1);
      });
    });
  };
};